import React, { useEffect, useMemo, useState } from "react";
import { Button, Dropdown } from "react-bootstrap";
import { BsWhatsapp } from "react-icons/bs";
import { ReactComponent as WhatsappIcon } from "../../Assets/icons/whatsapp.svg";
import { handleApiRequest } from "../../services/handleApiRequest";
import { addQuery } from "../../redux/contentPages/thunk";
import { successMsg } from "../../utils/toastMsg";
import { isEmail } from "validator";
import { getAllCountry } from "../../redux/countryAndCity/thunk";
import { useSelector } from "react-redux";
import phone from "phone";
import { uploadFile } from "../../redux/common/thunk";
import Asterik from "../../components/common/asterik";
import { whatsAppNumber } from "../../config";

export default function ContactUs() {
  const { allCountries } = useSelector((state) => state.countryAndCity);
  const [contactDetails, setContactDetails] = useState({
    name: "",
    email: "",
    phone: "",
    comment: "",
  });
  const [errors, setErrors] = useState({});

  const ghanaFlag = useMemo(() => {
    if (allCountries.data) {
      const ghana = allCountries.data?.items?.find((country) => country.name === "Ghana");
      return ghana?.flag;
    }
  }, [allCountries]);

  const handleChange = (e) => {
    setContactDetails((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleFileUpload = async (e) => {
    const file = e.target.files[0];

    const formData = new FormData();
    formData.append("images", file);

    if (file) {
      const response = await handleApiRequest(uploadFile, formData);
      if (response?.status) {
        setContactDetails((prev) => ({ ...prev, file: response.data[0]?.url }));
      }
    }
  };

  const handleSubmit = async () => {
    const error = {};
    for (let key of Object.keys(contactDetails)) {
      if (!contactDetails[key]) {
        error[key] = "Required";
      } else {
        error[key] = "";
      }
    }

    if (contactDetails.email && !isEmail(contactDetails.email)) {
      error.email = "Invalid Email";
    }
    if (contactDetails.phone && !phone(contactDetails.phone).isValid) {
      error.phone = "Invalid Phone";
    }

    if (Object.values(error).filter((value) => value).length > 0) {
      return setErrors(error);
    } else {
      setErrors({});
    }

    const response = await handleApiRequest(addQuery, contactDetails);
    if (response?.status) {
      successMsg("Query submitted  successfully!");
      setContactDetails({});
    }
  };

  return (
    <>
      <section>
        <div className="text-center mt-5 mb-4">
          <p>We’d Love to Hear From You</p>
          <h3>LET'S GET IN TOUCH!</h3>
        </div>
        <div className="d-flex justify-content-start" style={{ gap: "50px" }}>
          {/* <div
            className={`fullSizeAddContainer me-3 d-none d-xl-flex ms-0`}
            style={{ width: 160, height: 600, minWidth: 160 }}
          >
            Add Container
            <br />
            (160 x 600)
          </div> */}
          <div className="d-flex">
            <div className="contactUsFieldWrapper">
              <label className="">
                Full Name <Asterik />
              </label>
              <input
                type="text"
                className="myInput"
                placeholder="Enter your name"
                name="name"
                value={contactDetails.name || ""}
                onChange={handleChange}
              />
              {errors.name && <p className="errorMsg">*{errors.name}</p>}

              <label className="mt-2">
                Email <Asterik />
              </label>
              <input
                type="text"
                className="myInput"
                placeholder="Enter your email"
                name="email"
                value={contactDetails.email || ""}
                onChange={handleChange}
              />
              {errors.email && <p className="errorMsg">*{errors.email}</p>}

              <label className="mt-2">
                Mobile <Asterik />
              </label>
              <div className="input-group has-validation">
                <Dropdown>
                  <Dropdown.Toggle variant="" id="dropdown-basic" className="countryCodeSelector">
                    <img src={contactDetails.flag || ghanaFlag} width={18} />
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="countryCodeSelector" x>
                    {allCountries.data?.items?.map((country) => (
                      <Dropdown.Item
                        key={country._id}
                        onClick={() =>
                          setContactDetails((prev) => ({
                            ...prev,
                            flag: country.flag,
                            phone: `+${country.countryCode}`,
                            countryCode: `+${country.countryCode}`,
                          }))
                        }
                      >
                        <img src={country.flag} width={18} />
                        <span className="mx-2">{country.name}</span>
                        <span>({country.countryCode})</span>
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter Phone"
                  name="phone"
                  value={contactDetails.phone || "+233"}
                  onChange={(e) => {
                    const countryCode = contactDetails.countryCode || "+233";
                    const value = e.target.value.slice(1);
                    let updatedValue = value.replace(/\D+/g, "");
                    updatedValue = countryCode + updatedValue.slice(countryCode.length - 1);

                    setContactDetails((prev) => ({
                      ...prev,
                      phone: updatedValue,
                    }));
                  }}
                />
              </div>
              {errors.phone && <p className="errorMsg">*{errors.phone}</p>}

              <label className="mt-2">
                Business Name <small>(optional)</small>
              </label>
              <input
                type="text"
                className="myInput"
                placeholder="Enter Business Name"
                name="businessName"
                value={contactDetails.businessName || ""}
                onChange={handleChange}
              />

              <label className="mt-2">
                Comments <Asterik />
              </label>
              <textarea
                className="myInput"
                rows={7}
                placeholder="Enter message here"
                name="comment"
                value={contactDetails.comment || ""}
                onChange={handleChange}
              />
              <p className="small m-0">
                Please enter the details of your request and our customer service team will get back
                to you, typically within 24 - 48 hours.
              </p>
              <p className="small">
                <b>Please Note:</b> Do not submit sensitive information such as payment card
                details.
              </p>
              {errors.comment && <p className="errorMsg">*{errors.comment}</p>}
              <input className="myInput my-2" type="file" name="file" onChange={handleFileUpload} />

              <Button variant="danger" className="" onClick={handleSubmit}>
                Submit
              </Button>
            </div>
            <div className="timingBox rounded ms-3 p-3">
              <p className="text-center m-0">
                <b>Start a Chat</b>
                <p>For general enquiries</p>
              </p>
              <a href={`https://wa.me/${whatsAppNumber}`} target="_blank">
                <p className="whatsappSeller mainDarkColor m-0 rounded-pill small">
                  <WhatsappIcon className="me-1" width={20} />
                  Message us on WhatsApp
                </p>
              </a>
            </div>
          </div>
          {/* <div
            className={`fullSizeAddContainer me-3 d-none d-xl-flex ms-0`}
            style={{ width: 160, height: 600, minWidth: 160 }}
          >
            Add Container
            <br />
            (160 x 600)
          </div> */}
        </div>
        21
      </section>
    </>
  );
}
