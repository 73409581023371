import { categories } from "../..";
import { bikesBodyStyleOptions, bikesEnginePowerOptions } from "../bikes/options";
import { caravansBirthOptions, caravansCategoryOptions } from "../caravans/options";
import {
  colorsList,
  conditionOptions,
  engineSizeOptions,
  fuelTypeOptions,
  gearBoxOptions,
  getYearList,
  sellerOptions,
} from "../common/options";
import { farmsCategoryOptions } from "../farms/options";
import { plantsCategoryOptions } from "../plants/options";
import {
  trucksCategoryOptions,
  trucksEnginePowerOptions,
  trucksSubCategoryOptions,
} from "../trucks/options";
import { rentalsPriceTypeOptions } from "./options";

export const rentalsFilters = [
  { label: "Country", name: "country", filterOptions: [], filterType: "normal" },
  { label: "City", name: "city", filterOptions: [], filterType: "normal" },
  {
    label: "Category",
    name: "type",
    filterOptions: categories.filter((item) => item.value !== "rentals"),
    filterType: "singleRange",
  },
  { label: "Make", name: "make", filterOptions: [], filterType: "normal" },
  { label: "Model", name: "model", filterOptions: [], filterType: "normal" },
  // { label: "Variant", name: "variant", filterOptions: [], filterType: "normal" },
  {
    label: "Price",
    name: "price",
    filterOptions: {
      Min: { options: [], key: "minPrice" },
      Max: { options: [], key: "maxPrice" },
    },
    filterType: "input",
  },
  {
    label: "Year",
    name: "year",
    filterOptions: {
      From: { options: getYearList(), key: "minYear" },
      To: { options: getYearList(), key: "maxYear" },
    },
    filterType: "range",
  },
  // {
  //   label: "Condition",
  //   name: "condition",
  //   filterOptions: conditionOptions,
  //   filterType: "normal",
  // },
  {
    label: "Private or Dealer",
    name: "userType",
    filterOptions: sellerOptions,
    filterType: "normal",
  },
  {
    label: "Exterior Color",
    name: "exteriorColor",
    filterOptions: colorsList,
    filterType: "normal",
  },
  {
    label: "Fuel type",
    name: "fueltype",
    filterOptions: fuelTypeOptions,
    filterType: "normal",
  },
  {
    label: "Price type",
    name: "priceType",
    filterOptions: rentalsPriceTypeOptions,
    filterType: "normal",
  },
];

export const rentalsPostFeatures = {
  rentals: [
    { label: "Country", value: "country", options: [] },
    { label: "City", value: "city", options: [] },
    { label: "Make", value: "make", options: [] },
    { label: "Model", value: "model", options: [] },
  ],
  cars: [
    {
      label: "Year",
      value: "year",
      options: getYearList(),
    },
    // {
    //   label: "Condition",
    //   value: "condition",
    //   options: conditionOptions,
    // },
    {
      label: "Fuel Type",
      value: "fuelType",
      options: fuelTypeOptions,
    },
    {
      label: "Exterior Color",
      value: "exteriorColor",
      options: colorsList,
    },
    {
      label: "Transmission",
      value: "gearBox",
      options: gearBoxOptions,
    },
    {
      label: "Engine Size",
      value: "engineSize",
      options: engineSizeOptions,
    },
    {
      label: "Price type",
      value: "priceType",
      options: rentalsPriceTypeOptions,
    },
  ],

  bikes: [
    {
      label: "Year",
      value: "year",
      options: getYearList(),
    },
    // {
    //   label: "Condition",
    //   value: "condition",
    //   options: conditionOptions,
    // },
    {
      label: "Fuel Type",
      value: "fuelType",
      options: fuelTypeOptions,
    },
    {
      label: "Exterior Color",
      value: "exteriorColor",
      options: colorsList,
    },
    {
      label: "Engine Power",
      value: "enginePower",
      options: bikesEnginePowerOptions,
    },
    {
      label: "Body Type",
      value: "bodyStyle",
      options: bikesBodyStyleOptions,
    },
    {
      label: "Price type",
      value: "priceType",
      options: rentalsPriceTypeOptions,
    },
  ],
  vans: [
    {
      label: "Year",
      value: "year",
      options: getYearList(),
    },
    // {
    //   label: "Condition",
    //   value: "condition",
    //   options: conditionOptions,
    // },
    {
      label: "Fuel Type",
      value: "fuelType",
      options: fuelTypeOptions,
    },
    {
      label: "Exterior Color",
      value: "exteriorColor",
      options: colorsList,
    },
    {
      label: "Transmission",
      value: "gearBox",
      options: gearBoxOptions,
    },
    {
      label: "Price type",
      value: "priceType",
      options: rentalsPriceTypeOptions,
    },
  ],
  caravans: [
    {
      label: "Year",
      value: "year",
      options: getYearList(),
    },
    // {
    //   label: "Condition",
    //   value: "condition",
    //   options: conditionOptions,
    // },
    {
      label: "Fuel Type",
      value: "fuelType",
      options: fuelTypeOptions,
    },
    {
      label: "Exterior Color",
      value: "exteriorColor",
      options: colorsList,
    },
    {
      label: "Berth",
      value: "birth",
      options: caravansBirthOptions,
    },
    {
      label: "Category",
      value: "category",
      options: caravansCategoryOptions,
    },
    {
      label: "Price type",
      value: "priceType",
      options: rentalsPriceTypeOptions,
    },
  ],
  plants: [
    {
      label: "Category",
      value: "category",
      options: plantsCategoryOptions,
    },
    {
      label: "Sub Category",
      value: "subCategory",
      options: [],
    },
    {
      label: "Year",
      value: "year",
      options: getYearList(),
    },
    // {
    //   label: "Condition",
    //   value: "condition",
    //   options: conditionOptions,
    // },
    {
      label: "Fuel Type",
      value: "fuelType",
      options: fuelTypeOptions,
    },
    {
      label: "Exterior Color",
      value: "exteriorColor",
      options: colorsList,
    },
    {
      label: "Price type",
      value: "priceType",
      options: rentalsPriceTypeOptions,
    },
  ],
  farms: [
    {
      label: "Category",
      value: "category",
      options: farmsCategoryOptions,
    },
    {
      label: "Sub Category",
      value: "subCategory",
      options: [],
    },
    {
      label: "Year",
      value: "year",
      options: getYearList(),
    },
    // {
    //   label: "Condition",
    //   value: "condition",
    //   options: conditionOptions,
    // },
    {
      label: "Fuel Type",
      value: "fuelType",
      options: fuelTypeOptions,
    },
    {
      label: "Exterior Color",
      value: "exteriorColor",
      options: colorsList,
    },
    {
      label: "Price type",
      value: "priceType",
      options: rentalsPriceTypeOptions,
    },
  ],
  trucks: [
    {
      label: "Category",
      value: "category",
      options: trucksCategoryOptions,
    },
    {
      label: "Sub Category",
      value: "subCategory",
      options: trucksSubCategoryOptions,
    },
    {
      label: "Year",
      value: "year",
      options: getYearList(),
    },
    // {
    //   label: "Condition",
    //   value: "condition",
    //   options: conditionOptions,
    // },
    {
      label: "Fuel Type",
      value: "fuelType",
      options: fuelTypeOptions,
    },
    {
      label: "Exterior Color",
      value: "exteriorColor",
      options: colorsList,
    },
    {
      label: "Engine Power",
      value: "enginePower",
      options: trucksEnginePowerOptions,
    },
    {
      label: "Price type",
      value: "priceType",
      options: rentalsPriceTypeOptions,
    },
  ],
};

export const rentalsDetailsList = [
  // { value: "condition", label: "Condition" },
  { value: "year", label: "Year" },
  { value: "fuelType", label: "Fuel Type" },
  { value: "exteriorColor", label: "Colour" },
  { value: "gearBox", label: "Transmission" },
  { value: "engineSize", label: "Engine Size" },
  { value: "enginePower", label: "Engine Power" },
  { value: "bodyStyle", label: "Body Type" },
  { value: "birth", label: "Berth" },
  { value: "category", label: "Category" },
];
