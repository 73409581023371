import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import SelectBox from "../../../components/selectBox";
import { carsFilters } from "../../../utils/filters/cars";
import { selectFilters } from "../../../redux/filters/slice";
import { handleApiRequest } from "../../../services/handleApiRequest";
import { getVehicleCount } from "../../../redux/vehicles/thunk";
import HeroAdd from "../../../components/heroSection/heroAdd";
import CountryFilter from "../../../components/filters";
import { preventMinus } from "../../../utils";
import { carsMileageList } from "../../../utils/filters/cars/options";
import { getYearList } from "../../../utils/filters/common/options";
import { getAllMake, getAllModel } from "../../../redux/makeAndModel/thunk";
import TruckFilters from "./truckFilters";
import FarmFilters from "./farmsFIlters";
import PlantFilters from "./plantFilters";
import VerticalAds from "../../../googleAds/verticalAds";

export default function MoreFilters() {
  const [params] = useSearchParams();
  const type = params.get("type") || "sell";
  let userCategory = params.get("category");
  userCategory = userCategory ? userCategory : type === "rent" && !userCategory ? "" : "cars";

  const dispatch = useDispatch();
  const { filters } = useSelector((state) => state.filters);
  // const { allMakes, allModels, allVariants } = useSelector((state) => state.makeAndModel);
  const { allMakes } = useSelector((state) => state.makeAndModel);

  const handleUpdateFilter = (name, value) => {
    dispatch(selectFilters({ [name]: value }));
  };

  const handleResultCount = async () => {
    const newFilters = {};
    Object.entries(filters).forEach((filter) => {
      newFilters[filter[0]] =
        typeof filter[1] === "object" ? filter[1].value || filter[1]._id : filter[1];
    });

    handleApiRequest(getVehicleCount, {
      filters: { ...newFilters, status: "approved", type: userCategory, sellOrRent: "sell" },
    });
  };

  const handleMakeList = async () => {
    handleApiRequest(getAllMake, { type: userCategory });
  };

  const handleModelList = async () => {
    handleApiRequest(getAllModel, { makeId: filters.make.value, type: userCategory });
  };

  useEffect(() => {
    if (userCategory) {
      handleMakeList();
    }
  }, [userCategory]);

  useEffect(() => {
    if (userCategory) {
      handleResultCount();
    }
  }, [filters, userCategory]);

  useEffect(() => {
    if (filters.make?.value && userCategory) {
      handleModelList();
    }
  }, [filters.make, userCategory]);

  return (
    <>
      <HeroAdd />

      <section>
        <Row>
          <h5 className="fw-bold text-center">ADVANCED SEARCH</h5>
          <h6 className="text-center mb-5">Search the largest choice of {userCategory}</h6>
          <Col lg={4} className="d-flex flex-column" style={{ gap: 10 }}>
            <div className="d-flex justify-content-between my-2 gap-10">
              <div className="w-100">
                <label>Country</label>
                <CountryFilter filterType={"country"} />
              </div>
              {filters.country?.label !== "Africa" && (
                <div className="w-100">
                  <label>City</label>
                  <CountryFilter filterType={"city"} />
                </div>
              )}
            </div>
            <div className="d-flex justify-content-between my-2 gap-10">
              <div className="w-100">
                <label>Make</label>
                <SelectBox
                  placeholder="Make"
                  options={allMakes.data?.items || []}
                  value={filters.make || ""}
                  onChange={(selected) => {
                    handleUpdateFilter("make", { value: selected._id, label: selected.label });
                  }}
                />
              </div>
              <div className="w-100">
                <label>Model</label>
                <CountryFilter filterType={"model"} />
              </div>
            </div>
            <div className="d-flex justify-content-between my-2 gap-10">
              <div>
                <label>Min Price</label>
                <input
                  type="number"
                  className="form-control"
                  style={{ height: 40 }}
                  placeholder="Enter Min Price"
                  name="minPrice"
                  value={filters.minPrice?.value || ""}
                  min={0}
                  onKeyDown={preventMinus}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (value.length > 10) return;
                    handleUpdateFilter("minPrice", { value: value, label: value });
                  }}
                />
              </div>

              <div>
                <label>Min Price</label>
                <input
                  type="number"
                  className="form-control"
                  style={{ height: 40 }}
                  placeholder="Enter Max Price"
                  name="maxPrice"
                  value={filters.maxPrice?.value || ""}
                  min={0}
                  onKeyDown={preventMinus}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (value.length > 10) return;
                    handleUpdateFilter("maxPrice", { value: value, label: value });
                  }}
                />
              </div>
            </div>

            {userCategory !== "farms" && userCategory !== "plants" && (
              <div className="d-flex justify-content-between my-2 gap-10">
                <div className="w-100">
                  <label>Min Mileage</label>
                  <SelectBox
                    placeholder="Max Mileage"
                    options={carsMileageList.slice(0, -2)}
                    value={filters.minMileage || ""}
                    onChange={(value) => {
                      handleUpdateFilter("minMileage", value);
                    }}
                  />
                </div>
                <div className="w-100">
                  <label>Max Mileage</label>
                  <SelectBox
                    placeholder="Max Mileage"
                    options={carsMileageList.slice(2)}
                    value={filters.maxMileage || ""}
                    onChange={(value) => {
                      handleUpdateFilter("maxMileage", value);
                    }}
                  />
                </div>
              </div>
            )}
            <div className="d-flex justify-content-between my-2 gap-10">
              <div className="w-100">
                <label>From (Year)</label>
                <SelectBox
                  options={getYearList()}
                  value={filters.minYear || ""}
                  onChange={(value) => {
                    handleUpdateFilter("minYear", value);
                  }}
                />
              </div>
              <div className="w-100">
                <label>To (Year)</label>
                <SelectBox
                  options={getYearList()}
                  value={filters.maxYear || ""}
                  onChange={(value) => {
                    handleUpdateFilter("maxYear", value);
                  }}
                />
              </div>
            </div>

            <div className="allFiltersAdd">
              <VerticalAds dataAdSlot="1382508356" />
            </div>
          </Col>

          {userCategory === "trucks" ? (
            <TruckFilters />
          ) : userCategory === "farms" ? (
            <FarmFilters />
          ) : userCategory === "plants" ? (
            <PlantFilters />
          ) : (
            ""
          )}
        </Row>
      </section>
    </>
  );
}
