import React from "react";
import { Button } from "react-bootstrap";
import { IoSearch } from "react-icons/io5";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { resetFilters } from "../../../redux/filters/slice";

export default function ActionBtns({ count = 0, category = "cars", type = "sell" }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const hanldeResetFilters = () => {
    dispatch(resetFilters());
  };

  return (
    <div
      className="advancedSearchBtns mx-auto d-flex flex-column shadow bg-white pt-3 pb-1 position-sticky"
      style={{ bottom: 25 }}
    >
      <Button
        type="submit"
        variant="danger"
        className="w-50 mx-auto d-flex align-items-center justify-content-center"
        onClick={() => {
          navigate(`/${category}/all?category=${category}&type=${type}`);
        }}
      >
        <IoSearch className="searchIcon" />
        Search {count}
      </Button>
      <Button
        variant=""
        className="w-50 mx-auto text-primary text-center"
        style={{ fontSize: 16 }}
        onClick={() => {
          hanldeResetFilters();
        }}
      >
        Reset Filters
      </Button>
    </div>
  );
}
