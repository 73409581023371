import React, { useEffect } from "react";

export default function SquareFixAd({ dataAdSlot = "7645251103", style = {}, className = "" }) {
  useEffect(() => {
    try {
      (window.adsbygoogle = window.adsbygoogle || []).push({});
    } catch (error) {
      console.log("ad error", error);
    }
  }, []);

  return (
    <ins
      class={`adsbygoogle ${className}`}
      style={{ display: "inline-block", ...style }}
      data-ad-client="ca-pub-2768118606498294"
      data-ad-slot={dataAdSlot}
    ></ins>
  );
}
