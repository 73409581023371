import { store } from "../../../redux/store";
import {
  carsBodyStyleOptions,
  carsBootSpaceOptions,
  carsMileageList,
  carsSeatOptions,
} from "./options";
import {
  accelerationOptions,
  co2EmmisionOptions,
  colorsList,
  conditionOptions,
  doorOptions,
  driverPositionsOptions,
  engineSizeOptions,
  fuelConsumtionOptions,
  fuelTypeOptions,
  gearBoxOptions,
  getYearList,
  sellerOptions,
} from "../common/options";

export const carsFilters = [
  { label: "Country", name: "country", filterOptions: [], filterType: "normal" },
  { label: "City", name: "city", filterOptions: [], filterType: "normal" },
  { label: "Make", name: "make", filterOptions: [], filterType: "normal" },
  { label: "Model", name: "model", filterOptions: [], filterType: "normal" },
  // { label: "Variant", name: "variant", filterOptions: [], filterType: "normal" },
  {
    label: "Price",
    name: "price",
    filterOptions: {
      Min: { options: getYearList(), key: "minPrice" },
      Max: { options: getYearList(), key: "maxPrice" },
    },
    filterType: "input",
  },
  {
    label: "Year",
    name: "year",
    filterOptions: {
      From: { options: getYearList(), key: "minYear" },
      To: { options: getYearList(), key: "maxYear" },
    },
    filterType: "range",
  },
  {
    label: "Mileage",
    name: "mileage",
    filterOptions: {
      Min: { options: carsMileageList, key: "minMileage" },
      Max: { options: carsMileageList, key: "maxMileage" },
    },
    filterType: "range",
  },
  //   {
  //     label: "Private or Seller",
  //     filterOptions: [
  //       { value: "private", label: "Private" },
  //       { value: "Seller", label: "Seller" },
  //     ],
  //     filterType: "normal",
  //   },
  {
    label: "Condition",
    name: "condition",
    filterOptions: conditionOptions,
    filterType: "normal",
  },
  {
    label: "Private or Dealer",
    name: "userType",
    filterOptions: sellerOptions,
    filterType: "normal",
  },
  {
    label: "Body Type",
    name: "bodyStyle",
    filterOptions: carsBodyStyleOptions,
    filterType: "normal",
  },
  {
    label: "Engine Size",
    name: "engineSize",
    filterOptions: engineSizeOptions,
    filterType: "normal",
  },
  {
    label: "Door",
    name: "door",
    filterOptions: doorOptions,
    filterType: "normal",
  },
  {
    label: "Exterior Color",
    name: "exteriorColor",
    filterOptions: colorsList,
    filterType: "normal",
  },
  { label: "Seat", name: "seat", filterOptions: carsSeatOptions, filterType: "normal" },
  {
    label: "Driver Position",
    name: "driverPosition",
    filterOptions: driverPositionsOptions,
    filterType: "normal",
  },
  // {
  //   label: "Boot Space",
  //   name: "bootSpace",
  //   filterOptions: carsBootSpaceOptions,
  //   filterType: "normal",
  // },
  {
    label: "Transmission",
    name: "gearBox",
    filterOptions: gearBoxOptions,
    filterType: "normal",
  },

  // {
  //   label: "Acceleration",
  //   name: "accelration",
  //   filterOptions: accelerationOptions,
  //   filterType: "normal",
  // },
  {
    label: "Fuel Type",
    name: "fuelType",
    filterOptions: fuelTypeOptions,
    filterType: "normal",
  },
  // {
  //   label: "Fuel Consumption",
  //   name: "fuelConsumption",
  //   filterOptions: fuelConsumtionOptions,
  //   filterType: "normal",
  // },
  // {
  //   label: "CO2 Emission",
  //   name: "co2Emission",
  //   filterOptions: co2EmmisionOptions,
  //   filterType: "normal",
  // },
];

export const carsPostFeatures = [
  { label: "Country", value: "country", options: [] },
  { label: "City", value: "city", options: [] },
  { label: "Make", value: "make", options: [] },
  { label: "Model", value: "model", options: [] },
  // { label: "Variant", value: "variant", options: [],  },
  {
    label: "Year",
    value: "year",
    options: getYearList(),
  },
  {
    label: "Condition",
    value: "condition",
    options: conditionOptions,
  },
  {
    label: " body Style",
    value: "bodyStyle",
    options: carsBodyStyleOptions,
  },
  {
    label: "Fuel Type",
    value: "fuelType",
    options: fuelTypeOptions,
  },
  {
    label: "Engine Size",
    value: "engineSize",
    options: engineSizeOptions,
  },
  {
    label: "Transmission",
    value: "gearBox",
    options: gearBoxOptions,
  },
  // {
  //   label: "Fuel Consumption",
  //   value: "fuelConsumption",
  //   options: fuelConsumtionOptions,
  // },
  {
    label: "Door",
    value: "door",
    options: doorOptions,
  },
  {
    label: "Exterior Color",
    value: "exteriorColor",
    options: colorsList,
  },
  { label: "Seat", value: "seat", options: carsSeatOptions },
  {
    label: "Driver Position",
    value: "driverPosition",
    options: driverPositionsOptions,
  },
  // {
  //   label: "Boot Space",
  //   value: "bootSpace",
  //   options: carsBootSpaceOptions,
  // },
  // {
  //   label: "Acceleration",
  //   value: "accelration",
  //   options: accelerationOptions,
  // },
  // {
  //   label: "CO2 Emission",
  //   value: "co2Emission",
  //   options: co2EmmisionOptions,
  // },
];

export const carsDetailsList = [
  { value: "condition", label: "Condition" },
  { value: "year", label: "Year" },
  { value: "bodyStyle", label: "Body" },
  { value: "driverPosition", label: "Driver Position" },
  { value: "gearBox", label: "Transmission" },
  { value: "engineSize", label: "Engine Size" },
  { value: "mileage", label: "Mileage" },
  { value: "fuelType", label: "Fuel Type" },
  { value: "seat", label: "Seats" },
  { value: "door", label: "Doors" },
  { value: "exteriorColor", label: "Colour" },
  // { value: "bootSpace", label: "Boot Space" },
  // { value: "accelration", label: "Acceleration" },
  // { value: "co2Emission", label: "CO2 Emission" },
];
