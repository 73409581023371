import { bikesPostFeatures } from "./filters/bikes";
import { caravansPostFeatures } from "./filters/caravans";
import { carsPostFeatures } from "./filters/cars";
import { farmsPostFeatures } from "./filters/farms";
import { motorhomesPostFeatures } from "./filters/motorhomes";
import { partsPostFeatures } from "./filters/partsAndAccessories";
import { plantsPostFeatures } from "./filters/plants";
import { rentalsPostFeatures } from "./filters/rental";
import { trucksPostFeatures } from "./filters/trucks";
import { vansPostFeatures } from "./filters/vans";

export const getPostFeatures = (category, rentalCategory) => {
  if (category === "cars") {
    return carsPostFeatures;
  } else if (category === "caravans") {
    return caravansPostFeatures;
  } else if (category === "vans") {
    return vansPostFeatures;
  } else if (category === "bikes") {
    return bikesPostFeatures;
  } else if (category === "motorhomes") {
    return motorhomesPostFeatures;
  } else if (category === "trucks") {
    return trucksPostFeatures;
  } else if (category === "farms") {
    return farmsPostFeatures;
  } else if (category === "plants") {
    return plantsPostFeatures;
  } else if (category === "partAndAccessories") {
    return partsPostFeatures;
  } else if (category === "rentals") {
    if (rentalCategory === "cars") {
      return [...rentalsPostFeatures.rentals, ...rentalsPostFeatures.cars];
    } else if (rentalCategory === "bikes") {
      return [...rentalsPostFeatures.rentals, ...rentalsPostFeatures.bikes];
    } else if (rentalCategory === "vans" || rentalCategory === "motorhomes") {
      return [...rentalsPostFeatures.rentals, ...rentalsPostFeatures.vans];
    } else if (rentalCategory === "caravans") {
      return [...rentalsPostFeatures.rentals, ...rentalsPostFeatures.caravans];
    } else if (rentalCategory === "plants") {
      return [...rentalsPostFeatures.rentals, ...rentalsPostFeatures.plants];
    } else if (rentalCategory === "farms") {
      return [...rentalsPostFeatures.rentals, ...rentalsPostFeatures.farms];
    } else if (rentalCategory === "trucks") {
      return [...rentalsPostFeatures.rentals, ...rentalsPostFeatures.trucks];
    } else {
      return rentalsPostFeatures.rentals;
    }
  } else {
    return carsPostFeatures;
  }
};
