export const categories = [
  { value: "cars", label: "Cars" },
  { value: "vans", label: "Vans" },
  { value: "bikes", label: "Bikes" },
  { value: "motorhomes", label: "Motorhomes" },
  { value: "caravans", label: "Caravans" },
  { value: "trucks", label: "Trucks" },
  { value: "farms", label: "Farm" },
  { value: "plants", label: "Plant" },
  { value: "rentals", label: "Rentals" },
  { value: "partAndAccessories", label: "Parts & Accessories" },
];

export const preventMinus = (e) => {
  if (e.code === "Minus" || e.code === "KeyE") {
    e.preventDefault();
  }
};

export const numbersOnly = (value) => {
  if (!value) return "";
  const number = value.toString().replace(/[^0-9]/g, "");
  return number;
};
