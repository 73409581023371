import React, { useEffect } from "react";
import { handleApiRequest } from "../../services/handleApiRequest";
import { getPageContent } from "../../redux/contentPages/thunk";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Button } from "react-bootstrap";

export default function ContentPage() {
  const { pageId } = useParams();
  const { pageContent } = useSelector((state) => state.contentPage);

  const handlePageContent = async () => {
    await handleApiRequest(getPageContent, pageId);
  };

  useEffect(() => {
    handlePageContent();
  }, [pageId]);

  return (
    <>
      <section className="">
        {/* <div className="fullSizeAddContainer" style={{ width: 980, height: 120 }}>
          Add Container
          <br />
          (980 x 120)
        </div> */}
        <div className="d-flex">
          {/* <div
            className={`fullSizeAddContainer me-3 d-none d-xl-flex ms-0`}
            style={{ width: 160, height: 600, minWidth: 160 }}
          >
            Add Container
            <br />
            (160 x 600)
          </div> */}
          <div className="mx-3">
            <div className="contentPageHeadingWraper">
              <Button variant="danger my-3">{pageContent.data?.page}</Button>
              <div />
            </div>
            <div dangerouslySetInnerHTML={{ __html: pageContent.data?.description }} />
          </div>
          {/* <div
            className={`fullSizeAddContainer me-3 d-none d-xl-flex ms-0`}
            style={{ width: 160, height: 600, minWidth: 160 }}
          >
            Add Container
            <br />
            (160 x 600)
          </div> */}
        </div>
      </section>
    </>
  );
}
