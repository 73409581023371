import React, { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { FaFacebookF, FaYoutube } from "react-icons/fa";
import { GrInstagram } from "react-icons/gr";
import { BsTwitterX } from "react-icons/bs";
import { ReactComponent as FastforwardIcon } from "../../Assets/icons/fast-forward.svg";
import { ReactComponent as InstagramIcon } from "../../Assets/icons/instagram.svg";
import { ReactComponent as FacebookIcon } from "../../Assets/icons/facebook.svg";
import { ReactComponent as TwitterIcon } from "../../Assets/icons/twitter.svg";
import { ReactComponent as YoutubeIcon } from "../../Assets/icons/youtube.svg";
import { useSelector } from "react-redux";
import { handleApiRequest } from "../../services/handleApiRequest";
import { getContentPageList } from "../../redux/contentPages/thunk";
import { facebookLink, youtubeLink, instagramLink, twitterLink } from "../../config";
import HorizontalAds from "../../googleAds/horizontalAds";
import SquareAds from "../../googleAds/squareAds";
import SquareFixAd from "../../googleAds/squareFixAd";

const Footer = () => {
  const { contentPageList } = useSelector((state) => state.contentPage);

  const hanldePageList = async () => {
    await handleApiRequest(getContentPageList);
  };

  useEffect(() => {
    hanldePageList();
  }, []);

  return (
    <div>
      <footer className="mainWrapper bg-white">
        <div className="my-2">
          <HorizontalAds dataAdSlot="9038223821" />
        </div>
        <Row>
          <Col lg={12}>
            <Row>
              <Col lg={3} sm={6} className="socialLink p-0">
                <a
                  href={facebookLink}
                  target="_blank"
                  className="d-flex justify-content-between p-2 text-dark"
                >
                  <p className="m-0">
                    <FaFacebookF />
                  </p>
                  <p className="m-0">FACEBOOK</p>
                </a>
              </Col>
              <Col lg={3} sm={6} className="socialLink p-0">
                <a
                  href={instagramLink}
                  target="_blank"
                  className="d-flex justify-content-between p-2 text-dark"
                >
                  <p className="m-0">
                    <GrInstagram />
                  </p>
                  <p className="m-0">INSTAGRAM</p>
                </a>
              </Col>

              <Col lg={3} sm={6} className="socialLink p-0">
                <a
                  href={youtubeLink}
                  target="_blank"
                  className="d-flex justify-content-between p-2 text-dark"
                >
                  <p className="m-0">
                    <FaYoutube />
                  </p>
                  <p className="m-0">YOUTUBE</p>
                </a>
              </Col>
              <Col lg={3} sm={6} className="socialLink p-0">
                <a
                  href={twitterLink}
                  target="_blank"
                  className="d-flex justify-content-between p-2 text-dark"
                >
                  <p className="m-0">
                    <BsTwitterX />
                  </p>
                  <p className="m-0">TWITTER</p>
                </a>
              </Col>
            </Row>
          </Col>
          <hr />
          <Col lg="12" className="my-2">
            <Row className="d-flex align-items-start flex-wrap">
              {Array.from({ length: 2 }).map((page, i) => (
                <Col key={i} lg={3} sm={6} className="p-0 ps-2">
                  <ul className="list-unstyled ps-0 mb-0">
                    {i === 0 && (
                      <li className="py-1">
                        <Link to={"contactus"} className="text-dark">
                          <FastforwardIcon />
                          Contact Us
                        </Link>
                      </li>
                    )}
                    {contentPageList.data?.slice(i * 4, i * 4 + 4 + i).map((link) => (
                      <li key={link._id} className="py-1">
                        <Link to={`page/${link._id}`} className="text-dark">
                          <FastforwardIcon />
                          {link.page}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </Col>
              ))}
              <Col lg={5}>
                <div className="">
                  <SquareFixAd
                    dataAdSlot="7645251103"
                    className="allFiltersAdd"
                    style={{ width: 400, height: 140 }}
                  />
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </footer>

      <Row className="gx-0">
        <Col lg="12" className="py-3 px-0 bg-dark text-white">
          <p className="m-0 text-center">© Copyright 2024 Autotitanic</p>
        </Col>
      </Row>
    </div>
  );
};

export default Footer;
