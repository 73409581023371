import React, { useEffect, useState } from "react";
import { Container, Button, Navbar, Nav, Dropdown } from "react-bootstrap";
import { Link, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as CompareIcon } from "../../Assets/icons/compare.svg";
import { ReactComponent as GridFilledIcon } from "../../Assets/icons/grid-filled.svg";
import { ReactComponent as ProfileHolder } from "../../Assets/icons/Profile-holder.svg";
import { ReactComponent as DownArrow } from "../../Assets/icons/down-arrow.svg";
import mainLogo from "../../Assets/Images/mainLogo2.png";
// import mainLogo from "../../Assets/Images/mainLogo.png";
import { categories } from "../../utils";
import isUserLoggedin from "../../utils/isUserLoggedin";
import { handleApiRequest } from "../../services/handleApiRequest";
import { getUserProfile } from "../../redux/profile/thunk";
import { resetFilters, selectCategory } from "../../redux/filters/slice";
import { logoutUser } from "../../redux/auth/slice";
import ConfirmationPopup from "../Modals/confirmationPop";

const Header = ({ sidebar, setSidebar }) => {
  const { pathname } = useLocation();
  const [params] = useSearchParams();
  const type = params.get("type") || "sell";
  let userCategory = params.get("category");
  userCategory = userCategory ? userCategory : type === "rent" && !userCategory ? "" : "cars";

  const navigate = useNavigate();
  const dispatch = useDispatch();
  // const { category } = useSelector((state) => state.filters);
  const { loggedinUser } = useSelector((state) => state.auth);
  const token = loggedinUser?.data?.token;
  const { userProfile } = useSelector((state) => state.profile);

  const [userAction, setUserAction] = useState(null);

  const handleSidebar = () => {
    setSidebar(!sidebar);
  };

  const handleUserProfile = async () => {
    await handleApiRequest(getUserProfile, {}, false);
  };

  const handleLogout = () => {
    dispatch(logoutUser());
  };

  useEffect(() => {
    if (token) {
      handleUserProfile();
    }
  }, [token]);

  useEffect(() => {
    const isValidCategory = categories.find((cat) => cat.value === userCategory);
    if (!isValidCategory && type !== "rent") {
      navigate("/");
    }
  }, [pathname]);

  const NavLinks = ({ title, i }) => {
    return (
      <div className="navLink pointer m-0 text-small position-relative">
        <p
          className={`navLinkText ${
            type === "rent" && title.value === "rentals"
              ? "text-danger"
              : type !== "rent" && userCategory === title.value
              ? "text-danger"
              : ""
          }`}
          onClick={() => {
            navigate(
              `/${title.value}?${
                title.value !== "rentals" ? "category=" + title.value + "&type=sell" : "type=rent"
              }`
            );
          }}
        >
          {title.label} <DownArrow width={8} height={8} />
        </p>
        {title.label !== "Rentals" && (
          <ul className="dropOtions list-unstyled position-absolute">
            <li
              onClick={() => {
                navigate(`/${title.value}/all?category=${title.value}&type=sell`);
                dispatch(resetFilters());
              }}
            >
              All {title.label}
            </li>
            <li
              onClick={() => {
                navigate(`/${title.value}/used?category=${title.value}&type=sell&condition=used`);
                dispatch(resetFilters());
              }}
            >
              Used {title.label}
            </li>
            <li
              onClick={() => {
                navigate(`/${title.value}/new?category=${title.value}&type=sell&condition=new`);
                dispatch(resetFilters());
              }}
            >
              New {title.label}
            </li>
          </ul>
        )}
        {title.label === "Rentals" && (
          <ul className="dropOtions list-unstyled position-absolute">
            {categories.map(
              (category, i) =>
                category.value !== "partAndAccessories" &&
                category.value !== "rentals" && (
                  <li
                    key={category.value}
                    onClick={() =>
                      navigate(`/${category.value}/rent?category=${category.value}&type=rent`)
                    }
                  >
                    {category.label.endsWith("s")
                      ? `${category.label.slice(0, -1)} `
                      : `${category.label} `}
                    Rentals
                  </li>
                )
            )}
          </ul>
        )}
      </div>
    );
  };

  return (
    <>
      <header className="px-2 header">
        <Navbar expand="lg" className="mainWrapper h-100 pe-0">
          <div className=" d-flex align-items-center justify-content-between w-100 mx-lg-2">
            <Container fluid className="m-0 p-0 d-flex">
              <div className="left d-flex align-items-center">
                <Button
                  onClick={handleSidebar}
                  variant="transparent"
                  className="d-lg-none Toggle border-0 p-0 me-2 d-flex align-items-center justify-content-center"
                >
                  <GridFilledIcon />
                </Button>
              </div>
              <div className="">
                <Link to="/" className="h-100">
                  <img src={mainLogo} alt="" className="img-fluid" style={{ height: 15 }} />
                </Link>
              </div>
            </Container>

            <div className="d-flex align-items-center">
              <Nav className="d-none d-lg-flex me-2">
                {categories.map((category, i) => (
                  <NavLinks key={category.label} title={category} i={i} />
                ))}
              </Nav>
              <ul className="list-unstyled ps-0 mb-0 d-flex align-items-center gap-10">
                <li className="d-flex align-items-center position-relative">
                  <Button
                    variant="transparent"
                    className="border-0 p-0"
                    onClick={() => {
                      if (userProfile.data?.compareCount > 0) {
                        navigate("/CompareList");
                      } else if (!isUserLoggedin()) {
                        navigate("/login");
                      }
                    }}
                  >
                    <span className="icn">
                      <CompareIcon className="headerCompareIcon" />
                    </span>
                    {userProfile.data?.compareCount > 0 && (
                      <p className="compareCount">{userProfile.data?.compareCount}</p>
                    )}
                  </Button>
                </li>
                <li className="d-flex align-items-center mx-1 position-relative">
                  <Button
                    variant="danger"
                    className="headerPostBtn border-0 text-nowrap"
                    onClick={() => {
                      if (isUserLoggedin()) {
                        navigate("postAdvert");
                      } else {
                        navigate("/login");
                      }
                    }}
                  >
                    Post an Advert
                  </Button>
                </li>
                <li
                  className="position-relative d-flex align-items-center gap-10"
                  style={{ minWidth: 45 }}
                >
                  <Button
                    variant="transparent"
                    className="largeScreenSignIn d-none font-small border-0 p-0"
                    onClick={() => navigate("/profile")}
                  >
                    <p className="m-0 icn">
                      {isUserLoggedin() ? (
                        userProfile.data?.userAvatar || userProfile.data?.dealerLogo ? (
                          <img
                            src={userProfile.data.userAvatar || userProfile.data.dealerLogo}
                            className="headerAvatar"
                          />
                        ) : (
                          <ProfileHolder />
                        )
                      ) : (
                        ""
                      )}
                    </p>
                    <p className="userName">
                      {userProfile.data?.name ? (
                        userProfile.data?.name?.split(" ")[0]
                      ) : (
                        <>
                          Sign In | <br /> Register
                        </>
                      )}
                    </p>
                  </Button>
                  <Dropdown className="smallScreenSignIn">
                    <Dropdown.Toggle variant="transparent" id="dropdown-basic">
                      <Button
                        variant="transparent"
                        className="font-small border-0 p-0"
                        onClick={() => {
                          if (!isUserLoggedin()) {
                            navigate("/profile");
                          }
                        }}
                      >
                        <p className="m-0 icn">
                          {userProfile.data?.userAvatar || userProfile.data?.dealerLogo ? (
                            <img
                              src={userProfile.data.userAvatar || userProfile.data.dealerLogo}
                              className="headerAvatar"
                            />
                          ) : (
                            <ProfileHolder />
                          )}
                        </p>
                        <p className="userName">
                          {userProfile.data?.name?.split(" ")[0] || "Sign In"}
                        </p>
                      </Button>
                    </Dropdown.Toggle>

                    {isUserLoggedin() && (
                      <Dropdown.Menu className="dropdown-menu-end">
                        <Dropdown.Item onClick={() => navigate("/profile")}>Profile</Dropdown.Item>
                        <Dropdown.Item onClick={() => navigate("/my-items")}>
                          My Posts
                        </Dropdown.Item>
                        <Dropdown.Item onClick={() => navigate("/my-wishlist")}>
                          My Wishlist
                        </Dropdown.Item>
                        <Dropdown.Item onClick={() => navigate("/reviews")}>
                          My Reviews
                        </Dropdown.Item>
                        <Dropdown.Item onClick={() => navigate("/change-password")}>
                          Change password
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() => {
                            setUserAction({ action: "logout" });
                          }}
                        >
                          Logout
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    )}
                  </Dropdown>
                </li>
              </ul>
            </div>
          </div>
        </Navbar>
      </header>
      {userAction?.action === "logout" && (
        <ConfirmationPopup
          userAction={userAction}
          setUserAction={setUserAction}
          onSubmit={handleLogout}
          submitBtnLabel="Logout"
        />
      )}
    </>
  );
};
export default Header;
