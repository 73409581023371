import React from "react";
import { Navigate, useLocation, useParams, useSearchParams } from "react-router-dom";
import VehiclesList from "../vehiclesList";
import { categories } from "../../utils";
import Home from "../home";

export default function NavComponent() {
  const { pathname } = useLocation();
  const [params] = useSearchParams();
  let condition = params.get("condition");

  const { categoryFilter } = useParams();

  const categoryHome = categories.find((category) => pathname.includes(category.value));

  return categoryFilter === "new" || categoryFilter === "used" || categoryFilter === "all" ? (
    <VehiclesList />
  ) : categoryHome || pathname === "/" ? (
    <Home />
  ) : (
    <Navigate to="/login" />
  );
}
