export const vansBodyStyleOptions = [
  { value: "miniBus", label: "Mini Bus" },
  { value: "suv", label: "SUV" },
  { value: "luton", label: "Luton" },
  { value: "boxVan", label: "Box Van" },
  { value: "camper", label: "Camper" },
  { value: "coupe", label: "Coupe" },
  { value: "carDerivedVan", label: "Car Derived Van" },
  { value: "chassisCab", label: "Chassis Cab" },
  { value: "combiVa", label: "Combi Va" },
  { value: "curtainSide", label: "Curtain Side" },
  { value: "dropside", label: "Dropside" },
  { value: "highRoofVan", label: "High Roof Van" },
  { value: "mpv", label: "MPV" },
  { value: "panelVan", label: "Panel Van" },
  { value: "pickup", label: "Pick-up" },
  { value: "windowVan", label: "Window Van" },
  { value: "tipper", label: "Tipper" },
  { value: "vehicleTransport", label: "Vehicle Transport" },
  { value: "unspecified", label: "Unspecified" },
];

export const vansCabTypeOptions = [
  { value: "crewCab", label: "Crew cab" },
  { value: "doubleCab", label: "Double cab" },
  { value: "lowAccessCab", label: "Low access cab" },
  { value: "standardCab", label: "Standard cab" },
  { value: "superCab", label: "Super cab" },
  { value: "transporterCab", label: "Transporter cab" },
  { value: "unlisted", label: "Unlisted" },
];

export const vansEnginePowerOptions = [
  { value: 0, label: "0bhp" },
  { value: 50, label: "50bhp" },
  { value: 100, label: "100bhp" },
  { value: 150, label: "150bhp" },
  { value: 200, label: "200bhp" },
  { value: 250, label: "250bhp" },
  { value: 300, label: "300bhp" },
  { value: 350, label: "350bhp" },
  { value: 400, label: "400bhp" },
  { value: 450, label: "450bhp" },
  { value: 500, label: "500bhp" },
  { value: 600, label: "600bhp" },
  { value: 650, label: "650bhp" },
  { value: 700, label: "700bhp" },
  { value: 950, label: "950bhp" },
  { value: 1000, label: "1000bhp" },
];

export const vansMileageList = [
  { value: "", label: "Any" },
  { value: 100, label: "Up to 100 M" },
  { value: 500, label: "Up to 500 M" },
  { value: 1000, label: "Up to 1000 M" },
  { value: 5000, label: "Up to 5000 M" },
  { value: 10000, label: "Up to 10000 M" },
  { value: 20000, label: "Up to 20000 M" },
  { value: 30000, label: "Up to 30000 M" },
  { value: 40000, label: "Up to 40000 M" },
  { value: 50000, label: "Up to 50000 M" },
  { value: 60000, label: "Up to 60000 M" },
  { value: 70000, label: "Up to 70000 M" },
  { value: 80000, label: "Up to 80000 M" },
  { value: 90000, label: "Up to 90000 M" },
  { value: 100000, label: "Up to 100000 M" },
  { value: 120000, label: "Up to 120000 M" },
  { value: 140000, label: "Up to 140000 M" },
  { value: 160000, label: "Up to 160000 M" },
  { value: 180000, label: "Up to 180000 M" },
  { value: 200000, label: "Up to 200000 M" },
  { value: 999999, label: "Over 200000 M" },
];

export const vansSeatOptions = [
  { value: 1, label: "1 Seat" },
  { value: 2, label: "2 Seat" },
  { value: 3, label: "3 Seat" },
  { value: 4, label: "4 Seat" },
  { value: 5, label: "5 Seat" },
  { value: 6, label: "6 Seat" },
];

export const vansWheelBaseOptions = [
  { value: "LWB", label: "LWB" },
  { value: "MWB", label: "MWB" },
  { value: "STD", label: "STD" },
  { value: "SWB", label: "SWB" },
  { value: "XLWB", label: "XLWB" },
  { value: "XXLWB", label: "XXLWB" },
  { value: "unlisted", label: "Unlisted" },
];
