import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getWishlist } from "../../../redux/vehicles/thunk";
import { handleApiRequest } from "../../../services/handleApiRequest";
import { isArray } from "../../../utils/dataTypes";
import VehicleCard from "../../vehiclesList/components/vehicleCard";
import { MdOutlineArrowBackIosNew, MdOutlineArrowForwardIos } from "react-icons/md";
import { Form } from "react-bootstrap";
import HorizontalAds from "../../../googleAds/horizontalAds";
import { listAds } from "../../vehiclesList";
import MyPagination from "../../../components/pagination";

export default function Wishlist() {
  const { wishlist } = useSelector((state) => state.vehicles);
  const [paginationDetails, setPaginationDetails] = useState({
    page: 1,
    limit: 25,
    sortBy: "createdAt",
    order: -1,
  });

  const totalPage = Math.ceil(wishlist.data?.totalCount / paginationDetails.limit) || 0;

  const handlePage = (btn) => {
    if (btn === "back" && paginationDetails.page > 1) {
      setPaginationDetails((prev) => ({ ...prev, page: prev.page - 1 }));
    } else if (btn === "next" && paginationDetails.page < totalPage) {
      setPaginationDetails((prev) => ({ ...prev, page: prev.page + 1 }));
    }
  };

  const handleWishlist = async () => {
    await handleApiRequest(getWishlist, paginationDetails);
  };

  useEffect(() => {
    handleWishlist();
  }, [paginationDetails]);

  return (
    <>
      <h5>My Wishlist ({wishlist.data?.totalCount || 0})</h5>
      <div className="d-flex justify-content-between align-items-center my-2">
        <div>
          <button
            className={`paginationBtn ${paginationDetails.page === 1 ? "disabled" : ""}`}
            onClick={() => handlePage("back")}
          >
            <MdOutlineArrowBackIosNew />
          </button>
          <button
            className={`paginationBtn ${paginationDetails.page === totalPage ? "disabled" : ""}`}
            onClick={() => handlePage("next")}
          >
            <MdOutlineArrowForwardIos />
          </button>
          <span>
            Page {paginationDetails.page} of {totalPage}
          </span>
        </div>
        <div className="d-flex gap-10">
          <Form.Select
            className="myVehiclesFilter"
            name="limit"
            onChange={(e) => setPaginationDetails((prev) => ({ ...prev, limit: e.target.value }))}
          >
            <option value={25}>25</option>
            <option value={50}>50</option>
            <option value={75}>75</option>
            <option value={100}>100</option>
          </Form.Select>
          {/* <Form.Select className="myVehiclesFilter" name="status" onChange={handleChange}>
            <option value="">All</option>
            <option value="pending">Under Review</option>
            <option value="draft">Draft</option>
            <option value="approved">Approved</option>
            <option value="rejected">Rejected</option>
            <option value="deleted">Deleted</option>
          </Form.Select> */}
        </div>
      </div>
      {wishlist.data?.totalCount > 0 ? (
        isArray(wishlist.data?.items).map((item, i) => (
          <>
            <VehicleCard key={item._id} vehicle={item.vehicle || {}} wishlist={item._id} />
            {i % 10 === 0 && (
              <div>
                <HorizontalAds dataAdSlot={listAds[Math.floor(Math.random() * 4)]} />
              </div>
            )}
          </>
        ))
      ) : (
        <h4 className="text-center my-5">No item found</h4>
      )}
      <MyPagination
        paginationDetails={paginationDetails}
        setPaginationDetails={setPaginationDetails}
        totalPosts={wishlist.data?.totalCount}
      />
    </>
  );
}
