import React from "react";
import { useSelector } from "react-redux";
import Select from "react-select";
import africaFlag from "../../Assets/Images/africa-flag.png";
import { isArray } from "../../utils/dataTypes";

export default function SelectBox({ ...props }) {
  return (
    <>
      <Select
        className="countrySelect w-100"
        components={{
          IndicatorSeparator: null,
          ...props.components,
        }}
        styles={{
          placeholder: (basic) => {
            return { ...basic, whiteSpace: "nowrap", textOverflow: "ellipsis" };
          },
          ...props.styles,
        }}
        {...props}
      />
    </>
  );
}

export function CountrySelect({ ...props }) {
  const { allCountries } = useSelector((state) => state.countryAndCity);

  const CountryFilterOptions = ({ data, isDisabled, innerProps }) => {
    return !isDisabled ? (
      <div {...innerProps} className="pointer p-2">
        <img className="countryDropFlag" src={data.flag || africaFlag} />
        <span className="ms-2">{data.name}</span>
      </div>
    ) : null;
  };

  return (
    <>
      <SelectBox
        placeholder={props.placeholder || "Select country"}
        components={{ Option: CountryFilterOptions, IndicatorSeparator: null }}
        options={
          props.africa
            ? [{ _id: "", name: "Africa", flag: africaFlag }, ...isArray(allCountries.data?.items)]
            : isArray(allCountries.data?.items)
        }
        getOptionLabel={(option) => {
          return (
            <div className="pointer p-2">
              <img className="countryDropFlag" src={option.flag} />
              <span className="ms-1">{option.name}</span>
            </div>
          );
        }}
        getOptionValue={(option) => option._id}
        {...props}
      />
    </>
  );
}
