// export const REACT_APP_API_BASE_URL = "http://localhost:4000";
export const REACT_APP_API_BASE_URL = "https://api.autotitanic.com";
export const REACT_APP_API_VERSION = "/api/v1/";
export const whatsAppNumber = "+233257460080";
export const youtubeLink = "https://www.youtube.com/channel/UC_5WpskBGyfpukskRQRGuZQ";
export const facebookLink = "https://www.facebook.com/profile.php?id=61558921232789";
export const instagramLink = "https://www.instagram.com/autotitanic.africa/";
export const twitterLink = "https://x.com/auto_titanic";
export const passwordRegex = /^(?=.*[a-zA-Z])(?=.*\d).{6,}$/;
// export const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
