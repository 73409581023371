export const plantsCategoryOptions = [
  { value: "aerialPlatforms", label: "Aerial platforms" },
  { value: "attachments", label: "Attachments" },
  { value: "buildingsStorage", label: "Buildings & storage" },
  { value: "compactionMachines", label: "Compaction machines" },
  { value: "compressors", label: "Compressors" },
  { value: "concrete", label: "Concrete" },
  { value: "conveyorBelts", label: "Conveyor belts" },
  { value: "cranes", label: "Cranes" },
  { value: "dozers", label: "Dozers" },
  { value: "dumpers", label: "Dumpers" },
  { value: "excavators", label: "Excavators" },
  { value: "forklifts", label: "Forklifts" },
  { value: "generators", label: "Generators" },
  { value: "graders", label: "Graders" },
  { value: "loaders", label: "Loaders" },
  {
    value: "miningQuarryEquipment",
    label: "Mining & quarry equipment",
  },
  { value: "roadPavingMachine", label: "Road & paving machine" },
  {
    value: "roadConstructionMachine",
    label: "Road construction machine",
  },
  { value: "scrappers", label: "Scrappers" },
  { value: "telehandlers", label: "Telehandlers" },
  { value: "toolsEquipment", label: "Tools & equipment" },
  { value: "trailers", label: "Trailers" },
  { value: "wasterRecycling", label: "Waster & recycling" },
  { value: "others", label: "Others" },
];

export const plantsSubCategoryOptions = [
  {
    category: "aerialPlatforms",
    value: "articulatedPlatforms",
    label: "Articulated platforms",
  },
  { category: "aerialPlatforms", value: "scissors", label: "Scissors" },
  {
    category: "aerialPlatforms",
    value: "telescopicPlatforms",
    label: "Telescopic platforms",
  },
  { category: "attachments", value: "others", label: "Others" },
  {
    category: "buildingsStorage",
    value: "buildings",
    label: "Buildings",
  },
  { category: "buildingsStorage", value: "tanks", label: "Tanks" },
  {
    category: "buildingsStorage",
    value: "waterFuelBowers",
    label: "Water & fuel bowers",
  },
  {
    category: "buildingsStorage",
    value: "welfareUnits",
    label: "Welfare units",
  },
  { category: "buildingsStorage", value: "others", label: "Others" },
  {
    category: "compactionMachines",
    value: "doubleDrum",
    label: "Double drum",
  },
  {
    category: "compactionMachines",
    value: "plateCompactor",
    label: "Plate compactor",
  },
  {
    category: "compactionMachines",
    value: "singleDrumSmooth",
    label: "Single drum smooth",
  },
  { category: "compactionMachines", value: "trench", label: "Trench" },
  { category: "compactionMachines", value: "others", label: "Others" },
  { category: "compressors", value: "static", label: "Static" },
  { category: "compressors", value: "trailer", label: "Trailer" },
  {
    category: "concrete",
    value: "bathingSolis",
    label: "Bathing & solis",
  },
  { category: "concrete", value: "mixer", label: "Mixer" },
  { category: "concrete", value: "pumps", label: "Pumps" },
  { category: "conveyorBelts", value: "others", label: "Others" },
  {
    category: "cranes",
    value: "crawlerCranes",
    label: "Crawler cranes",
  },
  {
    category: "cranes",
    value: "truckMountedCranes",
    label: "Truck mounted cranes",
  },
  { category: "cranes", value: "others", label: "Others" },
  {
    category: "dozers",
    value: "trackedDozers",
    label: "Tracked dozers",
  },
  { category: "dozers", value: "others", label: "Others" },
  {
    category: "dumpers",
    value: "articulatedDumpTrucks",
    label: "Articulated dump trucks",
  },
  { category: "dumpers", value: "dumpTrucks", label: "Dump trucks" },
  { category: "dumpers", value: "miniDumpers", label: "Mini dumpers" },
  { category: "dumpers", value: "skipDumpers", label: "Skip dumpers" },
  {
    category: "dumpers",
    value: "trackDumpers",
    label: "Track dumpers",
  },
  {
    category: "excavators",
    value: "attachments",
    label: "Attachments",
  },
  {
    category: "excavators",
    value: "mircoExcavators",
    label: "Mirco excavators",
  },
  {
    category: "excavators",
    value: "miniExcavators",
    label: "Mini excavators",
  },
  {
    category: "excavators",
    value: "truckExcavators",
    label: "Truck excavators",
  },
  {
    category: "excavators",
    value: "wheeledExcavators",
    label: "Wheeled excavators",
  },
  {
    category: "forklifts",
    value: "terrainForklifts",
    label: "Terrain forklifts",
  },
  { category: "forklifts", value: "attachments", label: "Attachments" },
  {
    category: "forklifts",
    value: "containerHandlers",
    label: "Container handlers",
  },
  {
    category: "forklifts",
    value: "counterBalanceForklifts",
    label: "Counter balance forklifts",
  },
  {
    category: "forklifts",
    value: "narrowAisleTrucks",
    label: "Narrow aisle trucks",
  },
  {
    category: "forklifts",
    value: "pedestrianStackers",
    label: "Pedestrian stackers",
  },
  {
    category: "forklifts",
    value: "reachTrucks",
    label: "Reach trucks",
  },
  {
    category: "forklifts",
    value: "sideLoaders",
    label: "Side loaders",
  },
  { category: "generators", value: "static", label: "Static" },
  { category: "generators", value: "trailer", label: "Trailer" },
  { category: "graders", value: "others", label: "Others" },
  {
    category: "loaders",
    value: "backhoeLoaders",
    label: "Backhoe loaders",
  },
  {
    category: "loaders",
    value: "frontShovels",
    label: "Front shovels",
  },
  {
    category: "loaders",
    value: "skidSteerLoaders",
    label: "Skid steer loaders",
  },
  {
    category: "loaders",
    value: "trackLoaders",
    label: "Track loaders",
  },
  {
    category: "loaders",
    value: "wheeledLoaders",
    label: "Wheeled loaders",
  },
  { category: "loaders", value: "others", label: "Others" },
  { category: "miningQuarryEquipment", value: "others", label: "Others" },
  { category: "roadPavingMachine", value: "others", label: "Others" },
  {
    category: "roadConstructionMachine",
    value: "others",
    label: "Others",
  },
  { category: "scrappers", value: "others", label: "Others" },
  { category: "telehandlers", value: "others", label: "Others" },
  {
    category: "toolsEquipment",
    value: "liftingEuipment",
    label: "Lifting euipment",
  },
  { category: "toolsEquipment", value: "others", label: "Others" },
  { category: "trailers", value: "attachments", label: "Attachments" },
  { category: "trailers", value: "flatbed", label: "Flatbed" },
  {
    category: "trailers",
    value: "generalPurpose",
    label: "General purpose",
  },
  { category: "trailers", value: "lowLoader", label: "Low loader" },
  { category: "trailers", value: "tipping", label: "Tipping " },
  { category: "trailers", value: "others", label: "Others" },
  { category: "wasterRecycling", value: "skips", label: "Skips" },
  { category: "wasterRecycling", value: "sweepers", label: "Sweepers" },
  {
    category: "wasterRecycling",
    value: "woodChippers",
    label: "Wood chippers",
  },
  { category: "wasterRecycling", value: "others", label: "Others" },
];
