import React from "react";
import Drawer from "react-modern-drawer";
import { MdOutlineArrowBackIosNew } from "react-icons/md";

export default function DescriptionDrawer({ userAction, setUserAction }) {
  const closeDrawer = () => {
    setUserAction(null);
  };

  return (
    <>
      <Drawer
        open={userAction?.type === "showDesc"}
        onClose={closeDrawer}
        duration={1000}
        direction="right"
        className="myDrawer overflow-auto px-3 py-2"
      >
        {/* <span className="drawerCloseIcon pointer" onClick={closeDrawer}>
          <RxCross2 />
        </span> */}
        <h5 className="primaryColor pointer m-0" onClick={closeDrawer}>
          <MdOutlineArrowBackIosNew className="me-1" />
          Back
        </h5>
        <h4 className="text-center m-0 mt-4">Description</h4>
        <div className="d-flex align-items-center m-3">
          <div
            dangerouslySetInnerHTML={{
              __html: userAction?.desc,
            }}
          />
        </div>
      </Drawer>
    </>
  );
}
