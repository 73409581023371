import React, { useRef, useState } from "react";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { FaHeart } from "react-icons/fa";
import { FaRegClock } from "react-icons/fa";
import { TiInfoLarge } from "react-icons/ti";
// import star1 from "../../../Assets/Images/star-filled.jpeg";
import star1 from "../../../Assets/Images/star-filled.jpeg";
import star2 from "../../../Assets/Images/star-half.jpeg";
import star3 from "../../../Assets/Images/star-empty.jpeg";
import { GiCheckMark } from "react-icons/gi";
import { ReactComponent as LocationIcon } from "../../../Assets/icons/location.svg";
import { ReactComponent as CompareIcon } from "../../../Assets/icons/compare.svg";
import { ReactComponent as HeartIcon } from "../../../Assets/icons/heart.svg";
import { handleApiRequest } from "../../../services/handleApiRequest";
import parseKey, { parseCamelKey } from "../../../utils/parseKey";
import {
  addToCompare,
  addToWishlist,
  getVehicleList,
  getWishlist,
  removeCompareListItem,
  removeWishlistItem,
  updateVehicle,
} from "../../../redux/vehicles/thunk";
import DeletePopup from "../../../components/Modals/DeletePop";
import { errorMsg, successMsg } from "../../../utils/toastMsg";
import { getUserProfile } from "../../../redux/profile/thunk";
import { useSelector } from "react-redux";
import isUserLoggedin from "../../../utils/isUserLoggedin";
import moment from "moment";
import { MyTooltip } from "../../../components/myTooltip/myTooltip";
import { isArray } from "../../../utils/dataTypes";
import ReviewDrawer from "../../../components/sidebar/Reviews";
import { defaultPage, reviewMsg } from "../../../utils/constants";

export default function VehicleCard({
  vehicle,
  wishlist,
  myVehicle,
  setVehiclesList = () => {},
  i,
  paginationDetails = defaultPage,
}) {
  const navigate = useNavigate();
  const imageRef = useRef();
  const { userProfile } = useSelector((state) => state.profile);
  const { filters } = useSelector((state) => state.filters);
  const [userAction, setUserAction] = useState(null);

  let rating =
    isArray(vehicle?.sellerReviews)?.reduce((a, b) => a + Number(b.rating), 0) /
      vehicle?.sellerReviews?.length || 0;
  rating = rating ? rating.toFixed(1) : 0;

  const handleWishlist = async () => {
    await handleApiRequest(getWishlist);
  };

  const refreshVehicleList = async () => {
    const newFilters = {};
    Object.entries(filters).forEach((filter) => {
      newFilters[filter[0]] = filter[1].value || filter[1]._id;
    });
    const request = {
      filters: { ...newFilters, status: "approved" },
      paginationDetails: paginationDetails,
    };
    const response = await handleApiRequest(getVehicleList, request);
    if (response?.status) {
      setVehiclesList(response.data);
    }
  };

  const handleWishlistItem = async (e, actionType) => {
    e.stopPropagation();

    if (!isUserLoggedin()) {
      return errorMsg("Please sign-in or register to compare items");
    }

    if (actionType === "add") {
      const response = await handleApiRequest(addToWishlist, {
        id: vehicle._id,
      });
      if (response?.status) {
        successMsg("Added to Wishlist");
        refreshVehicleList();
      }
    } else {
      const response = await handleApiRequest(removeWishlistItem, {
        id: vehicle?.wishlistItem?._id,
      });
      if (response?.status) {
        successMsg("Removed from Wishlist");
        setVehiclesList((prev) => {
          const newList = JSON.parse(JSON.stringify([...(prev.items || [])]));
          newList[i].wishlistItem = null;
          return { ...prev, items: newList };
        });
      }
    }
  };

  const handleRemoveWishlistItem = async (e) => {
    e.stopPropagation();
    const response = await handleApiRequest(removeWishlistItem, { id: wishlist });
    if (response?.status) {
      handleWishlist();
    }
  };

  const handleUserProfile = async () => {
    await handleApiRequest(getUserProfile);
  };

  const handleCompareItem = async (e, actionType) => {
    e.stopPropagation();

    if (!isUserLoggedin()) {
      return errorMsg("Please sign-in or register to compare items");
    }

    if (actionType === "add") {
      const response = await handleApiRequest(addToCompare, { vehicle: vehicle._id });

      if (userProfile.data?.compareCount >= 4) {
        navigate("/CompareList");
      }
      if (response?.status) {
        successMsg("Added to compare list");
        handleUserProfile();
        refreshVehicleList();
      }
    } else {
      const response = await handleApiRequest(removeCompareListItem, {
        id: vehicle?.compareItem?._id,
      });
      if (response?.status) {
        successMsg("Removed from compare list");
        handleUserProfile();
        setVehiclesList((prev) => {
          const newList = JSON.parse(JSON.stringify([...(prev.items || [])]));
          newList[i].compareItem = null;
          return { ...prev, items: newList };
        });
      }
    }
  };

  const handleVehicleList = async () => {
    const request = { filters: { status: "", user: userProfile.data?._id }, paginationDetails };
    await handleApiRequest(getVehicleList, request);
  };

  const handleDeletePost = async () => {
    const response = await handleApiRequest(updateVehicle, {
      _id: userAction.id,
      status: "deleted",
    });
    if (response?.status) {
      handleVehicleList();
    }
  };

  const handleReviews = (e) => {
    e.stopPropagation();
    setUserAction({ action: "showReviews", seller: vehicle?.user });
  };

  return (
    <>
      <div className={`position-relative`} onClick={() => navigate(`/details/${vehicle._id}`)}>
        <div className={`vehicleCardWrapper ${vehicle?.isFeatured ? "shadow-none" : ""} `}>
          <div
            className={`pointer py-2 d-flex m-0 ${
              vehicle?.isFeatured ? "featuredVehicleCard" : ""
            }`}
          >
            <div className="vehicleCardImageWrapper d-flex">
              <div className="vehicleCardMainImage position-relative">
                <div className="mainImageWrapper position-relative" style={{ marginRight: 1 }}>
                  <img
                    ref={imageRef}
                    src={vehicle.media?.[0]?.url}
                    srcSet={vehicle.media?.[0]?.url}
                    className="mainImage"
                  />
                </div>
                {vehicle?.isFeatured && <button className="featuredBtn">Featured</button>}
              </div>
              <div className="vehicleCardSideImage px-0 d-flex flex-column">
                {vehicle.media?.slice(1, 4).map((image, i) => (
                  <img
                    key={image?.url}
                    src={image?.url}
                    className={`sideImage`}
                    style={i === 1 ? { marginBlock: 1 } : {}}
                  />
                ))}
              </div>
            </div>
            <div className="vehicleCardDetails px-2 my-2 my-lg-0 d-flex flex-column">
              <h6 className="d-flex align-items-center justify-content-between">
                <Button
                  className={`rounded-pill py-1 ${
                    vehicle?.condition === "used" ? "bg-danger" : "mainDarkColor"
                  }`}
                >
                  {parseCamelKey(vehicle?.condition)}
                </Button>
                {!myVehicle && !wishlist && (
                  <div className="d-flex gap-10">
                    {vehicle?.wishlistItem ? (
                      <Button variant="" className={`border border-danger rounded-pill py-1 `}>
                        <FaHeart
                          className="text-danger"
                          style={{ width: 20 }}
                          onClick={(e) => handleWishlistItem(e, "remove")}
                        />
                      </Button>
                    ) : (
                      <Button variant="" className={`border rounded-pill py-1 `}>
                        <HeartIcon
                          className="redIcon"
                          style={{ width: 20 }}
                          onClick={(e) => handleWishlistItem(e, "add")}
                        />
                      </Button>
                    )}
                    <Button
                      className={`rounded-pill py-1 ${
                        vehicle?.user?.userType === "dealer" ? "bg-danger" : "mainDarkColor"
                      }`}
                    >
                      {parseCamelKey(vehicle?.user?.userType)}
                    </Button>
                  </div>
                )}
              </h6>
              <h6 className="d-flex align-items-center justify-content-between m-0">
                <div
                  className="darkColor d-flex align-items-center gap-1 m-0 fw-bold"
                  style={{ fontSize: 18 }}
                >
                  <p className="m-0">{vehicle?.currency} </p>
                  <p className="m-0"> {vehicle?.price?.toLocaleString()}</p>
                </div>
                {!myVehicle && !wishlist ? (
                  vehicle?.compareItem ? (
                    <Button
                      variant=""
                      className={`border border-danger rounded-pill py-1 `}
                      onClick={(e) => handleCompareItem(e, "remove")}
                    >
                      <CompareIcon className="redIcon" />
                      Added to Compare
                      <GiCheckMark className="checkMark ms-1" />
                    </Button>
                  ) : (
                    <Button
                      variant=""
                      className={`border rounded-pill py-1 `}
                      onClick={(e) => handleCompareItem(e, "add")}
                    >
                      <CompareIcon className="redIcon" />
                      Add to Compare
                    </Button>
                  )
                ) : (
                  ""
                )}
              </h6>
              <p className="m-0">
                {vehicle.make?.label ? vehicle.make?.label + " " + vehicle.model?.label : ""}
              </p>
              {!myVehicle && (
                <p className={`text-truncate fw-bold text-danger`}>{vehicle?.title}</p>
              )}

              {vehicle.sellOrRent === "rent" ? (
                <p className="vehicleCardVehicleDetails mb-3 fw-bold">
                  {`${parseKey(vehicle?.year)} Reg | ${parseCamelKey(vehicle.fuelType)} | `}
                  {vehicle.type === "cars"
                    ? `${parseKey(vehicle?.gearBox)} | ${parseKey(vehicle?.engineSize)}`
                    : vehicle.type === "vans" || vehicle.type === "motorhomes"
                    ? `${parseKey(vehicle?.gearBox)} | ${parseKey(vehicle?.fuelType)}`
                    : vehicle.type === "bikes"
                    ? `${parseKey(vehicle?.enginePower)} | ${parseKey(vehicle?.bodyType)}`
                    : vehicle.type === "caravans"
                    ? `${parseKey(vehicle?.birth)} Berth | ${parseKey(vehicle?.category)}`
                    : vehicle.type === "farms"
                    ? `${parseKey(vehicle?.condition)}`
                    : vehicle.type === "plants"
                    ? `${parseKey(vehicle?.condition)}| ${parseKey(vehicle?.category)}`
                    : vehicle.type === "trucks"
                    ? `${parseKey(vehicle?.condition)}| ${parseKey(vehicle?.enginePower)}`
                    : `${parseKey(vehicle?.gearBox)} | ${parseKey(vehicle?.condition)}`}
                </p>
              ) : (
                <p className="vehicleCardVehicleDetails mb-3 fw-bold">
                  {vehicle.year ? `${vehicle.year} Reg | ` : ""}
                  {["cars", "vans", "motorhomes"]?.includes(vehicle.type)
                    ? `${parseCamelKey(vehicle?.bodyStyle)} | ${parseKey(
                        vehicle?.mileage
                      )} Miles | ${vehicle.engineSize} | ${parseCamelKey(vehicle.gearBox)} | 
                      ${parseCamelKey(vehicle.fuelType)}`
                    : vehicle.type === "bikes"
                    ? `${parseKey(vehicle?.bodyStyle)} | ${parseKey(vehicle?.mileage)} Miles | ${
                        vehicle.fuelType
                      }`
                    : vehicle.type === "caravans"
                    ? `${parseKey(vehicle.condition)} | ${vehicle.mileage} Miles | ${parseKey(
                        vehicle?.birth
                      )} Berth`
                    : vehicle.type === "farms"
                    ? `${parseKey(vehicle?.condition)} | ${parseKey(
                        vehicle.mileage
                      )} Miles | ${parseCamelKey(vehicle.fuelType)}`
                    : vehicle.type === "plants"
                    ? `${parseKey(vehicle?.condition)}| ${parseKey(
                        vehicle?.mileage
                      )} Miles | ${parseCamelKey(vehicle.fuelType)} | ${parseCamelKey(
                        vehicle.category
                      )} | ${parseCamelKey(vehicle.subCategory)}`
                    : vehicle.type === "trucks"
                    ? `${parseCamelKey(vehicle?.category)} | ${parseKey(
                        vehicle?.mileage
                      )} Miles | ${parseCamelKey(vehicle.fuelType)}`
                    : vehicle.type === "partAndAccessories"
                    ? `${parseCamelKey(vehicle.condition)} ${parseCamelKey(
                        vehicle?.category
                      )} | ${parseKey(vehicle?.subCategory)}`
                    : ``}
                </p>
              )}

              <div style={{ flex: 1 }} />
              <p className="d-flex align-items-center mb-1">
                <b>Seller review:</b>
                {rating ? (
                  <>
                    {Array.from({ length: 5 }).map((_, i) => {
                      return rating > i && rating < i + 1 ? (
                        <img key={i} src={star2} className="ratingStar" />
                      ) : rating <= i ? (
                        <img key={i} src={star3} className="ratingStar" />
                      ) : (
                        <img key={i} src={star1} className="ratingStar" />
                      );
                    })}
                    {rating || "No Rating yet"}
                  </>
                ) : (
                  ""
                )}
                <span onClick={handleReviews}>
                  &nbsp;({vehicle?.sellerReviews?.length} reviews)
                </span>
                <MyTooltip text={reviewMsg} placement="auto">
                  <TiInfoLarge className="infoIcon mainDarkColor" />
                </MyTooltip>
              </p>
              <div className="d-flex align-items-end justify-content-between border-top pt-1">
                <div>
                  {/* {vehicle?.user?.userType === "dealer" && (
                    <p className="">{vehicle?.user?.name}</p>
                  )} */}

                  <p className="darkColor mb-0 fw-bold">
                    <LocationIcon />
                    {vehicle.city?.name}, {vehicle.country?.name}
                    <img src={vehicle?.country?.flag} className="mx-1" style={{ width: 18 }} />
                  </p>
                  <p className="darkColor mb-2">
                    <FaRegClock className="me-1" />
                    {vehicle.status === "draft" ? "Drafted" : "Posted"} on{" "}
                    {moment(vehicle.createdAt).format("DD MMM. YYYY")}
                  </p>
                </div>
                {vehicle?.user?.userType === "dealer" && (
                  <div>
                    <img src={vehicle?.user?.dealerLogo} className="dealerLogo mb-1" />
                  </div>
                )}
              </div>
              {myVehicle && (
                <div className="d-flex gap-10 ">
                  {vehicle.status !== "deleted" && (
                    <Button
                      variant="danger"
                      onClick={(e) => {
                        e.stopPropagation();
                        setUserAction({ action: "deletePost", id: vehicle._id });
                      }}
                    >
                      Delete
                    </Button>
                  )}
                  <Button
                    className="mainDarkColor"
                    onClick={(e) => {
                      e.stopPropagation();
                      navigate(`/postAdvert?id=${vehicle._id}`);
                    }}
                  >
                    Edit
                  </Button>
                </div>
              )}
            </div>
          </div>
        </div>

        {wishlist && (
          <button
            className="removeBtn border rounded-pill bg-white"
            onClick={handleRemoveWishlistItem}
          >
            Remove
          </button>
        )}
        {myVehicle && (
          <button
            className={`removeBtn border rounded-pill bg-white ${
              vehicle.status === "approved"
                ? "successMsg"
                : vehicle.status === "rejected" || vehicle.status === "deleted"
                ? "rejectMsg"
                : vehicle.status === "pending" || vehicle.status === "draft"
                ? "warningMsg"
                : ""
            }`}
          >
            {vehicle.status === "pending" ? "Under Review" : parseCamelKey(vehicle.status)}
          </button>
        )}
      </div>

      {userAction?.action === "deletePost" && (
        <DeletePopup
          userAction={userAction}
          setUserAction={setUserAction}
          onDelete={handleDeletePost}
        />
      )}

      <ReviewDrawer userAction={userAction} setUserAction={setUserAction} />
    </>
  );
}
