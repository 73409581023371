import React, { memo, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { getFeaturedList, getRecentList } from "../../../redux/vehicles/thunk";
import { handleApiRequest } from "../../../services/handleApiRequest";
import ListCrousel from "./listCrousel";
import { parseCamelKey } from "../../../utils/parseKey";
import HorizontalAds from "../../../googleAds/horizontalAds";
import MyPagination from "../../../components/pagination";

function CarsList() {
  const [params] = useSearchParams();
  const type = params.get("type") || "sell";
  let userCategory = params.get("category");
  userCategory = userCategory ? userCategory : type === "rent" && !userCategory ? "" : "cars";

  const { filters } = useSelector((state) => state.filters);
  const featuredList = useSelector((state) => state.vehicles.featuredList);
  const recentList = useSelector((state) => state.vehicles.recentList);

  const [paginationDetails, setPaginationDetails] = useState({
    page: 1,
    limit: 180,
    sortBy: "updatedAt",
    order: -1,
  });

  const handleFeaturedList = async () => {
    const request = {
      filters: {
        ...filters,
        isFeatured: true,
        status: "approved",
        type: userCategory,
        sellOrRent: type,
      },
      paginationDetails: { page: 1, limit: 120, sortBy: "updatedAt", order: -1 },
    };

    for (let key of Object.keys(filters || {})) {
      if (
        typeof filters?.[key] === "object" &&
        "value" in filters?.[key] &&
        "label" in filters?.[key]
      ) {
        request.filters[key] = filters[key].value;
      }
    }

    if (userCategory === "motorhomes") {
      request.filters.minYear = filters.minYear?.value;
      request.filters.birth = filters.birth?.value;
    } else if (userCategory === "caravans") {
      request.filters.birth = filters.birth?.value;
    }

    await handleApiRequest(getFeaturedList, request);
  };

  const handleRecentList = async () => {
    const request = {
      filters: {
        ...filters,
        status: "approved",
        isFeatured: false,
        type: userCategory,
        sellOrRent: type,
      },
      paginationDetails: paginationDetails,
    };

    for (let key of Object.keys(filters || {})) {
      if (
        typeof filters?.[key] === "object" &&
        "value" in filters?.[key] &&
        "label" in filters?.[key]
      ) {
        request.filters[key] = filters[key].value;
      }
    }

    if (userCategory === "motorhomes") {
      request.filters.minYear = filters.minYear?.value;
      request.filters.birth = filters.birth?.value;
    } else if (userCategory === "caravans") {
      request.filters.birth = filters.birth?.value;
    }

    await handleApiRequest(getRecentList, request);
  };

  useEffect(() => {
    if (userCategory || type) {
      handleFeaturedList();
    }
  }, [userCategory, filters]);

  useEffect(() => {
    if (userCategory || type) {
      handleRecentList();
    }
  }, [userCategory, paginationDetails, filters]);

  return (
    <>
      {/* <h4 className="my-2 text-center text-danger">Featured Cars</h4> */}
      <ListCrousel dataList={featuredList.data?.items || []} rowsCount={6} rowSize={20} />

      <div className="my-3">
        <HorizontalAds />
      </div>

      <h5 className="drakColor my-2 text-center">
        {type === "rent"
          ? `Recently Posted ${parseCamelKey(userCategory)} rentals`
          : `Recently Posted ${parseCamelKey(userCategory)}`}
      </h5>
      {recentList.data ? (
        recentList.data?.items?.length > 0 ? (
          <>
            <ListCrousel dataList={recentList.data?.items || []} rowsCount={9} rowSize={20} />
            <MyPagination
              paginationDetails={paginationDetails}
              setPaginationDetails={setPaginationDetails}
              totalPosts={recentList?.data?.totalCount}
            />
          </>
        ) : (
          <h1 className="text-center my-5">No data found</h1>
        )
      ) : (
        <h1 className="text-center my-5">Loading...</h1>
      )}
    </>
  );
}

export default memo(CarsList);
