import React, { useCallback } from "react";
import { Col, Row } from "react-bootstrap";
import Slider from "react-slick";
import PostCard from "../../../components/postcard";
import VerticalFixAd from "../../../googleAds/verticleFixAd";

const adList = ["4852949692", "7516640046", "3789982175", "7721272729", "5095109382", "7673704096"];

export default function ListCrousel({ dataList = [], rowsCount, rowSize, className = "" }) {
  var settings = {
    className: "slider variable-width",
    infinite: true,
    speed: 700,
    autoplaySpeed: 8000,
    autoplay: true,
    cssEase: "linear",
    slidesToShow: 6,
    slidesToScroll: 6,
    responsive: [
      {
        breakpoint: 3000,
        settings: {
          slidesToShow: rowsCount > 1 ? 5 : 6,
          slidesToScroll: 5,
          infinite: true,
        },
      },
      {
        breakpoint: 2400,
        settings: {
          slidesToShow: rowsCount > 1 ? 4 : 5,
          slidesToScroll: 4,
          infinite: true,
        },
      },
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: rowsCount > 1 ? 3 : 4,
          slidesToScroll: 3,
          infinite: true,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const splitList = useCallback(
    (list = [], numberOfRows, rowSize) => {
      const myList = [...list];
      const newList = [];

      for (let i = 1; i <= Math.ceil(numberOfRows); i++) {
        const limitCount = i * rowSize;
        if (list.length <= limitCount && list.length > 0) {
          for (let ind = 1; ind <= i; ind++) {
            newList.push(myList.splice(0, list.length / i));
          }
          return newList;
        }
      }
      return newList;
    },
    [dataList]
  );

  const sliderItemsCountFix = (settings, itemsCount) => {
    if (!settings.responsive) return settings;
    const responsiveArray = settings.responsive.map((responsiveItem) => {
      const responsive = responsiveItem.settings !== "unslick" && responsiveItem.settings;
      if (!responsive) return;
      const slidesToShow = responsive.slidesToShow || 1;
      const isItemsMoreThanSlidesToShow = itemsCount > slidesToShow;

      return isItemsMoreThanSlidesToShow
        ? responsiveItem
        : {
            ...responsiveItem,
            settings: { ...responsiveItem.settings, slidesToShow: itemsCount },
          };
    });

    return { ...settings, responsive: responsiveArray };
  };

  return (
    <>
      {splitList(dataList, rowsCount / 2, rowSize * 2).map((rows, i) => {
        if (
          (rows.length === rowSize && i === 0) ||
          rows.length > rowSize ||
          rows.length < rowSize
        ) {
          return (
            <Row
              key={i + "parent"}
              className={`align-items-center ${className ? className : "justify-content-between"}`}
            >
              <div className={`${rowsCount > 1 ? "homePostRow" : "homePostRowSingle w-100"}`}>
                {splitList(
                  rows.length === rowSize ? dataList.slice(0, -1) : rows,
                  rowsCount / 2,
                  rowSize
                ).map((row, ind) => (
                  <Row className="m-0" key={ind + "child"}>
                    <Col xs={12} className="p-0">
                      <Slider {...sliderItemsCountFix(settings, row.length)}>
                        {row.map((post, i) => (
                          <PostCard key={i} post={post} />
                        ))}
                      </Slider>
                    </Col>
                  </Row>
                ))}
              </div>
              {rowsCount > 1 && (
                <div
                  className={`me-3 d-none d-xl-flex ms-0 align-items-center my-2`}
                  style={{ width: 170, height: 320 }}
                >
                  <VerticalFixAd
                    dataAdSlot={adList[i] || adList[0]}
                    style={{ width: "160px", height: "300px" }}
                  />
                </div>
              )}
            </Row>
          );
        }
      })}
    </>
  );
}
