import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { handleApiRequest } from "../../../services/handleApiRequest";
import { isArray } from "../../../utils/dataTypes";
import { getReviews } from "../../../redux/vehicles/thunk";
import { Form, Nav, Row, Tab } from "react-bootstrap";
import { MdOutlineArrowBackIosNew, MdOutlineArrowForwardIos } from "react-icons/md";
import ReviewCard from "./reviewCard";
import MyPagination from "../../../components/pagination";
import HorizontalAds from "../../../googleAds/horizontalAds";

export default function Reviews() {
  const { userProfile } = useSelector((state) => state.profile);
  const [paginationDetails, setPaginationDetails] = useState({
    page: 1,
    limit: 25,
    sortBy: "createdAt",
    order: -1,
  });
  const [activeTab, setActiveTab] = useState({});
  const [reviews, setReviews] = useState({});

  const totalPage = Math.ceil(reviews?.totalCount / paginationDetails.limit) || 0;

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  const handlePage = (btn) => {
    if (btn === "back" && paginationDetails.page > 1) {
      setPaginationDetails((prev) => ({ ...prev, page: prev.page - 1 }));
    } else if (btn === "next" && paginationDetails.page < totalPage) {
      setPaginationDetails((prev) => ({ ...prev, page: prev.page + 1 }));
    }
  };

  const handleReviewList = async () => {
    const request = { ...paginationDetails };
    if (activeTab === "given") {
      request.user = userProfile.data?._id;
    } else {
      request.seller = userProfile.data?._id;
    }

    const response = await handleApiRequest(getReviews, request);
    if (response?.status) {
      setReviews(response.data || {});
    }
  };

  useEffect(() => {
    if (userProfile.data?._id) {
      handleReviewList();
    }
  }, [paginationDetails, userProfile, activeTab]);

  return (
    <>
      <div className="my-2">
        <HorizontalAds dataAdSlot="6128139398" />
      </div>
      <h6 className="fw-bold">Reviews ({reviews?.totalCount || 0})</h6>
      {activeTab === "received" && (
        <p className="darkColor">
          My Review Score:{" "}
          <span className="mainDarkColor py-1 px-2 rounded">{reviews.rating || 0.0}</span>
        </p>
      )}
      <div className="d-flex justify-content-between align-items-center my-2">
        <div>
          <button
            className={`paginationBtn ${paginationDetails.page === 1 ? "disabled" : ""}`}
            onClick={() => handlePage("back")}
          >
            <MdOutlineArrowBackIosNew />
          </button>
          <button
            className={`paginationBtn ${paginationDetails.page === totalPage ? "disabled" : ""}`}
            onClick={() => handlePage("next")}
          >
            <MdOutlineArrowForwardIos />
          </button>
          <span>
            Page {paginationDetails.page} of {totalPage}
          </span>
        </div>
        <div className="d-flex gap-10">
          <Form.Select
            className="myVehiclesFilter"
            name="limit"
            onChange={(e) => setPaginationDetails((prev) => ({ ...prev, limit: e.target.value }))}
          >
            <option value={25}>25</option>
            <option value={50}>50</option>
            <option value={75}>75</option>
            <option value={100}>100</option>
          </Form.Select>
          <Form.Select
            style={{ width: "fit-content" }}
            name="order"
            onChange={(e) => setPaginationDetails((prev) => ({ ...prev, order: e.target.value }))}
          >
            <option value="">Default</option>
            <option value={-1}>Newest first</option>
            <option value={1}>Oldest first</option>
          </Form.Select>
        </div>
      </div>

      <Tab.Container id="left-tabs-example" defaultActiveKey="given">
        <Nav variant="pills">
          <Nav.Item>
            <Nav.Link
              className="bg-danger text-white me-1"
              eventKey="given"
              onClick={() => handleTabChange("given")}
            >
              Given
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link
              className="mainDarkColor"
              eventKey="received"
              onClick={() => handleTabChange("received")}
            >
              Recieved
            </Nav.Link>
          </Nav.Item>
        </Nav>
        <Tab.Content className="pt-0">
          <Tab.Pane eventKey="given">
            <Row>
              {reviews.totalCount > 0 ? (
                isArray(reviews?.items).map((review, i) => (
                  <ReviewCard key={review._id} type="given" review={review || {}} />
                ))
              ) : (
                <h4 className="text-center my-5">No item found</h4>
              )}
            </Row>
            <MyPagination
              paginationDetails={paginationDetails}
              setPaginationDetails={setPaginationDetails}
              totalPosts={reviews?.totalCount || 0}
            />
          </Tab.Pane>

          <Tab.Pane eventKey="received">
            <Row>
              {reviews?.totalCount > 0 ? (
                isArray(reviews?.items).map((review, i) => (
                  <ReviewCard key={review._id} type="recieved" review={review || {}} />
                ))
              ) : (
                <h4 className="text-center my-5">No item found</h4>
              )}
            </Row>
            <MyPagination
              paginationDetails={paginationDetails}
              setPaginationDetails={setPaginationDetails}
              totalPosts={reviews?.totalCount}
            />
          </Tab.Pane>
        </Tab.Content>
      </Tab.Container>
    </>
  );
}
