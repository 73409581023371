import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { Button, Col, Row } from "react-bootstrap";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import {
  ClassicEditor,
  Alignment,
  Autosave,
  BlockQuote,
  Bold,
  Essentials,
  FontBackgroundColor,
  FontColor,
  FontFamily,
  FontSize,
  Heading,
  Italic,
  List,
  Paragraph,
  SelectAll,
  Table,
  TableToolbar,
} from "ckeditor5";
import { MdOutlineArrowBackIosNew } from "react-icons/md";
import HeroAdd from "../../components/heroSection/heroAdd";
import SelectBox from "../../components/selectBox";
import Asterik from "../../components/common/asterik";
import { categories, preventMinus } from "../../utils";
import { handleApiRequest } from "../../services/handleApiRequest";
import { getAllCity } from "../../redux/countryAndCity/thunk";
import { getAllMake, getAllModel } from "../../redux/makeAndModel/thunk";
import { addVehicle, getVehicleDetails, updateVehicle } from "../../redux/vehicles/thunk";
import { errorMsg } from "../../utils/toastMsg";
import { uploadFile } from "../../redux/common/thunk";
import parseKey, { parseCamelKey } from "../../utils/parseKey";
import { trucksPostFeatures } from "../../utils/filters/trucks";
import { farmsPostFeatures } from "../../utils/filters/farms";
import { plantsPostFeatures } from "../../utils/filters/plants";
import { partsSubCategoryOptions } from "../../utils/filters/partsAndAccessories/options";
import { farmsSubCategoryOptions } from "../../utils/filters/farms/options";
import { plantsSubCategoryOptions } from "../../utils/filters/plants/options";
import { isArray } from "../../utils/dataTypes";
import { getPostFeatures } from "../../utils/getFeatures";
import { rentalsPostFeatures } from "../../utils/filters/rental";

export default function SellVehicle() {
  const { state } = useLocation();
  const [searchParams] = useSearchParams();
  const id = searchParams.get("id");
  const { vehicleDetails } = useSelector((state) => state.vehicles);

  const [postUploadStep, setPostUploadStep] = useState(state || 1);
  const [postDetails, setPostDetails] = useState({ media: [] });
  const [localImages, setLocalImages] = useState([...postDetails.media]);
  const [featuresList, setFeaturesList] = useState([]);

  const handleVehicleDetails = async () => {
    await handleApiRequest(getVehicleDetails, id);
  };

  useEffect(() => {
    if (id) {
      handleVehicleDetails();
    }
  }, [id]);

  useEffect(() => {
    if (id && vehicleDetails.data) {
      const postFeatures = getPostFeatures(
        vehicleDetails.data.type,
        vehicleDetails.data.rentalType
      );

      const myPostDetails = {};
      const myFeatureList = [
        ...postFeatures,
        { value: "price" },
        { value: "mileage" },
        { value: "title" },
        { value: "type" },
      ];

      myFeatureList?.map((feature) => {
        if (
          typeof vehicleDetails.data[feature.value] === "string" ||
          typeof vehicleDetails.data[feature.value] === "number"
        ) {
          myPostDetails[feature.value] = {
            value: vehicleDetails.data[feature.value],
            label:
              typeof vehicleDetails.data[feature.value] === "number"
                ? vehicleDetails.data[feature.value]
                : parseKey(vehicleDetails.data[feature.value]),
          };
        }
      });
      setPostDetails({ ...vehicleDetails.data, ...myPostDetails });
    }
  }, [vehicleDetails]);

  useEffect(() => {
    if (postDetails.media) {
      setLocalImages(postDetails.media);
    }
  }, [postDetails.media]);

  console.log("featuresList", featuresList);

  return (
    <>
      <section>
        <HeroAdd />

        <Row className="sellVehicleFeatureContainer m-2 justify-content-center">
          <Row
            className="my-3 d-flex justify-content-center align-items-center"
            // style={{ maxWidth: 800 }}
          >
            {postUploadStep === 2 && (
              <h5 className="primaryColor pointer m-0" onClick={() => setPostUploadStep(1)}>
                <MdOutlineArrowBackIosNew className="me-1" />
                Back
              </h5>
            )}
            <div className="darkColor m-0 my-2 text-center">
              {/* Ad details */}
              <p className="m-0" style={{ fontSize: 16 }}>
                Please complete the sections below
              </p>
              <p className="small m-0">
                <b>Note:</b> all sections must be completed
              </p>
              {/* {postDetails.type?.label ? postDetails.type?.label?.slice(0, -1) : "Car"} details */}
            </div>
          </Row>
          {postUploadStep === 1 ? (
            <PostStepOne
              postDetails={postDetails}
              setPostDetails={setPostDetails}
              setPostUploadStep={setPostUploadStep}
              localImages={localImages}
              setLocalImages={setLocalImages}
              setFeaturesList={setFeaturesList}
            />
          ) : (
            <PostStepTwo
              postDetails={postDetails}
              setPostDetails={setPostDetails}
              featuresList={featuresList}
              setFeaturesList={setFeaturesList}
            />
          )}
        </Row>
      </section>
    </>
  );
}

export const PostStepOne = ({
  postDetails,
  setPostDetails,
  setPostUploadStep,
  localImages,
  setLocalImages,
  setFeaturesList,
}) => {
  const { userProfile } = useSelector((state) => state.profile);
  const { allCountries, allCities } = useSelector((state) => state.countryAndCity);

  const supportedFileTypes = ["jpg", "jpeg", "png"];

  const handleChange = (name, value) => {
    setPostDetails((prev) => {
      if (name === "type" || name === "rentalType") {
        return {
          ...prev,
          [name]: value,
          make: "",
          model: "",
          category: "",
          subCategory: "",
        };
      } else {
        return {
          ...prev,
          [name]: value,
        };
      }
    });
  };

  const getMediaLocalUrl = async (e) => {
    const files = e.target.files;
    const imageUrls = [];
    for (let file of files) {
      if (!supportedFileTypes.includes(file.type?.split("/")?.[1]))
        return errorMsg("Invalid file type");

      if (file.size > 5 * 1000 * 1000) return errorMsg("File size cannot be more than 5MB");
      const imageUrl = URL.createObjectURL(file);
      imageUrls.push({ url: imageUrl, type: "image", file: file });
    }

    const formData = new FormData();
    const allLocalImages = [];
    const allLiveImages = [];
    [...localImages, ...imageUrls].map((image) => {
      if (image.type === "image") {
        allLocalImages.push(image);
      } else {
        allLiveImages.push(image);
      }
    });

    allLocalImages.forEach((image) => {
      formData.append("images", image.file);
    });

    if (
      (postDetails.type?.value === "partAndAccessories" ||
        postDetails.type === "partAndAccessories") &&
      [...localImages, ...imageUrls].length > 15
    )
      return errorMsg("Sorry, uploads cannot exceed 15 images");
    else if ([...localImages, ...imageUrls].length > 20)
      return errorMsg("Sorry, uploads cannot exceed 20 images");

    setLocalImages((prev) => [...prev, ...imageUrls]);
    e.target.value = "";

    let myPosts = [...localImages, ...imageUrls];
    if (allLocalImages.length > 0 && allLocalImages.length < 21) {
      const response = await handleApiRequest(uploadFile, formData);
      if (response?.status) {
        myPosts = [...allLiveImages, ...response.data];
      }
    }

    setPostDetails((prev) => {
      if (!postDetails.video) {
        return {
          ...prev,
          media: [...myPosts],
        };
      } else {
        return {
          ...prev,
          media: [...myPosts, { url: postDetails.video, type: "video/mp4" }],
        };
      }
    });
  };

  const handleRemovelocalImage = (i) => {
    const oldImages = [...localImages];
    oldImages.splice(i, 1);
    setLocalImages([...oldImages]);
  };

  const handleCityList = async () => {
    await handleApiRequest(getAllCity, postDetails.country?._id);
  };

  const handleProccedToNextStep = async () => {
    setPostUploadStep(2);
  };

  const handleSelectMainImage = (i) => {
    const oldImages = [...localImages];
    const oldImage = oldImages[i];
    oldImages.splice(i, 1);
    oldImages.unshift(oldImage);
    setLocalImages([...oldImages]);
  };

  useEffect(() => {
    if (postDetails.country) handleCityList();
  }, [postDetails.country]);

  useEffect(() => {
    if (userProfile.data && allCountries.data) {
      const currency = allCountries.data?.items?.find(
        (country) => country._id === userProfile.data.country?._id
      )?.currency;

      setPostDetails((prev) => ({
        ...prev,
        country: userProfile.data.country,
        currency: {
          value: currency,
          label: currency,
        },
      }));
    }
  }, [userProfile, allCountries]);

  useEffect(() => {
    const postFeatures = getPostFeatures(
      postDetails.type?.value || postDetails.type,
      postDetails.rentalType?.value || postDetails.rentalType
    );

    setFeaturesList(postFeatures);
  }, [postDetails.type, postDetails.rentalType]);

  let proceedToNextStep = false;
  if (
    postDetails.country &&
    postDetails.city &&
    postDetails.type &&
    (((postDetails.type?.value === "partAndAccessories" ||
      postDetails.type === "partAndAccessories") &&
      localImages?.length >= 2 &&
      localImages?.length <= 15) ||
      (postDetails.type?.value !== "partAndAccessories" &&
        postDetails.type !== "partAndAccessories" &&
        localImages?.length >= 5 &&
        localImages?.length <= 20)) &&
    (((postDetails.type?.value === "rentals" || postDetails.type === "rentals") &&
      postDetails?.rentalType) ||
      (postDetails.type?.value !== "rentals" && postDetails.type !== "rentals"))
  ) {
    proceedToNextStep = true;
  } else {
    proceedToNextStep = false;
  }

  return (
    <>
      <Col md={6} className="sellFeatureBoxWrapper">
        <SelectBox
          className="my-3"
          placeholder={
            <span>
              Category<span className="text-danger">*</span>
            </span>
          }
          options={categories.map((myCategory) =>
            myCategory.label.endsWith("s") && myCategory.value !== "rentals"
              ? { value: myCategory.value, label: myCategory.label.slice(0, -1) }
              : myCategory
          )}
          value={postDetails.type}
          onChange={(selected) => handleChange("type", selected)}
        />

        {postDetails.type?.value === "rentals" && (
          <SelectBox
            className="my-3"
            placeholder={
              <span>
                Rental Category<span className="text-danger">*</span>
              </span>
            }
            options={categories.filter(
              (elem) => elem.value !== "rentals" && elem.value !== "partAndAccessories"
            )}
            value={postDetails.rentalType}
            onChange={(selected) => handleChange("rentalType", selected)}
          />
        )}

        <SelectBox
          isDisabled
          className="sellFeatureBox my-3"
          placeholder={
            <span>
              Country<span className="text-danger">*</span>
            </span>
          }
          options={allCountries.data?.items}
          value={userProfile.data?.country}
          // value={postDetails.country}
          getOptionLabel={(option) => option.name}
          getOptionValue={(option) => option._id}
          onChange={(selected) =>
            setPostDetails((prev) => ({
              ...prev,
              country: selected,
              currency: postDetails.currency?.value
                ? postDetails.currency
                : {
                    value: selected.currency,
                    label: selected.currency,
                  },
              city: "",
            }))
          }
        />
        <SelectBox
          className="sellFeatureBox my-3"
          placeholder={
            <span>
              City<span className="text-danger">*</span>
            </span>
          }
          options={
            allCities.data?.items ? [...allCities.data?.items, { _id: "other", name: "Other" }] : []
          }
          value={postDetails.city || ""}
          getOptionLabel={(option) => option.name}
          getOptionValue={(option) => option._id}
          onChange={(selected) => handleChange("city", selected)}
        />

        {postDetails.city?._id === "other" && (
          <input
            type="text"
            className="form-control my-3"
            placeholder="Enter city name"
            value={postDetails.city?._id === "other" ? postDetails.newCity : ""}
            onChange={(e) => handleChange("newCity", e.target.value)}
          />
        )}

        <label>
          Images<span className="text-danger">*</span>
        </label>
        {postDetails.type === "partAndAccessories" ||
        postDetails.type?.value === "partAndAccessories" ? (
          <p className="small text-muted">Add at least 2 and maximum 15 images ( Max 5MB )</p>
        ) : (
          <p className="small text-muted">Add at least 5 and maximum 20 images ( Max 5MB )</p>
        )}
        <div className="postImageWrapper d-flex align-items-center">
          <label htmlFor="selectpostImage" className="imageploadBtn">
            +
          </label>
          <input
            id="selectpostImage"
            type="file"
            multiple
            className="d-none"
            onChange={(e) => {
              getMediaLocalUrl(e);
            }}
          />
          {localImages.map((image, i) => (
            <div key={image.url} className="position-relative">
              <img
                src={image.url}
                className="postImagePreview pointer"
                onClick={() => handleSelectMainImage(i)}
              />
              <p
                className="imageRemoveIcon"
                onClick={() => {
                  handleRemovelocalImage(i);
                }}
              >
                +
              </p>
              {i === 0 && <p className="mainImageLabel">Main Image</p>}
            </div>
          ))}
        </div>

        <p className="small m-0"> (Support format: *.pnj and *.jpg)</p>
        {localImages.length > 0 && (
          <p className="small">Click on an image to select as main image</p>
        )}

        <input
          type="text"
          className="form-control my-3"
          placeholder="Link for video"
          value={postDetails.video}
          onChange={(e) => handleChange("video", e.target.value)}
        />
        <Button
          variant="danger"
          className="my-3 w-50 mx-auto d-flex justify-content-center"
          disabled={!proceedToNextStep}
          onClick={() => handleProccedToNextStep()}
        >
          Next
        </Button>
      </Col>
    </>
  );
};

export const PostStepTwo = ({ postDetails, setPostDetails, featuresList, setFeaturesList }) => {
  const navigate = useNavigate();
  const { allMakes, allModels } = useSelector((state) => state.makeAndModel);
  const [errors, setErrors] = useState({});

  const handleChange = (name, value) => {
    if (name === "make")
      setPostDetails((prev) => ({ ...prev, [name]: value, model: "", variant: "" }));
    else if (name === "model") setPostDetails((prev) => ({ ...prev, [name]: value, variant: "" }));
    else if (name === "priceType")
      setPostDetails((prev) => ({ ...prev, [name]: value, price: "" }));
    else if (name === "category")
      setPostDetails((prev) => ({ ...prev, [name]: value, subCategory: "" }));
    else setPostDetails((prev) => ({ ...prev, [name]: value }));
  };

  const handleCreatePost = async (status) => {
    const request = {
      ...postDetails,
      status: status || "pending",
      isFeatured: false,
    };

    const allKeys = featuresList.map((elem) => elem.value);
    allKeys.push("price", "description", "currency", "title", "type");

    if (
      ["cars", "vans", "bikes", "motorhomes", "trucks"].includes(
        postDetails.type?.value || postDetails.type
      ) &&
      !["trailer", "others"].includes(postDetails.category?.value, postDetails.category)
    ) {
      allKeys.push("mileage");
    }

    let myErrors = {};

    for (let key of allKeys) {
      if (!postDetails[key] && request.status !== "draft") {
        myErrors = { ...myErrors, [key]: `*${parseCamelKey(key)} is required` };
      }
      if (
        typeof postDetails[key] === "object" &&
        (postDetails[key]?.value || postDetails[key]?._id)
      ) {
        request[key] = postDetails[key]?.value || postDetails[key]?._id;
      }
    }

    if (Object.values(myErrors || {}).filter((item) => item).length > 0) {
      return setErrors(myErrors);
    } else {
      setErrors({});
    }

    if (request.newCity && request.city === "other") {
      request.city = request.newCity;
    }
    if (request.type === "rentals") {
      request.type = request.rentalType?.value;
      request.sellOrRent = "rent";
    }

    for (let field of Object.entries(request || {})) {
      const key = field[0];
      const value = field[1];

      const extraKeys = ["media", "video", "status", "isFeatured"];

      if (!value || (!allKeys.includes(key) && !extraKeys.includes(key))) {
        delete request[key];
      }
    }

    if (request.mileage?.value) {
      request.mileage = request.mileage?.value;
    }

    let response = {};
    if (request._id) {
      response = await handleApiRequest(updateVehicle, request);
    } else {
      response = await handleApiRequest(addVehicle, request);
    }

    if (response?.status) {
      setPostDetails({ media: [] });
      if (status === "draft") {
        navigate("/my-items");
      } else {
        navigate("/successMsg");
      }
    }
  };

  const handleMakeList = async () => {
    let type = "";
    if (postDetails.type?.value === "rentals" || postDetails.type === "rentals") {
      type = postDetails.rentalType?.value;
    } else {
      type = postDetails.type?.value || postDetails.type;
    }
    handleApiRequest(getAllMake, {
      type: type === "partAndAccessories" ? "cars" : type,
    });
  };

  const handleModelList = async () => {
    let type = "";
    if (postDetails.type?.value === "rentals" || postDetails.type === "rentals") {
      type = postDetails.rentalType?.value;
    } else {
      type = postDetails.type?.value || postDetails.type;
    }
    handleApiRequest(getAllModel, {
      makeId: postDetails.make?.value || postDetails.make?._id,
      type: type === "partAndAccessories" ? "cars" : type,
    });
  };

  useEffect(() => {
    handleMakeList();
  }, []);

  useEffect(() => {
    if (allMakes.data && postDetails.make) handleModelList();
    // if (allModels.data && postDetails.model) handleVariantList();
  }, [postDetails.make, allMakes]);

  useEffect(() => {
    const oldFeatures = [...featuresList];
    const makeIndex = oldFeatures.findIndex((elem) => elem.label === "Make");
    const modelIndex = oldFeatures.findIndex((elem) => elem.label === "Model");

    if (allMakes.data) {
      oldFeatures[makeIndex].options = [
        {
          label: "most searched for",
          options: isArray(allMakes.data?.popularMakes).map((make) => ({
            ...make,
            group: "popular",
          })),
        },
        { label: "All makes", options: allMakes.data?.items },
      ];
    }

    if (postDetails.make && allModels.data) {
      oldFeatures[modelIndex].options = allModels.data.items;
    }

    setFeaturesList(oldFeatures);
  }, [allMakes, allModels]);

  useEffect(() => {
    let oldFeatures = [...featuresList];
    console.log("oldFeatures", oldFeatures);

    if (
      postDetails.type?.value === "trucks" ||
      postDetails.type === "trucks" ||
      postDetails.rentalType?.value === "trucks" ||
      postDetails.rentalType === "trucks"
    ) {
      if (["trailer", "others"].includes(postDetails.category?.value || postDetails.category)) {
        const fieldsToExclude = ["fuelType", "enginePower", "axle", "gvw", "gtw", "cabType"];

        const newFeatures = trucksPostFeatures.filter(
          (item) => !fieldsToExclude.includes(item.value)
        );
        oldFeatures = [...newFeatures];

        for (let key of Object.keys(postDetails || {})) {
          if (fieldsToExclude.includes(key)) {
            delete postDetails[key];
          }
        }
      } else if (
        ["tractorUnit", "rigidTruck"].includes(postDetails.category?.value || postDetails.category)
      ) {
        const newFeatures = trucksPostFeatures.filter((item) => item.value !== "subCategory");
        oldFeatures = [...newFeatures];

        if (postDetails.subCategory) {
          delete postDetails.subCategory;
        }
      }
    }

    if (
      postDetails.type?.value === "plants" ||
      postDetails.type === "plants" ||
      postDetails.rentalType?.value === "plants" ||
      postDetails.rentalType === "plants"
    ) {
      if (postDetails.category?.value || typeof postDetails.category === "string") {
        const subCategoryIndex = oldFeatures.findIndex((elem) => elem.value === "subCategory");
        const options = plantsSubCategoryOptions.filter(
          (item) => item.category === postDetails.category.value
        );

        oldFeatures[subCategoryIndex].options = options;

        if (
          [
            "attachments",
            "buildingsStorage",
            "toolsEquipment",
            "wasterRecycling",
            "trailers",
          ].includes(postDetails.category?.value || postDetails.category)
        ) {
          const newFeatures =
            postDetails.type?.value === "plants" || postDetails.type === "plants"
              ? plantsPostFeatures.filter((item) => item.value !== "farmsUsedHours")
              : postDetails.rentalType?.value === "plants" || postDetails.rentalType === "plants"
              ? [...rentalsPostFeatures.rentals, ...rentalsPostFeatures.plants].filter(
                  (item) => item.value !== "farmsUsedHours"
                )
              : "";
          oldFeatures = [...newFeatures];

          if (postDetails.farmsUsedHours) {
            delete postDetails.farmsUsedHours;
          }
        } else {
          oldFeatures =
            postDetails.type?.value === "plants" || postDetails.type === "plants"
              ? [...plantsPostFeatures]
              : postDetails.rentalType?.value === "plants" || postDetails.rentalType === "plants"
              ? [...rentalsPostFeatures.rentals, ...rentalsPostFeatures.plants]
              : "";
        }
      }
    }

    if (
      (postDetails.type?.value === "partAndAccessories" ||
        postDetails.type === "partAndAccessories") &&
      (postDetails.category?.value || typeof postDetails.category === "string")
    ) {
      const subCategoryIndex = oldFeatures.findIndex((elem) => elem.value === "subCategory");
      const options = partsSubCategoryOptions.filter(
        (item) => item.category === postDetails.category.value
      );

      oldFeatures[subCategoryIndex].options = options;
    }

    if (
      postDetails.type?.value === "farms" ||
      postDetails.type === "farms" ||
      postDetails.rentalType?.value === "farms" ||
      postDetails.rentalType === "farms"
    ) {
      if (postDetails.category?.value || typeof postDetails.category === "string") {
        const subCategoryIndex = oldFeatures.findIndex((elem) => elem.value === "subCategory");
        const options = farmsSubCategoryOptions.filter(
          (item) => item.category === postDetails.category.value
        );

        if (subCategoryIndex >= 0) {
          oldFeatures[subCategoryIndex].options = options;
        }

        if (
          !["harvesters", "4wdVehicles", "loadersExcavators", "tractors"].includes(
            postDetails.category?.value || postDetails.category
          )
        ) {
          const newFeatures =
            postDetails.type?.value === "farms" || postDetails.type === "farms"
              ? farmsPostFeatures.filter((item) => item.value !== "farmsUsedHours")
              : postDetails.rentalType?.value === "farms" || postDetails.rentalType === "farms"
              ? [...rentalsPostFeatures.rentals, ...rentalsPostFeatures.farms].filter(
                  (item) => item.value !== "farmsUsedHours"
                )
              : "";

          oldFeatures = [...newFeatures];

          if (postDetails.farmsUsedHours) {
            delete postDetails.farmsUsedHours;
          }
        } else {
          postDetails.type?.value === "farms" || postDetails.type === "farms"
            ? [...farmsPostFeatures]
            : postDetails.rentalType?.value === "farms" || postDetails.rentalType === "farms"
            ? [...rentalsPostFeatures.rentals, ...rentalsPostFeatures.farms]
            : "";
        }
      }
    }

    setFeaturesList(oldFeatures);
  }, [postDetails.category]);

  const MakeGroupLabel = (data) => (
    <div className="mainDarkColor text-white p-2 fw-bold">
      <span>{data.label}</span>
    </div>
  );

  return (
    <>
      <Row style={{ maxWidth: 800 }}>
        <Col xs={12}>
          <label htmlFor="" className="form-label mb-0">
            Title
            <Asterik />
          </label>
          <div className="input-group has-validation">
            <input
              type="text"
              className={`form-control ${errors.title ? "border-danger" : ""}`}
              placeholder="Enter title"
              name="title"
              maxLength={65}
              value={postDetails.title?.value || ""}
              onChange={(e) =>
                handleChange("title", { value: e.target.value, label: e.target.value })
              }
            />
          </div>
          {errors.title && <p className="m-0 text-danger">{errors.title}</p>}
        </Col>

        {featuresList.map((filter, i) => {
          return i > 1 && Array.isArray(filter.options) ? (
            (["trucks", "plants", "farms"].includes(postDetails.type?.value || postDetails.type) ||
              ["trucks", "plants", "farms"].includes(
                postDetails.rentalType?.value || postDetails.rentalType
              )) &&
            (postDetails.category?.value === "others" || postDetails.category === "others") &&
            filter.value === "subCategory" ? (
              <Col key={filter.value} md={6} className="my-2">
                <label htmlFor="" className="form-label mb-0">
                  Sub Category
                  <Asterik />
                </label>
                <div className="input-group has-validation">
                  <input
                    type="text"
                    className={`form-control ${errors.subCategory ? "border-danger" : ""}`}
                    style={{ height: 40 }}
                    placeholder="Please specify"
                    name="subCategory"
                    value={postDetails.subCategory?.value || ""}
                    onChange={(e) => {
                      const value = e.target.value;
                      handleChange("subCategory", { value: value, label: value });
                    }}
                  />
                </div>
                {errors.subCategory && <p className="m-0 text-danger">{errors.subCategory}</p>}
              </Col>
            ) : (
              <Col key={filter.value} md={6} className="my-2 ">
                <label className="">
                  <span className="">
                    {filter.label}
                    <Asterik />
                  </span>
                </label>
                <SelectBox
                  classNamePrefix={"makeSelector"}
                  styles={{
                    control: (baseStyle) => {
                      return { ...baseStyle, border: errors[filter.value] ? "1px solid red" : "" };
                    },
                  }}
                  isDisabled={
                    (filter.value === "model" && !postDetails.make) ||
                    (filter.value === "subCategory" && !postDetails.category)
                  }
                  formatGroupLabel={MakeGroupLabel}
                  options={filter.options}
                  value={postDetails[filter.value]}
                  getOptionLabel={(option) => option.label || option.name}
                  getOptionValue={(option) => option.value || option._id}
                  onChange={(selected) => {
                    const value = {
                      value: selected.value || selected._id,
                      label: selected.label || selected.name,
                    };
                    handleChange(filter.value, value);
                  }}
                />
                {errors[filter.value] && <p className="m-0 text-danger">{errors[filter.value]}</p>}
              </Col>
            )
          ) : (
            ""
          );
        })}

        {!["partAndAccessories", "caravans", "rentals", "farms", "plants"].includes(
          postDetails.type?.value || postDetails.type
        ) ? (
          (["trucks"].includes(postDetails.type?.value || postDetails.type) &&
            !["others", "trailer"].includes(postDetails.category?.value || postDetails.category)) ||
          !["trucks"].includes(postDetails.type?.value || postDetails.type) ? (
            <Col md={6} className="my-2">
              <label htmlFor="" className="form-label mb-0">
                Mileage
                <Asterik />
              </label>
              <div className="input-group has-validation">
                <input
                  type="number"
                  className={`form-control ${errors.mileage ? "border-danger" : ""}`}
                  style={{ height: 40 }}
                  placeholder="Enter Mileage"
                  name="mileage"
                  value={postDetails.mileage?.value || ""}
                  min={0}
                  onKeyDown={preventMinus}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (value.length > 6) return;
                    handleChange("mileage", { value: value, label: value });
                  }}
                />
              </div>
              {errors.mileage && <p className="m-0 text-danger">{errors.mileage}</p>}
            </Col>
          ) : (
            ""
          )
        ) : (
          ""
        )}

        {(postDetails?.priceType?.value === "contactforRate" ||
          postDetails?.priceType === "contactforRate") &&
        (postDetails.type?.value === "rentals" || postDetails.type === "rentals") ? (
          ""
        ) : (
          <Col md={6} className="my-2">
            <label htmlFor="" className="form-label mb-0">
              Price
              <Asterik />
            </label>
            <div className="input-group has-validation">
              <input
                type="number"
                className={`form-control ${errors.price ? "border-danger" : ""}`}
                style={{ height: 40 }}
                placeholder="Enter Price"
                name="price"
                value={postDetails.price?.value || ""}
                min={0}
                onKeyDown={preventMinus}
                onChange={(e) => {
                  const value = e.target.value;
                  if (value.length > 10) return;
                  handleChange("price", { value: value, label: value });
                }}
              />
            </div>
            {errors.price && <p className="m-0 text-danger">{errors.price}</p>}
          </Col>
        )}

        <Col xs={12}>
          <label htmlFor="" className="form-label mb-0 mt-2">
            Description
            <Asterik />
          </label>
          <div
            className="input-group has-validation"
            style={{ border: errors.description ? "1px solid red" : "" }}
          >
            <CKEditor
              activeClass={`p-10 w-100`}
              content={postDetails.description}
              config={{
                versionCheck: false,
              }}
              events={{
                change: (e) => {
                  setPostDetails((prev) => {
                    return {
                      ...prev,
                      description: e.editor.getData(),
                    };
                  });
                },
              }}
            />
            <CKEditor
              className="manish"
              activeClass={`p-10 w-100 manish`}
              editor={ClassicEditor}
              config={{
                placeholder: "Enter description here",
                toolbar: {
                  items: [
                    "heading",
                    "|",
                    "fontSize",
                    "fontFamily",
                    "fontColor",
                    "fontBackgroundColor",
                    "|",
                    "bold",
                    "italic",
                    "blockQuote",
                    "|",
                    "alignment",
                    "|",
                    "bulletedList",
                    "numberedList",
                    "insertTable",
                  ],
                },
                plugins: [
                  Alignment,
                  Autosave,
                  BlockQuote,
                  Bold,
                  Essentials,
                  FontBackgroundColor,
                  FontColor,
                  FontFamily,
                  FontSize,
                  Italic,
                  List,
                  Paragraph,
                  SelectAll,
                  Table,
                  TableToolbar,
                  Heading,
                ],
              }}
              data={postDetails.description || ""}
              onChange={(e, editor) => {
                setPostDetails((prev) => {
                  return {
                    ...prev,
                    description: editor.getData(),
                  };
                });
              }}
            />
          </div>
          {errors.description && <p className="m-0 text-danger">{errors.description}</p>}
        </Col>

        <Col className="my-2">
          <Button variant="danger" onClick={() => handleCreatePost("pending")}>
            Post Advert
          </Button>
          <Button
            variant=""
            className="mainDarkColor mx-2"
            onClick={() => handleCreatePost("draft")}
          >
            Save as Draft
          </Button>
        </Col>
      </Row>
    </>
  );
};
