import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Button, Col, Row } from "react-bootstrap";
import moment from "moment";
import { MdLocalOffer } from "react-icons/md";
import { MdOutlineEmail } from "react-icons/md";
import { TiInfoLarge } from "react-icons/ti";
import { FaHeart } from "react-icons/fa";
import { IoMdShare } from "react-icons/io";
import { GiCheckMark } from "react-icons/gi";
import { MdOutlineArrowBackIosNew } from "react-icons/md";
import star1 from "../../Assets/Images/star-filled.jpeg";
import star2 from "../../Assets/Images/star-half.jpeg";
import star3 from "../../Assets/Images/star-empty.jpeg";
import { ReactComponent as CompareIcon } from "../../Assets/icons/compare.svg";
import { ReactComponent as Heartcon } from "../../Assets/icons/heart.svg";
import { ReactComponent as WhatsappIcon } from "../../Assets/icons/whatsapp.svg";
import { handleApiRequest } from "../../services/handleApiRequest";
import {
  addToCompare,
  addToWishlist,
  getRelatedVehicleList,
  getVehicleDetails,
  removeCompareListItem,
  removeWishlistItem,
} from "../../redux/vehicles/thunk";
import { getUserProfile } from "../../redux/profile/thunk";
import parseKey, { parseCamelKey } from "../../utils/parseKey";
import { successMsg } from "../../utils/toastMsg";
import isUserLoggedin from "../../utils/isUserLoggedin";
import { sellerDetails } from "../../utils/filters/common";
import { carsDetailsList } from "../../utils/filters/cars";
import { vansDetailsList } from "../../utils/filters/vans";
import { bikesDetailsList } from "../../utils/filters/bikes";
import { motorhomesDetailsList } from "../../utils/filters/motorhomes";
import { caravansDetailsList } from "../../utils/filters/caravans";
import { trucksDetailsList } from "../../utils/filters/trucks";
import { partsDetailsList } from "../../utils/filters/partsAndAccessories";
import { farmsDetailsList } from "../../utils/filters/farms";
import { plantsDetailsList } from "../../utils/filters/plants";
import { rentalsDetailsList } from "../../utils/filters/rental";
import { reviewMsg } from "../../utils/constants";
import { numbersOnly } from "../../utils";
import ListCrousel from "../home/components/listCrousel";
import { MyTooltip } from "../../components/myTooltip/myTooltip";
import ReviewDrawer from "../../components/sidebar/Reviews";
import DescriptionDrawer from "../../components/sidebar/FullDescription";
import MediaCarousel from "./components/mediaCrousel";
import MakeOfferPop from "../../components/Modals/makeOfferPop";
import SharePop from "../../components/Modals/sharePop";

export default function VehicleDetails() {
  const { pathname, state } = useLocation();
  const whatsappBoxref = useRef();
  const { id } = useParams();
  const navigate = useNavigate();
  const { vehicleDetails } = useSelector((state) => state.vehicles);
  const { userProfile } = useSelector((state) => state.profile);
  const detail = vehicleDetails.data;
  const [userAction, setUserAction] = useState(null);
  const [detailsList, setDetailsList] = useState([]);
  const [relatedVehicles, setRelatedVehicles] = useState([]);

  const handleVehicleDetails = async () => {
    await handleApiRequest(getVehicleDetails, id);
  };

  const handleUserProfile = async () => {
    await handleApiRequest(getUserProfile);
  };

  const handleCompareItem = async (actionType) => {
    if (!isUserLoggedin()) {
      // return errorMsg("Please sign-in or register to compare items");
      navigate("/login");
    }

    if (actionType === "add") {
      const response = await handleApiRequest(addToCompare, { vehicle: id });

      if (userProfile.data?.compareCount >= 4) {
        navigate("/CompareList");
      }
      if (response?.status) {
        successMsg("Added to compare list");
        await handleUserProfile();
        handleVehicleDetails();
      }
    } else {
      const response = await handleApiRequest(removeCompareListItem, {
        id: detail?.compareItem?._id,
      });
      if (response?.status) {
        successMsg("Removed from compare list");
        handleUserProfile();
        handleVehicleDetails();
      }
    }
  };

  const handleWishlistItem = async (actionType) => {
    if (!isUserLoggedin()) {
      // return errorMsg("Please sign-in or register to compare items");
      navigate("/login");
    }

    if (actionType === "add") {
      const response = await handleApiRequest(addToWishlist, { id });
      if (response?.status) {
        successMsg("Added to Wishlist");
        handleVehicleDetails();
      }
    } else {
      const response = await handleApiRequest(removeWishlistItem, {
        id: detail?.wishlistItem?._id,
      });
      if (response?.status) {
        successMsg("Removed from Wishlist");
        handleVehicleDetails();
      }
    }
  };

  const handleRelatedVehicles = async () => {
    const response = await handleApiRequest(getRelatedVehicleList, { _id: detail?._id, limit: 60 });
    if (response?.status) {
      setRelatedVehicles(response);
    }
  };

  useEffect(() => {
    if (id) {
      handleVehicleDetails();
    }
  }, [id]);

  useEffect(() => {
    if (detail && id) {
      handleRelatedVehicles();
    }
  }, [detail]);

  useEffect(() => {
    if (vehicleDetails.data) {
      if (vehicleDetails.data.sellOrRent === "rent") {
        setDetailsList(rentalsDetailsList);
      } else if (vehicleDetails.data.type === "cars") {
        setDetailsList(carsDetailsList);
      } else if (vehicleDetails.data.type === "vans") {
        setDetailsList(vansDetailsList);
      } else if (vehicleDetails.data.type === "bikes") {
        setDetailsList(bikesDetailsList);
      } else if (vehicleDetails.data.type === "motorhomes") {
        setDetailsList(motorhomesDetailsList);
      } else if (vehicleDetails.data.type === "caravans") {
        setDetailsList(caravansDetailsList);
      } else if (vehicleDetails.data.type === "trucks") {
        setDetailsList(trucksDetailsList);
      } else if (vehicleDetails.data.type === "partAndAccessories") {
        setDetailsList(partsDetailsList);
      } else if (vehicleDetails.data.type === "farms") {
        setDetailsList(farmsDetailsList);
      } else if (vehicleDetails.data.type === "plants") {
        setDetailsList(plantsDetailsList);
      } else {
        setDetailsList(carsDetailsList);
      }
    }
  }, [vehicleDetails]);

  return (
    <>
      <section>
        <h6
          className="primaryColor mt-3 pointer"
          style={{ width: "fit-content" }}
          onClick={() => {
            state === "afterLogin" ? navigate("/") : navigate(-1);
          }}
        >
          <MdOutlineArrowBackIosNew className="me-1" />
          Back to results
        </h6>
        <div className="detailWrapper">
          <div className="detailPageImagesSideContainer">
            <div className="parentCrousel">
              <MediaCarousel media={detail?.media} isFeatured={detail?.isFeatured} />
            </div>
            <div className="inlineActionWrapper border-bottom my-4">
              <div className="d-flex align-items-center">
                <p
                  className="small pointer text-center m-0 p-2"
                  onClick={() => {
                    if (isUserLoggedin()) {
                      setUserAction({ type: "makeOffer", currency: detail?.currency });
                    } else {
                      navigate("/login");
                    }
                  }}
                >
                  <MdLocalOffer className="text-danger mx-1" />
                  Make an Offer
                </p>
                {detail?.compareItem ? (
                  <p
                    className="small pointer text-center m-0 p-2"
                    onClick={() => handleCompareItem("remove")}
                  >
                    <CompareIcon className="redIcon" />
                    Added to Compare
                    <GiCheckMark className="checkMark ms-1" />
                  </p>
                ) : (
                  <p
                    className="small pointer text-center m-0 p-2"
                    onClick={() => handleCompareItem("add")}
                  >
                    <CompareIcon className="redIcon" />
                    Add to Compare
                  </p>
                )}
                {detail?.wishlistItem ? (
                  <p
                    className="small pointer text-center m-0 p-2 mx-2"
                    onClick={() => handleWishlistItem("remove")}
                  >
                    <FaHeart className="text-danger" style={{ width: 20 }} />
                    Added to Wishlist
                  </p>
                ) : (
                  <p
                    className="small pointer text-center m-0 p-2 mx-2"
                    onClick={() => handleWishlistItem("add")}
                  >
                    <Heartcon className="redIcon" />
                    Add to Wishlist
                  </p>
                )}
              </div>
              <p
                className="small pointer text-center m-0 p-2"
                onClick={() => {
                  setUserAction({ type: "sharePost" });
                }}
              >
                Share
                <IoMdShare
                  className="mx-1 text-danger"
                  onClick={() => {
                    setUserAction({ type: "sharePost" });
                  }}
                />
              </p>
            </div>

            <div className="my-4">
              <h5 className="darkColor">
                {parseKey(detail?.type?.slice(0, -1)) === "PartAndAccessorie"
                  ? "Item"
                  : parseKey(detail?.type?.slice(0, -1))}{" "}
                Description
              </h5>
              <div className="border-bottom pb-3">
                <>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: detail?.description?.slice(0, 350),
                    }}
                  />
                  {detail?.description?.length > 350 && (
                    <Button
                      variant=""
                      className="readMoreBtn p-0 fw-sbold text-primary rounded-0"
                      style={{ borderBottom: "2px solid var(--bs-primary)" }}
                      onClick={() => setUserAction({ type: "showDesc", desc: detail?.description })}
                    >
                      Read more
                    </Button>
                  )}
                </>
              </div>
            </div>
          </div>
          <div className="detailsPageDetailsContainer">
            <h6 className="darkColor my-2">{detail?.title || "--"}</h6>
            <p>{[detail?.make?.label || "--", detail?.model?.label || "--"].join("  ")}</p>
            <div className="d-flex align-items-center text-danger gap-1 mb-2">
              {detail?.sellOrRent === "rent" && (
                <h6 className="m-0 fw-bold">{parseCamelKey(detail?.priceType)} </h6>
              )}
              <h6 className="m-0 fw-bold">{detail?.currency} </h6>
              <h6 className="m-0 fw-bold"> {detail?.price?.toLocaleString() || "--"}</h6>
              {/* {detail?.priceType && <small className="text-dark">({detail?.priceType})</small>} */}
            </div>
            <div className="fw-bold">
              {detail?.city?.name + ", " + detail?.country?.name}
              <img src={detail?.country?.flag} className="ms-1" style={{ width: 22 }} />
            </div>
            <p className="darkColor mb-0 mt-3">
              Posted on {moment(detail?.updatedAt || detail?.createdAt).format("DD MMM. YYYY")}
            </p>
            <div className="detailsWrapper">
              <h6 className="detailsHeading mainDarkColor mb-0 pb-1">
                <p> Key Details</p>
              </h6>
              <div className="p-3">
                {detailsList?.map(
                  (key) =>
                    detail[key.value] && (
                      <Row key={key.value} className="my-2">
                        <Col xs={5} className="darkColor small fw-bold">
                          {key.label}
                        </Col>
                        <Col xs={7} className="small primaryColor">
                          {typeof detail?.[key.value] !== "object"
                            ? parseCamelKey(detail?.[key.value]?.toString() || "--")
                            : parseCamelKey(
                                detail?.[key.value]?.name || detail?.[key.value]?.label || "--"
                              )}
                        </Col>
                      </Row>
                    )
                )}
              </div>
            </div>
            <div className="detailsWrapper sellerDetailsWrapper mt-3">
              <h6 className="detailsHeading bg-danger text-white mb-0 pb-1">
                <p>Seller Details</p>
              </h6>
              <div className="p-3">
                {isUserLoggedin() ? (
                  <>
                    {sellerDetails.map((key, i) => {
                      const myKey =
                        key.label === "Seller's Name" &&
                        detail?.user?.[sellerDetails[i - 1]?.value] === "dealer"
                          ? "Business Name"
                          : key.label;

                      return (
                        detail?.user && (
                          <Row key={key.label} className="my-2 align-items-start">
                            <Col xs={5} className="darkColor small fw-bold">
                              {myKey}
                            </Col>
                            <Col
                              xs={7}
                              className="small primaryColor"
                              style={{ wordWrap: "break-word" }}
                            >
                              {key.value === "whatsapp" ? (
                                <a
                                  href={`https://wa.me/${numbersOnly(
                                    detail?.user?.[key.value]
                                  )}?text=Hi, Is your "${detail?.title}" still available? \n${
                                    window.location?.href
                                  }`}
                                  target="_blank"
                                >
                                  <p
                                    className="whatsappSeller mainDarkColor m-0 rounded-pill small"
                                    ref={whatsappBoxref}
                                  >
                                    <WhatsappIcon className="me-1" width={20} />
                                    WhatsApp Seller
                                  </p>
                                </a>
                              ) : key.value === "email" ? (
                                <p
                                  className="whatsappSeller mainDarkColor m-0 rounded-pill small"
                                  style={{ minWidth: whatsappBoxref.current?.offsetWidth }}
                                >
                                  <a
                                    href={`mailto:${detail?.user?.[key.value]}`}
                                    target="_blank"
                                    className="text-white"
                                  >
                                    <MdOutlineEmail className="emailIcon me-1" />
                                    Email Seller
                                  </a>
                                </p>
                              ) : key.value === "mobile" ? (
                                <a
                                  href={`tel:${detail?.user?.[key.value]}`}
                                  target="_blank"
                                  className="primaryColor d-flex align-items-center"
                                >
                                  <img
                                    src={detail?.user.country?.flag}
                                    width={15}
                                    className=" me-1"
                                  />
                                  {parseKey(detail?.user?.[key.value])}
                                </a>
                              ) : key.value === "name" && detail.user?.userType === "private" ? (
                                parseKey(detail?.user?.[key.value].split(" ")[0])
                              ) : key.value === "rating" ? (
                                <>
                                  <p className="m-0">
                                    {detail?.rating == 0.0 ? (
                                      "No rating yet"
                                    ) : (
                                      <>
                                        {detail?.rating}
                                        <span className="mx-1">
                                          {Array.from({ length: 5 }).map((_, i) => {
                                            return detail?.rating > i && detail?.rating < i + 1 ? (
                                              <img key={i} src={star2} className="ratingStar" />
                                            ) : detail?.rating <= i ? (
                                              <img key={i} src={star3} className="ratingStar" />
                                            ) : (
                                              <img key={i} src={star1} className="ratingStar" />
                                            );
                                          })}
                                        </span>
                                      </>
                                    )}
                                  </p>
                                  <p className="m-0">
                                    <span
                                      className="pointer"
                                      onClick={() =>
                                        setUserAction({
                                          action: "showReviews",
                                          seller: detail?.user,
                                        })
                                      }
                                    >
                                      {`( ${detail?.reviewsCount || 0} reviews )`}
                                    </span>
                                    <MyTooltip text={reviewMsg} placement="auto">
                                      <TiInfoLarge className="infoIcon mainDarkColor" />
                                    </MyTooltip>
                                  </p>
                                </>
                              ) : (
                                parseKey(detail?.user?.[key.value])
                              )}
                            </Col>
                          </Row>
                        )
                      );
                    })}
                  </>
                ) : (
                  <div className="loginTOViewDetails d-flex justify-content-center">
                    <p
                      className="blockSellerDetails pointer m-0 border-0 rounded-pill"
                      onClick={() => navigate("/login", { state: pathname })}
                    >
                      Sign In | Register to view Contact Details
                    </p>
                  </div>
                )}
              </div>
            </div>
            <div className="detailsWrapper ActionWrapper mt-3">
              <h6 className="detailsHeading mainDarkColor mb-0 pb-1">
                <p>Interactive Options</p>
              </h6>
              <div className="p-3">
                <Row className="">
                  <Col xs={6} className="small" style={{ wordWrap: "break-word" }}>
                    <p
                      className="small pointer primaryColor m-0 p-2"
                      onClick={() => {
                        if (isUserLoggedin()) {
                          setUserAction({ type: "makeOffer", currency: detail?.currency });
                        } else {
                          navigate("/login");
                        }
                      }}
                    >
                      <MdLocalOffer className="text-danger mx-1" />
                      Make an Offer
                    </p>
                  </Col>
                  <Col xs={6} className="small" style={{ wordWrap: "break-word" }}>
                    {detail?.compareItem ? (
                      <p
                        className="small pointer primaryColor m-0 p-2"
                        onClick={() => handleCompareItem("remove")}
                      >
                        <CompareIcon className="redIcon" />
                        Added to Compare
                        <GiCheckMark className="checkMark ms-1" />
                      </p>
                    ) : (
                      <p
                        className="small pointer primaryColor m-0 p-2"
                        onClick={() => handleCompareItem("add")}
                      >
                        <CompareIcon className="redIcon" />
                        Add to Compare
                      </p>
                    )}
                  </Col>
                  <Col xs={6} className="small" style={{ wordWrap: "break-word" }}>
                    {detail?.wishlistItem ? (
                      <p
                        className="small pointer primaryColor m-0 p-2 "
                        onClick={() => handleWishlistItem("remove")}
                      >
                        <FaHeart className="text-danger" style={{ width: 20 }} />
                        Added to Wishlist
                      </p>
                    ) : (
                      <p
                        className="small pointer primaryColor m-0 p-2 "
                        onClick={() => handleWishlistItem("add")}
                      >
                        <Heartcon className="redIcon" />
                        Add to Wishlist
                      </p>
                    )}
                  </Col>
                  <Col xs={6} className="small" style={{ wordWrap: "break-word" }}>
                    <p
                      className="small pointer primaryColor m-0 p-2"
                      onClick={() => {
                        setUserAction({ type: "sharePost" });
                      }}
                    >
                      <IoMdShare
                        className="mx-1 text-danger"
                        onClick={() => {
                          setUserAction({ type: "sharePost" });
                        }}
                      />
                      Share
                    </p>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </div>
        {relatedVehicles.data?.totalCount >= 6 && (
          <>
            <h4 className="my-4 darkColor">
              Related {detail?.sellOrRent === "rent" ? "Rental" : ""} {parseCamelKey(detail?.type)}
            </h4>
            <ListCrousel
              className={"justify-content-center"}
              dataList={relatedVehicles.data?.items || []}
              rowsCount={1}
              rowSize={50}
            />
          </>
        )}
      </section>

      {userAction?.type === "makeOffer" && (
        <MakeOfferPop action={userAction} setAction={setUserAction} />
      )}
      {userAction?.type === "sharePost" && (
        <SharePop action={userAction} setAction={setUserAction} />
      )}
      <ReviewDrawer userAction={userAction} setUserAction={setUserAction} />
      <DescriptionDrawer userAction={userAction} setUserAction={setUserAction} />
    </>
  );
}
