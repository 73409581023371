export const trucksFuelTypeOptions = [
  { value: "biFuel", label: "Bi fuel" },
  { value: "diesel", label: "Diesel" },
  { value: "ethanol", label: "Ethanol" },
  { value: "electric", label: "Electric" },
  { value: "lpg", label: "LPG" },
  { value: "petrol", label: "Petrol" },
  { value: "hybrid(Electric/Gasoline)", label: "Hybrid (Electric/Gasoline)" },
  { value: "hybrid(Electric/Diesel)", label: "Hybrid (Electric/Diesel)" },
  { value: "hydrogen", label: "Hydrogen" },
  { value: "other", label: "Other" },
];

export const trucksCategoryOptions = [
  { value: "rigidTruck", label: "Rigid Truck" },
  { value: "tractorUnit", label: "Tractor Unit" },
  { value: "trailer", label: "Trailer" },
  { value: "others", label: "Others" },
];

export const trucksSubCategoryOptions = [
  { value: "drawbarTrailer", label: "Drawbar Trailer" },
  { value: "semiTrailer", label: "Semi Trailer" },
  { value: "trailer", label: "Tag Axle" },
];

export const trucksAxleOptions = [
  { value: "4x2", label: "4x2" },
  { value: "4x4", label: "4x4" },
  { value: "6x2", label: "6x2" },
  { value: "6x4", label: "6x4" },
  { value: "6x6", label: "6x6" },
  { value: "8x2", label: "8x2" },
  { value: "8x4", label: "8x4" },
  { value: "8x6", label: "8x6" },
  { value: "unlisted", label: "Unlisted" },
  { value: "fixedDoubleBed", label: "Fixed double bed" },
  { value: "fixedIslandBed", label: "Fixed island bed" },
  { value: "fixedSingleBeds", label: "Fixed single beds" },
  { value: "frenchBed", label: "French bed" },
  { value: "loungeConversion", label: "Lounge conversion" },
  { value: "overcabBedDouble", label: "Overcab bed - double" },
  { value: "popupRoofDoubleBed", label: "Pop-up roof double bed" },
  { value: "rockNRollBed", label: "Rock n Roll bed" },
  { value: "transverseBed", label: "Transverse bed" },
  { value: "unlisted", label: "Unlisted" },
];

export const trucksEnginePowerOptions = [
  { value: 50, label: "50bhp" },
  { value: 100, label: "100bhp" },
  { value: 150, label: "150bhp" },
  { value: 200, label: "200bhp" },
  { value: 250, label: "250bhp" },
  { value: 300, label: "300bhp" },
  { value: 350, label: "350bhp" },
  { value: 400, label: "400bhp" },
  { value: 450, label: "450bhp" },
  { value: 500, label: "500bhp" },
  { value: 550, label: "550bhp" },
  { value: 600, label: "600bhp" },
  { value: 650, label: "650bph" },
  { value: 700, label: "700bph" },
  { value: 950, label: "950bph" },
  { value: 1000, label: "1000bhp" },
];

export const trucksGvwOptions = [
  { value: 0, label: "0kg" },
  { value: 5000, label: "5000kg" },
  { value: 10000, label: "10000kg" },
  { value: 15000, label: "15000kg" },
  { value: 20000, label: "20000kg" },
  { value: 25000, label: "25000kg" },
  { value: 30000, label: "30000kg" },
  { value: 35000, label: "35000kg" },
  { value: 40000, label: "40000kg" },
  { value: 50000, label: "50000kg" },
];

export const trucksGtwOptions = [
  { value: 10000, label: "10000kg" },
  { value: 20000, label: "20000kg" },
  { value: 25000, label: "25000kg" },
  { value: 30000, label: "30000kg" },
  { value: 35000, label: "35000kg" },
  { value: 40000, label: "40000kg" },
];

export const trucksCabOptions = [
  { value: "crewCab", label: "Crew cab" },
  { value: "dayCab", label: "Day cab" },
  { value: "doubleCab", label: "Double cab" },
  { value: "highSleeperCab", label: "High sleeper cab" },
  { value: "lowAccessCab", label: "Low access cab" },
  { value: "shortCab", label: "Short cab" },
  { value: "sleeperCab", label: "Sleeper cab" },
  { value: "transporterCab", label: "Transporter cab" },
  { value: "unlisted", label: "Unlisted" },
];

export const trucksBodyStyleOptions = [
  { value: "beavertail", label: "Beavertail" },
  { value: "box", label: "Box" },
  { value: "bus", label: "Bus" },
  { value: "catering", label: "Catering" },
  { value: "chassisCab", label: "Chassis Cab" },
  { value: "coach", label: "Coach" },
  { value: "concreteMix", label: "Concrete Mix" },
  { value: "craneMounted", label: "Crane Mounted" },
  { value: "curtainSide", label: "Curtain Side" },
  { value: "dropside", label: "Dropside" },
  { value: "flatbed", label: "Flatbed" },
  { value: "gritterTruck", label: "Gritter Truck" },
  { value: "hookLoader", label: "Hook Loader" },
  { value: "livestock", label: "Livestock" },
  { value: "lowLoader", label: "Low Loader" },
  { value: "municipal", label: "Municipal" },
  { value: "pantechnican", label: "Pantechnican" },
  { value: "roadSweeper", label: "Road Sweeper" },
  { value: "scaffordBody", label: "Scafford Body" },
  { value: "skeletal", label: "Skeletal" },
  { value: "skipLoader", label: "Skip Loader" },
  { value: "specialistVehicle", label: "Specialist Vehicle" },
  { value: "tanker", label: "Tanker" },
  { value: "temperatureControlled", label: "Temperature Controlled" },
  { value: "tipper", label: "Tipper" },
  { value: "tipperGrab", label: "Tipper Grab" },
  { value: "tractorUnit", label: "Tractor Unit" },
  { value: "vehicleTransporter", label: "Vehicle Transporter" },
  { value: "unlisted", label: "Unlisted" },
];
