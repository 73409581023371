import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "react-bootstrap";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import Select from "react-select";
import africaFlag from "../../Assets/Images/africa-flag.png";
import { isArray } from "../../utils/dataTypes";
import { handleApiRequest } from "../../services/handleApiRequest";
import { getAllCity, getAllCountry } from "../../redux/countryAndCity/thunk";
import { getAllMake, getAllModel } from "../../redux/makeAndModel/thunk";
import { getVehicleCount } from "../../redux/vehicles/thunk";
import { resetFilters, selectFilters } from "../../redux/filters/slice";
import SelectBox from "../selectBox";
import { categories, preventMinus } from "../../utils";
import { parseCamelKey } from "../../utils/parseKey";
import { motorhomesBirthOptions } from "../../utils/filters/motorhomes/options";
import { getYearList } from "../../utils/filters/common/options";
import {
  caravansBirthOptions,
  caravansCategoryOptions,
} from "../../utils/filters/caravans/options";
import { vansBodyStyleOptions } from "../../utils/filters/vans/options";
import { farmsCategoryOptions } from "../../utils/filters/farms/options";
import { plantsCategoryOptions } from "../../utils/filters/plants/options";
import { getBanner } from "../../redux/common/thunk";
import { partsCategoryOptions } from "../../utils/filters/partsAndAccessories/options";

export default function HeroSection({ showFilterBox = true }) {
  const { pathname } = useLocation();
  const [params] = useSearchParams();
  const type = params.get("type") || "sell";
  let userCategory = params.get("category");
  userCategory = userCategory ? userCategory : type === "rent" && !userCategory ? "" : "cars";

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { filters } = useSelector((state) => state.filters);
  const { allCountries, allCities } = useSelector((state) => state.countryAndCity);
  const { allMakes, allModels } = useSelector((state) => state.makeAndModel);
  const { vehiclesCount } = useSelector((state) => state.vehicles);

  const [bannerImage, setBannerImage] = useState("");

  const handleUpdateFilters = (name, value) => {
    dispatch(selectFilters({ [name]: value }));
  };

  const handleResetFilters = () => {
    dispatch(resetFilters());
  };

  const handleBanner = async (category) => {
    const response = await handleApiRequest(getBanner, { title: category });

    if (response?.status) {
      setBannerImage(response.data?.bannerImage || "");
    }
  };

  const handleCityList = async () => {
    handleApiRequest(getAllCity, filters.country?.value);
  };

  const handleMakeList = async () => {
    handleApiRequest(getAllMake, {
      type: userCategory === "partAndAccessories" ? "cars" : userCategory,
    });
  };

  const handleModelList = async () => {
    handleApiRequest(getAllModel, {
      makeId: filters.make.value,
      type: userCategory === "partAndAccessories" ? "cars" : userCategory,
    });
  };

  const handleResultCount = async () => {
    const request = {
      ...filters,
      status: "approved",
      type: userCategory,
      sellOrRent: type,
    };

    for (let key of Object.keys(filters || {})) {
      if (
        typeof filters?.[key] === "object" &&
        "value" in filters?.[key] &&
        "label" in filters?.[key]
      ) {
        request[key] = filters[key].value;
      }
    }

    if (userCategory === "motorhomes") {
      request.minYear = filters.minYear?.value;
      request.birth = filters.birth?.value;
    } else if (userCategory === "caravans") {
      request.birth = filters.birth?.value;
    }
    handleApiRequest(getVehicleCount, { filters: request });
  };

  useEffect(() => {
    if (showFilterBox) handleResultCount();
  }, [filters]);

  useEffect(() => {
    if (filters.make?.value && showFilterBox) {
      handleModelList();
    }
  }, [filters.make]);

  useEffect(() => {
    if (filters.country?.value && showFilterBox) {
      handleCityList();
    }
  }, [filters.country]);

  useEffect(() => {
    let bannerCategory = "";

    switch (pathname) {
      case "/":
        bannerCategory = "cars";
        break;
      case "/cars":
        bannerCategory = "cars";
        break;
      case "/bikes":
        bannerCategory = "bikes";
        break;
      case "/caravans":
        bannerCategory = "caravans";
        break;
      case "/vans":
        bannerCategory = "vans";
        break;
      case "/motorhomes":
        bannerCategory = "motorhomes";
        break;
      case "/farms":
        bannerCategory = "farms";
        break;
      case "/plants":
        bannerCategory = "plants";
        break;
      case "/partAndAccessories":
        bannerCategory = "partAndAccessories";
        break;
      case "/trucks":
        bannerCategory = "trucks";
        break;
      case "/cars/rent":
        bannerCategory = "carRental";
        break;
      case "/bikes/rent":
        bannerCategory = "bikeRental";
        break;
      case "/vans/rent":
        bannerCategory = "vanRental";
        break;
      case "/caravans/rent":
        bannerCategory = "caravanRental";
        break;
      case "/motorhomes/rent":
        bannerCategory = "motorhomeRental";
        break;
      case "/trucks/rent":
        bannerCategory = "truckRental";
        break;
      case "/farms/rent":
        bannerCategory = "farmRental";
        break;
      case "/plants/rent":
        bannerCategory = "plantRental";
        break;
      default:
        bannerCategory = "rentals";
        break;
    }

    handleBanner(bannerCategory);
  }, [pathname]);

  useEffect(() => {
    if (userCategory && showFilterBox) {
      handleMakeList();
    }
  }, [userCategory]);

  const CountryFilterOptions = ({ data, isDisabled, innerProps, ...props }) => {
    return !isDisabled ? (
      <div {...innerProps} className="pointer p-2">
        <img className="countryDropFlag" src={data.flag || africaFlag} />
        <span className="ms-2">{data.name}</span>
      </div>
    ) : null;
  };

  const MakeGroupLabel = (data) => (
    <div className="mainDarkColor text-white p-2 fw-bold">
      <span>{data.label}</span>
    </div>
  );

  return (
    <div className="mx-0 mx-lg-2">
      <section
        className="heroSectionWrapper"
        style={{
          minHeight: !showFilterBox ? 250 : "",
          backgroundImage: `url(${bannerImage})`,
        }}
      >
        <div
          className="searchBoxWrapper bg-white p-3 pb-2 rounded m-3"
          style={{ display: showFilterBox ? "" : "none" }}
        >
          <h5 className="text-center">
            {type === "rent"
              ? "Find the perfect Vehicle to Rent"
              : ["cars", "motorhomes", "caravans"].includes(userCategory)
              ? `Find your dream ${parseCamelKey(userCategory?.slice(0, -1))}`
              : userCategory === "partAndAccessories"
              ? "Find the correct Parts & Accessories"
              : userCategory === "farms"
              ? "Find your perfect Farm Machinery"
              : `Find your perfect ${parseCamelKey(userCategory?.slice(0, -1))}`}
          </h5>

          <SelectBox
            isSearchable={false}
            placeholder="Select Country"
            components={{ Option: CountryFilterOptions, IndicatorSeparator: null }}
            options={[
              { _id: "", name: "Africa", flag: africaFlag },
              ...isArray(allCountries.data?.items),
            ]}
            getOptionLabel={(option) => {
              return (
                <div className="pointer p-2">
                  <img className="countryDropFlag" src={option.flag} />
                  <span className="ms-1">{option.label}</span>
                </div>
              );
            }}
            value={filters.country || ""}
            onChange={(selected) => {
              handleUpdateFilters("country", {
                value: selected._id,
                label: selected.name,
                flag: selected.flag,
              });
              handleUpdateFilters("city", "");
            }}
          />
          <div className="my-1">
            <SelectBox
              isDisabled={!filters.country?.value}
              isClearable={true}
              isSearchable={false}
              placeholder="Select City"
              components={{ IndicatorSeparator: null }}
              styles={{
                clearIndicator: (basic) => ({ ...basic, paddingInline: "0px" }),
                dropdownIndicator: (basic) => ({ ...basic, paddingLeft: "0px" }),
              }}
              options={filters.country?.value ? isArray(allCities.data?.items) : []}
              getOptionLabel={(option) => option.name || option.label}
              value={filters.city || ""}
              onChange={(selected) => {
                handleUpdateFilters(
                  "city",
                  selected
                    ? {
                        value: selected._id,
                        label: selected.name,
                      }
                    : ""
                );
              }}
            />
          </div>

          {userCategory === "" && type === "rent" && (
            <div className="my-1">
              <SelectBox
                placeholder="Select Category"
                components={{ IndicatorSeparator: null }}
                options={[...categories]}
                value={filters.type || ""}
                onChange={(selected) => {
                  handleUpdateFilters("type", selected);
                }}
              />
            </div>
          )}

          <div className="d-flex justify-content-between my-1 gap-10">
            <Select
              className="countrySelect w-100"
              classNamePrefix="makeSelector"
              placeholder="Make"
              components={{
                IndicatorSeparator: null,
              }}
              styles={{
                valueContainer: (base) => ({ ...base, paddingRight: 0 }),
              }}
              formatGroupLabel={MakeGroupLabel}
              options={[
                {
                  label: "Most Searched for",
                  options: isArray(allMakes.data?.popularMakes).map((make) => ({
                    ...make,
                    group: "popular",
                  })),
                },
                {
                  label: "All Makes",
                  options: allMakes.data?.items || [],
                },
              ]}
              getOptionValue={(option) => `${option._id}id${option.group}`}
              value={filters.make || ""}
              onChange={(selected) => {
                handleUpdateFilters("make", { value: selected._id, label: selected?.label });
              }}
            />

            <SelectBox
              // className="heroWidth"
              placeholder="Model"
              styles={{
                valueContainer: (base) => ({ ...base, paddingRight: 0 }),
              }}
              options={allModels.data?.items || []}
              value={filters.model || ""}
              onChange={(selected) => {
                handleUpdateFilters("model", { value: selected._id, label: selected.label });
              }}
            />
          </div>

          {userCategory === "vans" && (
            <SelectBox
              classNamePrefix={"makeSelector"}
              placeholder="Body type"
              options={vansBodyStyleOptions}
              value={filters.bodyStyle || ""}
              onChange={(selected) => {
                handleUpdateFilters("bodyStyle", selected);
              }}
            />
          )}

          {userCategory === "motorhomes" && (
            <div className="d-flex justify-content-between my-1 gap-10">
              <SelectBox
                // className="heroWidth"
                classNamePrefix={"makeSelector"}
                placeholder="Berth"
                options={motorhomesBirthOptions}
                value={filters.birth || ""}
                onChange={(selected) => {
                  handleUpdateFilters("birth", selected);
                }}
              />
              <SelectBox
                // className="heroWidth"
                placeholder="Min Year"
                options={getYearList()}
                value={filters.minYear || ""}
                onChange={(selected) => {
                  handleUpdateFilters("minYear", selected);
                }}
              />
            </div>
          )}

          {userCategory === "caravans" && (
            <div className="d-flex justify-content-between my-1 gap-10">
              <SelectBox
                // className="heroWidth"
                classNamePrefix={"makeSelector"}
                placeholder="Berth"
                options={caravansBirthOptions}
                value={filters.birth || ""}
                onChange={(selected) => {
                  handleUpdateFilters("birth", selected);
                }}
              />
              <SelectBox
                // className="heroWidth"
                placeholder="Category"
                options={caravansCategoryOptions}
                value={filters.category}
                onChange={(selected) => {
                  handleUpdateFilters("category", selected);
                }}
              />
            </div>
          )}

          {["farms", "partAndAccessories", "plants"].includes(userCategory) && (
            <SelectBox
              classNamePrefix={"makeSelector"}
              placeholder="Category"
              options={
                userCategory === "/farms"
                  ? farmsCategoryOptions
                  : userCategory === "/plants"
                  ? plantsCategoryOptions
                  : partsCategoryOptions
              }
              value={filters.category || ""}
              onChange={(selected) => {
                handleUpdateFilters("category", selected);
              }}
            />
          )}

          {["farms", "partAndAccessories", "plants", "trucks"].includes(userCategory) && (
            <input
              type="text"
              className="form-control my-1"
              style={{ height: 40 }}
              placeholder="Keywords"
              name="keyword"
              value={filters.keyword?.value || ""}
              onChange={(e) => {
                const value = e.target.value;
                if (value.length > 10) return;
                handleUpdateFilters("keyword", { value: value, label: value });
              }}
            />
          )}

          <div className="d-flex justify-content-between my-1 gap-10">
            <input
              type="number"
              className=" form-control"
              style={{
                height: 40,
                maxWidth: ["farms", "partAndAccessories"].includes(userCategory) ? 175 : 152,
              }}
              placeholder="Enter Min Price"
              name="minPrice"
              value={filters.minPrice?.value || ""}
              min={0}
              onKeyDown={preventMinus}
              onChange={(e) => {
                const value = e.target.value;
                if (value.length > 10) return;
                handleUpdateFilters("minPrice", { value: value, label: value });
              }}
            />
            <input
              type="number"
              className=" form-control"
              style={{
                height: 40,
                maxWidth: ["farms", "partAndAccessories"].includes(userCategory) ? 175 : 152,
              }}
              placeholder="Enter Max Price"
              name="maxPrice"
              value={filters.maxPrice?.value || ""}
              min={0}
              onKeyDown={preventMinus}
              onChange={(e) => {
                const value = e.target.value;
                if (value.length > 10) return;
                handleUpdateFilters("maxPrice", { value: value, label: value });
              }}
            />
          </div>
          <div className="text-center my-2">
            <Button
              variant="danger"
              onClick={() =>
                navigate(
                  `${
                    pathname === "/" ? "cars" : pathname
                  }/all?category=${userCategory}&type=${type}&condition=all`
                )
              }
            >
              {type === "rent"
                ? `Search ${vehiclesCount.data?.totalCount?.toLocaleString() || ""} 
                ${parseCamelKey(userCategory || "Rentals")}`
                : userCategory === "plants"
                ? `Search ${vehiclesCount.data?.totalCount?.toLocaleString() || ""} Plant Machines`
                : `Search ${vehiclesCount.data?.totalCount?.toLocaleString() || ""} 
              ${parseCamelKey(userCategory)}`}
            </Button>
          </div>
          <div className="d-flex justify-content-between">
            <u className="text-primary pointer" onClick={() => handleResetFilters()}>
              Reset Filters
            </u>

            <u
              className="text-primary pointer"
              onClick={() =>
                navigate(
                  `${
                    pathname === "/" ? "cars" : pathname
                  }/allFilters?category=${userCategory}&type=${type}`
                )
              }
            >
              More Options
            </u>
          </div>
        </div>
      </section>
    </div>
  );
}
