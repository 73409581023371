export const partsCategoryOptions = [
  { value: "4WD transfer", label: "4WD transfer" },
  { value: "ABS / TCS /ESC ", label: "ABS / TCS /ESC " },
  { value: "AD / ADAS / Telematics", label: "AD / ADAS / Telematics" },
  { value: "Air intake & exhaust systems", label: "Air intake & exhaust systems" },
  { value: "Airbag", label: "Airbag" },
  { value: "Axle", label: "Axle" },
  { value: "Battery & Related Parts", label: "Battery & Related Parts" },
  { value: "Body Parts / Frame", label: "Body Parts / Frame" },
  { value: "Brake & Pedal", label: "Brake & Pedal" },
  { value: "Bumper", label: "Bumper" },
  { value: "Camping ", label: "Camping " },
  { value: "Caravan & Motorhomes", label: "Caravan & Motorhomes" },
  { value: "Chassis Module", label: "Chassis Module" },
  { value: "Child Seat", label: "Child Seat" },
  { value: "Climate Control", label: "Climate Control" },
  { value: "Clutch", label: "Clutch" },
  { value: "Cockpit", label: "Cockpit" },
  { value: "Differential", label: "Differential" },
  { value: "Display", label: "Display" },
  { value: "Driver motor", label: "Driver motor" },
  { value: "EV Cooling System", label: "EV Cooling System" },
  { value: "Electric powertrain system parts", label: "Electric powertrain system parts" },
  { value: "Electrical / Electronic Parts - ECU", label: "Electrical / Electronic Parts - ECU" },
  { value: "Electrical Parts", label: "Electrical Parts" },
  { value: "Electronic Device", label: "Electronic Device" },
  { value: "Engine Cooling System", label: "Engine Cooling System" },
  { value: "Engine Electrical Parts", label: "Engine Electrical Parts" },
  { value: "Engine Lubrication Components", label: "Engine Lubrication Components" },
  { value: "Engine Structural Parts", label: "Engine Structural Parts" },
  { value: "Exterior Parts", label: "Exterior Parts" },
  { value: "Fuel Cell System", label: "Fuel Cell System" },
  { value: "Fuel Supply System", label: "Fuel Supply System" },
  { value: "General Parts", label: "General Parts" },
  { value: "Hiddin Switch", label: "Hiddin Switch" },
  { value: "Ignition System", label: "Ignition System" },
  { value: "Instrument Panel ", label: "Instrument Panel " },
  { value: "Interior Switch", label: "Interior Switch" },
  { value: "Interior Trim & Parts", label: "Interior Trim & Parts" },
  { value: "Lighting ", label: "Lighting " },
  { value: "Motor", label: "Motor" },
  { value: "Motorcycling", label: "Motorcycling" },
  { value: "Outside Mirror", label: "Outside Mirror" },
  { value: "Paints & Body Repair", label: "Paints & Body Repair" },
  { value: "Peripheral Engine Parts", label: "Peripheral Engine Parts" },
  {
    value: "Process type (Stamping, Plastic injection molding etc.)",
    label: "Process type (Stamping, Plastic injection molding etc.)",
  },
  { value: "Seat & Seat Belt", label: "Seat & Seat Belt" },
  { value: "Security I Entertainment", label: "Security I Entertainment" },
  { value: "Semiconductor Device", label: "Semiconductor Device" },
  { value: "Sensor", label: "Sensor" },
  { value: "Steering", label: "Steering" },
  { value: "Suspendion", label: "Suspendion" },
  { value: "Transmission & Parts", label: "Transmission & Parts" },
  { value: "Turbochargers / Superchargers", label: "Turbochargers / Superchargers" },
  { value: "Tyre & Wheel", label: "Tyre & Wheel" },
  { value: "Valvetrain", label: "Valvetrain" },
  { value: "Vehicle Care ", label: "Vehicle Care " },
  { value: "Window Glass", label: "Window Glass" },
];

export const partsSubCategoryOptions = [
  {
    value: "4WD ECU",
    label: "4WD ECU",
    category: "Electrical / Electronic Parts - ECU",
  },
  {
    value: "4WD parts",
    label: "4WD parts",
    category: "4WD transfer",
  },
  {
    value: "4WD transfer",
    label: "4WD transfer",
    category: "4WD transfer",
  },
  {
    value: "4WD transfer case",
    label: "4WD transfer case",
    category: "4WD transfer",
  },
  {
    value: "4WD transfer gear",
    label: "4WD transfer gear",
    category: "4WD transfer",
  },
  {
    value: "4WS (4 Wheel Steering)",
    label: "4WS (4 Wheel Steering)",
    category: "Steering",
  },
  {
    value: "4WS parts",
    label: "4WS parts",
    category: "Steering",
  },
  {
    value: "ABS",
    label: "ABS",
    category: "ABS / TCS /ESC ",
  },
  {
    value: "ABS ECU",
    label: "ABS ECU",
    category: "Electrical / Electronic Parts - ECU",
  },
  {
    value: "ABS actuator",
    label: "ABS actuator",
    category: "ABS / TCS /ESC ",
  },
  {
    value: "ABS motor",
    label: "ABS motor",
    category: "Motor",
  },
  {
    value: "ABS parts",
    label: "ABS parts",
    category: "ABS / TCS /ESC ",
  },
  {
    value: "ADAS ECU",
    label: "ADAS ECU",
    category: "Electrical / Electronic Parts - ECU",
  },
  {
    value: "AMT assembly (transmission)",
    label: "AMT assembly (transmission)",
    category: "Transmission & Parts",
  },
  {
    value: "AMT parts (transmission)",
    label: "AMT parts (transmission)",
    category: "Transmission & Parts",
  },
  {
    value: "ASIC",
    label: "ASIC",
    category: "Semiconductor Device",
  },
  {
    value: "AT ECU",
    label: "AT ECU",
    category: "Electrical / Electronic Parts - ECU",
  },
  {
    value: "Abrasives",
    label: "Abrasives",
    category: "Paints & Body Repair",
  },
  {
    value: "Accelerator pedal module",
    label: "Accelerator pedal module",
    category: "Brake & Pedal",
  },
  {
    value: "Accelerator pedal sensor",
    label: "Accelerator pedal sensor",
    category: "Sensor",
  },
  {
    value: "Adaptive Cruise Control (ACC)",
    label: "Adaptive Cruise Control (ACC)",
    category: "AD / ADAS / Telematics",
  },
  {
    value: "Adhesive",
    label: "Adhesive",
    category: "General Parts",
  },
  {
    value: "Adhesives",
    label: "Adhesives",
    category: "Paints & Body Repair",
  },
  {
    value: "Advanced Driver Assistance System (ADAS)",
    label: "Advanced Driver Assistance System (ADAS)",
    category: "AD / ADAS / Telematics",
  },
  {
    value: "Air / Fuel module+C8C87:C115",
    label: "Air / Fuel module+C8C87:C115",
    category: "Air intake & exhaust systems",
  },
  {
    value: "Air Cleaners",
    label: "Air Cleaners",
    category: "Motorcycling",
  },
  {
    value: "Air Fresheners",
    label: "Air Fresheners",
    category: "Vehicle Care ",
  },
  {
    value: "Air brake",
    label: "Air brake",
    category: "Brake & Pedal",
  },
  {
    value: "Air cleaner",
    label: "Air cleaner",
    category: "Air intake & exhaust systems",
  },
  {
    value: "Air cleaner case",
    label: "Air cleaner case",
    category: "Air intake & exhaust systems",
  },
  {
    value: "Air cleaner element",
    label: "Air cleaner element",
    category: "Air intake & exhaust systems",
  },
  {
    value: "Air conditioner",
    label: "Air conditioner",
    category: "Climate Control",
  },
  {
    value: "Air conditioner ECU",
    label: "Air conditioner ECU",
    category: "Electrical / Electronic Parts - ECU",
  },
  {
    value: "Air conditioner clutch",
    label: "Air conditioner clutch",
    category: "Climate Control",
  },
  {
    value: "Air conditioner compressor",
    label: "Air conditioner compressor",
    category: "Climate Control",
  },
  {
    value: "Air conditioner condenser",
    label: "Air conditioner condenser",
    category: "Climate Control",
  },
  {
    value: "Air conditioner coolant temperature sensor",
    label: "Air conditioner coolant temperature sensor",
    category: "Sensor",
  },
  {
    value: "Air conditioner evaporator",
    label: "Air conditioner evaporator",
    category: "Climate Control",
  },
  {
    value: "Air conditioner fan switch",
    label: "Air conditioner fan switch",
    category: "Hiddin Switch",
  },
  {
    value: "Air conditioner hose",
    label: "Air conditioner hose",
    category: "Climate Control",
  },
  {
    value: "Air conditioner parts",
    label: "Air conditioner parts",
    category: "Climate Control",
  },
  {
    value: "Air conditioner pressure switch",
    label: "Air conditioner pressure switch",
    category: "Hiddin Switch",
  },
  {
    value: "Air conditioner receiver",
    label: "Air conditioner receiver",
    category: "Climate Control",
  },
  {
    value: "Air conditioner temperature sensor",
    label: "Air conditioner temperature sensor",
    category: "Sensor",
  },
  {
    value: "Air conditioner temperature switch",
    label: "Air conditioner temperature switch",
    category: "Hiddin Switch",
  },
  {
    value: "Air duct",
    label: "Air duct",
    category: "Climate Control",
  },
  {
    value: "Air flow meter",
    label: "Air flow meter",
    category: "Fuel Supply System",
  },
  {
    value: "Air flow sensor",
    label: "Air flow sensor",
    category: "Sensor",
  },
  {
    value: "Air intake module",
    label: "Air intake module",
    category: "Air intake & exhaust systems",
  },
  {
    value: "Air intake resonator",
    label: "Air intake resonator",
    category: "Air intake & exhaust systems",
  },
  {
    value: "Air pump",
    label: "Air pump",
    category: "Air intake & exhaust systems",
  },
  {
    value: "Air purifier motor",
    label: "Air purifier motor",
    category: "Motor",
  },
  {
    value: "Air purifier parts",
    label: "Air purifier parts",
    category: "Climate Control",
  },
  {
    value: "Air shock absorber",
    label: "Air shock absorber",
    category: "Suspendion",
  },
  {
    value: "Air suspension",
    label: "Air suspension",
    category: "Suspendion",
  },
  {
    value: "Air suspension parts",
    label: "Air suspension parts",
    category: "Suspendion",
  },
  {
    value: "Airbag ECU",
    label: "Airbag ECU",
    category: "Electrical / Electronic Parts - ECU",
  },
  {
    value: "Airbag fabric",
    label: "Airbag fabric",
    category: "Airbag",
  },
  {
    value: "Airbag module",
    label: "Airbag module",
    category: "Airbag",
  },
  {
    value: "Airbag sensor",
    label: "Airbag sensor",
    category: "Sensor",
  },
  {
    value: "Airbeds, Camping Beds & Sleeping Mats",
    label: "Airbeds, Camping Beds & Sleeping Mats",
    category: "Camping ",
  },
  {
    value: "Airbeds, Camping Beds & Sleeping Mats",
    label: "Airbeds, Camping Beds & Sleeping Mats",
    category: "Caravan & Motorhomes",
  },
  {
    value: "Alexa Technology",
    label: "Alexa Technology",
    category: "Security I Entertainment",
  },
  {
    value: "All Car Cleaning",
    label: "All Car Cleaning",
    category: "Vehicle Care ",
  },
  {
    value: "All Car Seats",
    label: "All Car Seats",
    category: "Child Seat",
  },
  {
    value: "All Motorcycling",
    label: "All Motorcycling",
    category: "Motorcycling",
  },
  {
    value: "All Paints & Body Repair",
    label: "All Paints & Body Repair",
    category: "Paints & Body Repair",
  },
  {
    value: "Alloy wheel",
    label: "Alloy wheel",
    category: "Tyre & Wheel",
  },
  {
    value: "Alternator",
    label: "Alternator",
    category: "Engine Electrical Parts",
  },
  {
    value: "Aluminum casting",
    label: "Aluminum casting",
    category: "Process type (Stamping, Plastic injection molding etc.)",
  },
  {
    value: "Aluminum die casting",
    label: "Aluminum die casting",
    category: "Process type (Stamping, Plastic injection molding etc.)",
  },
  {
    value: "Aluminum extrusion processing",
    label: "Aluminum extrusion processing",
    category: "Process type (Stamping, Plastic injection molding etc.)",
  },
  {
    value: "Aluminum machining",
    label: "Aluminum machining",
    category: "Process type (Stamping, Plastic injection molding etc.)",
  },
  {
    value: "Alumite treatment",
    label: "Alumite treatment",
    category: "Process type (Stamping, Plastic injection molding etc.)",
  },
  {
    value: "Ambient temperature sensor",
    label: "Ambient temperature sensor",
    category: "Sensor",
  },
  {
    value: "Angle sensor",
    label: "Angle sensor",
    category: "Sensor",
  },
  {
    value: "Anode materials / plates for lithium-ion battery",
    label: "Anode materials / plates for lithium-ion battery",
    category: "Battery & Related Parts",
  },
  {
    value: "Anode materials / platse for nickel metal hydride battery",
    label: "Anode materials / platse for nickel metal hydride battery",
    category: "Battery & Related Parts",
  },
  {
    value: "Antenna motor",
    label: "Antenna motor",
    category: "Motor",
  },
  {
    value: "Armature",
    label: "Armature",
    category: "Steering",
  },
  {
    value: "Armrest",
    label: "Armrest",
    category: "Interior Trim & Parts",
  },
  {
    value: "Ashtray",
    label: "Ashtray",
    category: "Interior Trim & Parts",
  },
  {
    value: "Assembly",
    label: "Assembly",
    category: "Process type (Stamping, Plastic injection molding etc.)",
  },
  {
    value: "Assist grip",
    label: "Assist grip",
    category: "Interior Trim & Parts",
  },
  {
    value: "Audio amplifier",
    label: "Audio amplifier",
    category: "AD / ADAS / Telematics",
  },
  {
    value: "Audio equalizer",
    label: "Audio equalizer",
    category: "AD / ADAS / Telematics",
  },
  {
    value: "Auto cruise control switch",
    label: "Auto cruise control switch",
    category: "Interior Switch",
  },
  {
    value: "Automatic Emergency Brake (AEB)",
    label: "Automatic Emergency Brake (AEB)",
    category: "AD / ADAS / Telematics",
  },
  {
    value: "Automatic choke",
    label: "Automatic choke",
    category: "Air intake & exhaust systems",
  },
  {
    value: "Automatic parking system",
    label: "Automatic parking system",
    category: "AD / ADAS / Telematics",
  },
  {
    value: "Automatic transmission assembly",
    label: "Automatic transmission assembly",
    category: "Transmission & Parts",
  },
  {
    value: "Automatic transmission carrier",
    label: "Automatic transmission carrier",
    category: "Transmission & Parts",
  },
  {
    value: "Automatic transmission clutch",
    label: "Automatic transmission clutch",
    category: "Transmission & Parts",
  },
  {
    value: "Automatic transmission control parts",
    label: "Automatic transmission control parts",
    category: "Transmission & Parts",
  },
  {
    value: "Automatic transmission fluid pump",
    label: "Automatic transmission fluid pump",
    category: "Transmission & Parts",
  },
  {
    value: "Automatic transmission fluid pump front body",
    label: "Automatic transmission fluid pump front body",
    category: "Transmission & Parts",
  },
  {
    value: "Automatic transmission fluid pump parts",
    label: "Automatic transmission fluid pump parts",
    category: "Transmission & Parts",
  },
  {
    value: "Automatic transmission fluid pump rear cover",
    label: "Automatic transmission fluid pump rear cover",
    category: "Transmission & Parts",
  },
  {
    value: "Automatic transmission shift lock mechanism",
    label: "Automatic transmission shift lock mechanism",
    category: "Transmission & Parts",
  },
  {
    value: "Automatic transmission solenoid",
    label: "Automatic transmission solenoid",
    category: "Transmission & Parts",
  },
  {
    value: "Automatic transmission valve body",
    label: "Automatic transmission valve body",
    category: "Transmission & Parts",
  },
  {
    value: "Autonomous Driving (AD) system",
    label: "Autonomous Driving (AD) system",
    category: "AD / ADAS / Telematics",
  },
  {
    value: "Auxiliary brake",
    label: "Auxiliary brake",
    category: "Brake & Pedal",
  },
  {
    value: "Auxiliary brake",
    label: "Auxiliary brake",
    category: "Brake & Pedal",
  },
  {
    value: "Auxiliary heater",
    label: "Auxiliary heater",
    category: "Climate Control",
  },
  {
    value: "Awnings & Accessories",
    label: "Awnings & Accessories",
    category: "Caravan & Motorhomes",
  },
  {
    value: "Axle case",
    label: "Axle case",
    category: "Axle",
  },
  {
    value: "Axle module",
    label: "Axle module",
    category: "Axle",
  },
  {
    value: "Axle parts",
    label: "Axle parts",
    category: "Axle",
  },
  {
    value: "Axle shaft",
    label: "Axle shaft",
    category: "Axle",
  },
  {
    value: "BMS (Battery Management System)",
    label: "BMS (Battery Management System)",
    category: "Battery & Related Parts",
  },
  {
    value: "Baby Car Seats",
    label: "Baby Car Seats",
    category: "Child Seat",
  },
  {
    value: "Balance shaft",
    label: "Balance shaft",
    category: "Engine Structural Parts",
  },
  {
    value: "Balance shaft gear",
    label: "Balance shaft gear",
    category: "Engine Structural Parts",
  },
  {
    value: "Ball screw",
    label: "Ball screw",
    category: "Steering",
  },
  {
    value: "Batteries & Electricals",
    label: "Batteries & Electricals",
    category: "Motorcycling",
  },
  {
    value: "Battery",
    label: "Battery",
    category: "Engine Electrical Parts",
  },
  {
    value: "Battery booster cable",
    label: "Battery booster cable",
    category: "General Parts",
  },
  {
    value: "Battery cable",
    label: "Battery cable",
    category: "Engine Electrical Parts",
  },
  {
    value: "Battery case",
    label: "Battery case",
    category: "Battery & Related Parts",
  },
  {
    value: "Battery charger",
    label: "Battery charger",
    category: "General Parts",
  },
  {
    value: "Battery cooling plate",
    label: "Battery cooling plate",
    category: "EV Cooling System",
  },
  {
    value: "Battery cooling system",
    label: "Battery cooling system",
    category: "EV Cooling System",
  },
  {
    value: "Battery cooling system parts",
    label: "Battery cooling system parts",
    category: "EV Cooling System",
  },
  {
    value: "Bearing",
    label: "Bearing",
    category: "General Parts",
  },
  {
    value: "Bedplate",
    label: "Bedplate",
    category: "Engine Structural Parts",
  },
  {
    value: "Bellows",
    label: "Bellows",
    category: "General Parts",
  },
  {
    value: "Blind Spot Warning (BSW)",
    label: "Blind Spot Warning (BSW)",
    category: "AD / ADAS / Telematics",
  },
  {
    value: "Blow molding",
    label: "Blow molding",
    category: "Process type (Stamping, Plastic injection molding etc.)",
  },
  {
    value: "Blower",
    label: "Blower",
    category: "Climate Control",
  },
  {
    value: "Blower fan motor",
    label: "Blower fan motor",
    category: "Motor",
  },
  {
    value: "Blower switch",
    label: "Blower switch",
    category: "Hiddin Switch",
  },
  {
    value: "Board mounting",
    label: "Board mounting",
    category: "Process type (Stamping, Plastic injection molding etc.)",
  },
  {
    value: "Body control module (Body ECU)",
    label: "Body control module (Body ECU)",
    category: "Electrical / Electronic Parts - ECU",
  },
  {
    value: "Body insulator",
    label: "Body insulator",
    category: "Body Parts / Frame",
  },
  {
    value: "Body panel",
    label: "Body panel",
    category: "Body Parts / Frame",
  },
  {
    value: "Body reinforcement parts",
    label: "Body reinforcement parts",
    category: "Body Parts / Frame",
  },
  {
    value: "Body side panel parts",
    label: "Body side panel parts",
    category: "Body Parts / Frame",
  },
  {
    value: "Bolt / Nut",
    label: "Bolt / Nut",
    category: "General Parts",
  },
  {
    value: "Booster Seats",
    label: "Booster Seats",
    category: "Child Seat",
  },
  {
    value: "Boot",
    label: "Boot",
    category: "General Parts",
  },
  {
    value: "Brake",
    label: "Brake",
    category: "Brake & Pedal",
  },
  {
    value: "Brake booster",
    label: "Brake booster",
    category: "Brake & Pedal",
  },
  {
    value: "Brake caliper",
    label: "Brake caliper",
    category: "Brake & Pedal",
  },
  {
    value: "Brake caliper piston",
    label: "Brake caliper piston",
    category: "Brake & Pedal",
  },
  {
    value: "Brake disc",
    label: "Brake disc",
    category: "Brake & Pedal",
  },
  {
    value: "Brake drum",
    label: "Brake drum",
    category: "Brake & Pedal",
  },
  {
    value: "Brake hose",
    label: "Brake hose",
    category: "Brake & Pedal",
  },
  {
    value: "Brake lamp switch",
    label: "Brake lamp switch",
    category: "Hiddin Switch",
  },
  {
    value: "Brake lining",
    label: "Brake lining",
    category: "Brake & Pedal",
  },
  {
    value: "Brake master cylinder",
    label: "Brake master cylinder",
    category: "Brake & Pedal",
  },
  {
    value: "Brake pad",
    label: "Brake pad",
    category: "Brake & Pedal",
  },
  {
    value: "Brake pedal",
    label: "Brake pedal",
    category: "Brake & Pedal",
  },
  {
    value: "Brake shoe",
    label: "Brake shoe",
    category: "Brake & Pedal",
  },
  {
    value: "Brake tube",
    label: "Brake tube",
    category: "Brake & Pedal",
  },
  {
    value: "Brake valve",
    label: "Brake valve",
    category: "Brake & Pedal",
  },
  {
    value: "Brake wheel cylinder",
    label: "Brake wheel cylinder",
    category: "Brake & Pedal",
  },
  {
    value: "Brakes",
    label: "Brakes",
    category: "Motorcycling",
  },
  {
    value: "Breakdown Road Sign Kits",
    label: "Breakdown Road Sign Kits",
    category: "Security I Entertainment",
  },
  {
    value: "Bulb",
    label: "Bulb",
    category: "Electrical Parts",
  },
  {
    value: "Bumper",
    label: "Bumper",
    category: "Bumper",
  },
  {
    value: "Bumper beam",
    label: "Bumper beam",
    category: "Body Parts / Frame",
  },
  {
    value: "Bumper energy absorbing parts",
    label: "Bumper energy absorbing parts",
    category: "Bumper",
  },
  {
    value: "Bumper fascia",
    label: "Bumper fascia",
    category: "Bumper",
  },
  {
    value: "Bumper parts",
    label: "Bumper parts",
    category: "Bumper",
  },
  {
    value: "Busbar",
    label: "Busbar",
    category: "Battery & Related Parts",
  },
  {
    value: "Bush",
    label: "Bush",
    category: "General Parts",
  },
  {
    value: "CCP solenoid",
    label: "CCP solenoid",
    category: "Fuel Supply System",
  },
  {
    value: "CD player / DVD player",
    label: "CD player / DVD player",
    category: "AD / ADAS / Telematics",
  },
  {
    value: "CNG system",
    label: "CNG system",
    category: "Fuel Supply System",
  },
  {
    value: "CNG system parts",
    label: "CNG system parts",
    category: "Fuel Supply System",
  },
  {
    value: "CPU",
    label: "CPU",
    category: "Semiconductor Device",
  },
  {
    value: "CVT ECU",
    label: "CVT ECU",
    category: "Electrical / Electronic Parts - ECU",
  },
  {
    value: "CVT assembly (transmission)",
    label: "CVT assembly (transmission)",
    category: "Transmission & Parts",
  },
  {
    value: "CVT belt (transmission)",
    label: "CVT belt (transmission)",
    category: "Transmission & Parts",
  },
  {
    value: "CVT parts (transmission)",
    label: "CVT parts (transmission)",
    category: "Transmission & Parts",
  },
  {
    value: "Cabin air filter",
    label: "Cabin air filter",
    category: "Climate Control",
  },
  {
    value: "Cam sensor",
    label: "Cam sensor",
    category: "Sensor",
  },
  {
    value: "Camera Monitoring System (CMS)",
    label: "Camera Monitoring System (CMS)",
    category: "AD / ADAS / Telematics",
  },
  {
    value: "Camping Accessories",
    label: "Camping Accessories",
    category: "Camping ",
  },
  {
    value: "Camping Accessories",
    label: "Camping Accessories",
    category: "Caravan & Motorhomes",
  },
  {
    value: "Camping Bundles",
    label: "Camping Bundles",
    category: "Camping ",
  },
  {
    value: "Camping Bundles",
    label: "Camping Bundles",
    category: "Caravan & Motorhomes",
  },
  {
    value: "Camping Essentials",
    label: "Camping Essentials",
    category: "Camping ",
  },
  {
    value: "Camping Essentials",
    label: "Camping Essentials",
    category: "Caravan & Motorhomes",
  },
  {
    value: "Camping Furniture",
    label: "Camping Furniture",
    category: "Camping ",
  },
  {
    value: "Camping Furniture",
    label: "Camping Furniture",
    category: "Caravan & Motorhomes",
  },
  {
    value: "Camshaft",
    label: "Camshaft",
    category: "Valvetrain",
  },
  {
    value: "Canister",
    label: "Canister",
    category: "Fuel Supply System",
  },
  {
    value: "Canister filter",
    label: "Canister filter",
    category: "Fuel Supply System",
  },
  {
    value: "Capacitor",
    label: "Capacitor",
    category: "Electronic Device",
  },
  {
    value: "Car Audio / Video Player",
    label: "Car Audio / Video Player",
    category: "Security I Entertainment",
  },
  {
    value: "Car Audio Player",
    label: "Car Audio Player",
    category: "Security I Entertainment",
  },
  {
    value: "Car Cleaning Bundles",
    label: "Car Cleaning Bundles",
    category: "Vehicle Care ",
  },
  {
    value: "Car Cleaning Kits",
    label: "Car Cleaning Kits",
    category: "Vehicle Care ",
  },
  {
    value: "Car Cleaning Materials",
    label: "Car Cleaning Materials",
    category: "Vehicle Care ",
  },
  {
    value: "Car Polish",
    label: "Car Polish",
    category: "Vehicle Care ",
  },
  {
    value: "Car Seat Bases",
    label: "Car Seat Bases",
    category: "Child Seat",
  },
  {
    value: "Car Seat Bundles",
    label: "Car Seat Bundles",
    category: "Child Seat",
  },
  {
    value: "Car Shampoo",
    label: "Car Shampoo",
    category: "Vehicle Care ",
  },
  {
    value: "Car Spray Paints",
    label: "Car Spray Paints",
    category: "Paints & Body Repair",
  },
  {
    value: "Car Trackers",
    label: "Car Trackers",
    category: "Security I Entertainment",
  },
  {
    value: "Car Video Player",
    label: "Car Video Player",
    category: "Security I Entertainment",
  },
  {
    value: "Car Wax",
    label: "Car Wax",
    category: "Vehicle Care ",
  },
  {
    value: "Car audio parts",
    label: "Car audio parts",
    category: "AD / ADAS / Telematics",
  },
  {
    value: "Car navigation ECU",
    label: "Car navigation ECU",
    category: "Electrical / Electronic Parts - ECU",
  },
  {
    value: "Car radio",
    label: "Car radio",
    category: "AD / ADAS / Telematics",
  },
  {
    value: "Caravan Cleaning",
    label: "Caravan Cleaning",
    category: "Caravan & Motorhomes",
  },
  {
    value: "Caravan Covers & Blinds",
    label: "Caravan Covers & Blinds",
    category: "Caravan & Motorhomes",
  },
  {
    value: "Caravning Essentials",
    label: "Caravning Essentials",
    category: "Caravan & Motorhomes",
  },
  {
    value: "Carbon brush",
    label: "Carbon brush",
    category: "Electrical Parts",
  },
  {
    value: "Carburetor",
    label: "Carburetor",
    category: "Air intake & exhaust systems",
  },
  {
    value: "Carburetor parts",
    label: "Carburetor parts",
    category: "Air intake & exhaust systems",
  },
  {
    value: "Cardan joint",
    label: "Cardan joint",
    category: "Axle",
  },
  {
    value: "Carpet",
    label: "Carpet",
    category: "Interior Trim & Parts",
  },
  {
    value: "Catalyst",
    label: "Catalyst",
    category: "Air intake & exhaust systems",
  },
  {
    value: "Catalyst carrier",
    label: "Catalyst carrier",
    category: "Air intake & exhaust systems",
  },
  {
    value: "Catalytic converter",
    label: "Catalytic converter",
    category: "Air intake & exhaust systems",
  },
  {
    value: "Catalytic converter parts",
    label: "Catalytic converter parts",
    category: "Air intake & exhaust systems",
  },
  {
    value: "Cathode materials / plates for lithium-ion battery",
    label: "Cathode materials / plates for lithium-ion battery",
    category: "Battery & Related Parts",
  },
  {
    value: "Cathode materials / plates for nickel metal hydride battery",
    label: "Cathode materials / plates for nickel metal hydride battery",
    category: "Battery & Related Parts",
  },
  {
    value: "Ceramic Coating",
    label: "Ceramic Coating",
    category: "Vehicle Care ",
  },
  {
    value: "Chain tensioner",
    label: "Chain tensioner",
    category: "Valvetrain",
  },
  {
    value: "Charging equipment (normal / fast)",
    label: "Charging equipment (normal / fast)",
    category: "Battery & Related Parts",
  },
  {
    value: "Chassis / Frame",
    label: "Chassis / Frame",
    category: "Body Parts / Frame",
  },
  {
    value: "Chassis module",
    label: "Chassis module",
    category: "Chassis Module",
  },
  {
    value: "Child Car Seats",
    label: "Child Car Seats",
    category: "Child Seat",
  },
  {
    value: "Child seat",
    label: "Child seat",
    category: "General Parts",
  },
  {
    value: "Cigarette lighter",
    label: "Cigarette lighter",
    category: "Interior Trim & Parts",
  },
  {
    value: "Clamp",
    label: "Clamp",
    category: "General Parts",
  },
  {
    value: "Clay Bars & Detailing Products",
    label: "Clay Bars & Detailing Products",
    category: "Vehicle Care ",
  },
  {
    value: "Clip",
    label: "Clip",
    category: "General Parts",
  },
  {
    value: "Clock",
    label: "Clock",
    category: "Interior Trim & Parts",
  },
  {
    value: "Clutch assembly",
    label: "Clutch assembly",
    category: "Clutch",
  },
  {
    value: "Clutch cover",
    label: "Clutch cover",
    category: "Clutch",
  },
  {
    value: "Clutch cover complete",
    label: "Clutch cover complete",
    category: "Clutch",
  },
  {
    value: "Clutch disc",
    label: "Clutch disc",
    category: "Clutch",
  },
  {
    value: "Clutch facing",
    label: "Clutch facing",
    category: "Clutch",
  },
  {
    value: "Clutch master cylinder / pump",
    label: "Clutch master cylinder / pump",
    category: "Clutch",
  },
  {
    value: "Clutch pedal",
    label: "Clutch pedal",
    category: "Brake & Pedal",
  },
  {
    value: "Clutch pressure plate",
    label: "Clutch pressure plate",
    category: "Clutch",
  },
  {
    value: "Clutch spring",
    label: "Clutch spring",
    category: "Clutch",
  },
  {
    value: "Cockpit module",
    label: "Cockpit module",
    category: "Cockpit",
  },
  {
    value: "Cockpit related parts",
    label: "Cockpit related parts",
    category: "Cockpit",
  },
  {
    value: "Coil",
    label: "Coil",
    category: "Electrical Parts",
  },
  {
    value: "Coil spring",
    label: "Coil spring",
    category: "Suspendion",
  },
  {
    value: "Cold forging",
    label: "Cold forging",
    category: "Process type (Stamping, Plastic injection molding etc.)",
  },
  {
    value: "Combination switch",
    label: "Combination switch",
    category: "Interior Switch",
  },
  {
    value: "Combustion pressure sensor",
    label: "Combustion pressure sensor",
    category: "Sensor",
  },
  {
    value: "Common rail",
    label: "Common rail",
    category: "Fuel Supply System",
  },
  {
    value: "Connecting rod",
    label: "Connecting rod",
    category: "Engine Structural Parts",
  },
  {
    value: "Connecting rod bolt",
    label: "Connecting rod bolt",
    category: "Engine Structural Parts",
  },
  {
    value: "Connecting rod bush",
    label: "Connecting rod bush",
    category: "Engine Structural Parts",
  },
  {
    value: "Connector",
    label: "Connector",
    category: "General Parts",
  },
  {
    value: "Console box",
    label: "Console box",
    category: "Interior Trim & Parts",
  },
  {
    value: "Constant velocity joint",
    label: "Constant velocity joint",
    category: "Axle",
  },
  {
    value: "Contact breaker",
    label: "Contact breaker",
    category: "Ignition System",
  },
  {
    value: "Control arm",
    label: "Control arm",
    category: "Suspendion",
  },
  {
    value: "Control cable",
    label: "Control cable",
    category: "General Parts",
  },
  {
    value: "Controls",
    label: "Controls",
    category: "Motorcycling",
  },
  {
    value: "Converter housing",
    label: "Converter housing",
    category: "Air intake & exhaust systems",
  },
  {
    value: "Convertible roof",
    label: "Convertible roof",
    category: "Body Parts / Frame",
  },
  {
    value: "Convertible roof parts",
    label: "Convertible roof parts",
    category: "Body Parts / Frame",
  },
  {
    value: "Cooking Equipment",
    label: "Cooking Equipment",
    category: "Camping ",
  },
  {
    value: "Cooking Equipment",
    label: "Cooking Equipment",
    category: "Caravan & Motorhomes",
  },
  {
    value: "Coolboxes",
    label: "Coolboxes",
    category: "Camping ",
  },
  {
    value: "Coolboxes",
    label: "Coolboxes",
    category: "Caravan & Motorhomes",
  },
  {
    value: "Cooling fan",
    label: "Cooling fan",
    category: "Engine Cooling System",
  },
  {
    value: "Cooling fan belt",
    label: "Cooling fan belt",
    category: "Engine Cooling System",
  },
  {
    value: "Cooling fan control module",
    label: "Cooling fan control module",
    category: "Engine Cooling System",
  },
  {
    value: "Cooling fan coupling",
    label: "Cooling fan coupling",
    category: "Engine Cooling System",
  },
  {
    value: "Cooling fan module",
    label: "Cooling fan module",
    category: "Engine Cooling System",
  },
  {
    value: "Cornering lamp",
    label: "Cornering lamp",
    category: "Lighting ",
  },
  {
    value: "Countershaft",
    label: "Countershaft",
    category: "Transmission & Parts",
  },
  {
    value: "Crank angle sensor",
    label: "Crank angle sensor",
    category: "Sensor",
  },
  {
    value: "Crankcase",
    label: "Crankcase",
    category: "Engine Structural Parts",
  },
  {
    value: "Crankcase ventilation system",
    label: "Crankcase ventilation system",
    category: "Engine Structural Parts",
  },
  {
    value: "Crankcase ventilation valve",
    label: "Crankcase ventilation valve",
    category: "Engine Structural Parts",
  },
  {
    value: "Crankshaft",
    label: "Crankshaft",
    category: "Engine Structural Parts",
  },
  {
    value: "Cross Traffic Alert (CTA)",
    label: "Cross Traffic Alert (CTA)",
    category: "AD / ADAS / Telematics",
  },
  {
    value: "Cross car beam",
    label: "Cross car beam",
    category: "Body Parts / Frame",
  },
  {
    value: "Cruise control",
    label: "Cruise control",
    category: "AD / ADAS / Telematics",
  },
  {
    value: "Cupholder",
    label: "Cupholder",
    category: "Interior Trim & Parts",
  },
  {
    value: "Curtain shield airbag",
    label: "Curtain shield airbag",
    category: "Airbag",
  },
  {
    value: "Cylinder block",
    label: "Cylinder block",
    category: "Engine Structural Parts",
  },
  {
    value: "Cylinder head",
    label: "Cylinder head",
    category: "Engine Structural Parts",
  },
  {
    value: "Cylinder head bolt",
    label: "Cylinder head bolt",
    category: "Engine Structural Parts",
  },
  {
    value: "Cylinder head cover",
    label: "Cylinder head cover",
    category: "Engine Structural Parts",
  },
  {
    value: "Cylinder head gasket",
    label: "Cylinder head gasket",
    category: "Engine Structural Parts",
  },
  {
    value: "Cylinder liner",
    label: "Cylinder liner",
    category: "Engine Structural Parts",
  },
  {
    value: "DC-DC converter",
    label: "DC-DC converter",
    category: "Electrical Parts",
  },
  {
    value: "DC-DC converter for PCU",
    label: "DC-DC converter for PCU",
    category: "Electric powertrain system parts",
  },
  {
    value: "DC-DC converter for PCU",
    label: "DC-DC converter for PCU",
    category: "Driver motor",
  },
  {
    value: "DCT assembly (transmission)",
    label: "DCT assembly (transmission)",
    category: "Transmission & Parts",
  },
  {
    value: "DCT parts (transmission)",
    label: "DCT parts (transmission)",
    category: "Transmission & Parts",
  },
  {
    value: "Dash & Gauges",
    label: "Dash & Gauges",
    category: "Motorcycling",
  },
  {
    value: "Dash Cams ",
    label: "Dash Cams ",
    category: "Security I Entertainment",
  },
  {
    value: "Dash insulator",
    label: "Dash insulator",
    category: "Interior Trim & Parts",
  },
  {
    value: "Dash panel parts",
    label: "Dash panel parts",
    category: "Body Parts / Frame",
  },
  {
    value: "Defroster",
    label: "Defroster",
    category: "Climate Control",
  },
  {
    value: "Detailing Products",
    label: "Detailing Products",
    category: "Vehicle Care ",
  },
  {
    value: "Diaphragm",
    label: "Diaphragm",
    category: "General Parts",
  },
  {
    value: "Diesel fuel filter",
    label: "Diesel fuel filter",
    category: "Fuel Supply System",
  },
  {
    value: "Diesel injection pump",
    label: "Diesel injection pump",
    category: "Fuel Supply System",
  },
  {
    value: "Diesel injection system",
    label: "Diesel injection system",
    category: "Fuel Supply System",
  },
  {
    value: "Differential",
    label: "Differential",
    category: "Differential",
  },
  {
    value: "Differential case",
    label: "Differential case",
    category: "Differential",
  },
  {
    value: "Differential drive pinion gear",
    label: "Differential drive pinion gear",
    category: "Differential",
  },
  {
    value: "Differential gear",
    label: "Differential gear",
    category: "Differential",
  },
  {
    value: "Differential pinion gear",
    label: "Differential pinion gear",
    category: "Differential",
  },
  {
    value: "Differential pinion shaft",
    label: "Differential pinion shaft",
    category: "Differential",
  },
  {
    value: "Differential ring gear",
    label: "Differential ring gear",
    category: "Differential",
  },
  {
    value: "Differential side gear",
    label: "Differential side gear",
    category: "Differential",
  },
  {
    value: "Diode",
    label: "Diode",
    category: "Semiconductor Device",
  },
  {
    value: "Disc brake",
    label: "Disc brake",
    category: "Brake & Pedal",
  },
  {
    value: "Display parts",
    label: "Display parts",
    category: "Display",
  },
  {
    value: "Distributor",
    label: "Distributor",
    category: "Ignition System",
  },
  {
    value: "Door",
    label: "Door",
    category: "Body Parts / Frame",
  },
  {
    value: "Door armrest switch",
    label: "Door armrest switch",
    category: "Interior Switch",
  },
  {
    value: "Door check",
    label: "Door check",
    category: "Body Parts / Frame",
  },
  {
    value: "Door closure",
    label: "Door closure",
    category: "Body Parts / Frame",
  },
  {
    value: "Door hinge",
    label: "Door hinge",
    category: "Body Parts / Frame",
  },
  {
    value: "Door lock",
    label: "Door lock",
    category: "Body Parts / Frame",
  },
  {
    value: "Door lock controller",
    label: "Door lock controller",
    category: "Body Parts / Frame",
  },
  {
    value: "Door lock module",
    label: "Door lock module",
    category: "Body Parts / Frame",
  },
  {
    value: "Door lock parts",
    label: "Door lock parts",
    category: "Body Parts / Frame",
  },
  {
    value: "Door mirror retraction motor",
    label: "Door mirror retraction motor",
    category: "Motor",
  },
  {
    value: "Door module",
    label: "Door module",
    category: "Body Parts / Frame",
  },
  {
    value: "Door panel parts",
    label: "Door panel parts",
    category: "Body Parts / Frame",
  },
  {
    value: "Door trim",
    label: "Door trim",
    category: "Interior Trim & Parts",
  },
  {
    value: "Drive & Transmission",
    label: "Drive & Transmission",
    category: "Motorcycling",
  },
  {
    value: "Drive motor",
    label: "Drive motor",
    category: "Driver motor",
  },
  {
    value: "Drive motor <Permanent magnet synchronous motor>",
    label: "Drive motor <Permanent magnet synchronous motor>",
    category: "Driver motor",
  },
  {
    value: "Drive motor cooling system",
    label: "Drive motor cooling system",
    category: "EV Cooling System",
  },
  {
    value: "Drive motor cooling system parts",
    label: "Drive motor cooling system parts",
    category: "EV Cooling System",
  },
  {
    value: "Drive motor housing",
    label: "Drive motor housing",
    category: "Driver motor",
  },
  {
    value: "Drive motor resolver (angle sensor)",
    label: "Drive motor resolver (angle sensor)",
    category: "Driver motor",
  },
  {
    value: "Drive motor shaft",
    label: "Drive motor shaft",
    category: "Driver motor",
  },
  {
    value: "Drive plate",
    label: "Drive plate",
    category: "Engine Structural Parts",
  },
  {
    value: "Drive plate ring gear",
    label: "Drive plate ring gear",
    category: "Engine Structural Parts",
  },
  {
    value: "Driver Monitor System (DMS)",
    label: "Driver Monitor System (DMS)",
    category: "AD / ADAS / Telematics",
  },
  {
    value: "Driver airbag module",
    label: "Driver airbag module",
    category: "Airbag",
  },
  {
    value: "Driveshaft",
    label: "Driveshaft",
    category: "Axle",
  },
  {
    value: "Drum brake",
    label: "Drum brake",
    category: "Brake & Pedal",
  },
  {
    value: "Duct",
    label: "Duct",
    category: "General Parts",
  },
  {
    value: "EEPROM / Flash memories",
    label: "EEPROM / Flash memories",
    category: "Semiconductor Device",
  },
  {
    value: "EGR cooler",
    label: "EGR cooler",
    category: "Air intake & exhaust systems",
  },
  {
    value: "EGR pipe",
    label: "EGR pipe",
    category: "Air intake & exhaust systems",
  },
  {
    value: "EGR valve",
    label: "EGR valve",
    category: "Air intake & exhaust systems",
  },
  {
    value: "EL display unit",
    label: "EL display unit",
    category: "Display",
  },
  {
    value: "EL panel (Electroluminescence panel)",
    label: "EL panel (Electroluminescence panel)",
    category: "Electronic Device",
  },
  {
    value: "ESC system parts",
    label: "ESC system parts",
    category: "ABS / TCS /ESC ",
  },
  {
    value: "EV motor control ECU",
    label: "EV motor control ECU",
    category: "Electrical / Electronic Parts - ECU",
  },
  {
    value: "EV power ECU",
    label: "EV power ECU",
    category: "Electrical / Electronic Parts - ECU",
  },
  {
    value: "EV speed reducer (reduction gear)",
    label: "EV speed reducer (reduction gear)",
    category: "Electric powertrain system parts",
  },
  {
    value: "EV speed reducer (reduction gear)",
    label: "EV speed reducer (reduction gear)",
    category: "Driver motor",
  },
  {
    value: "Egr gas temperature sensor",
    label: "Egr gas temperature sensor",
    category: "Sensor",
  },
  {
    value: "Electric Parking Brake (EPB)",
    label: "Electric Parking Brake (EPB)",
    category: "Brake & Pedal",
  },
  {
    value: "Electric Parking Brake (EPB)",
    label: "Electric Parking Brake (EPB)",
    category: "Brake & Pedal",
  },
  {
    value: "Electric Parking Brake (EPB) ECU",
    label: "Electric Parking Brake (EPB) ECU",
    category: "Electrical / Electronic Parts - ECU",
  },
  {
    value: "Electric Power Steering (EPS) ECU",
    label: "Electric Power Steering (EPS) ECU",
    category: "Electrical / Electronic Parts - ECU",
  },
  {
    value: "Electric air purifier",
    label: "Electric air purifier",
    category: "Climate Control",
  },
  {
    value: "Electric brake (Brake-by-wire)",
    label: "Electric brake (Brake-by-wire)",
    category: "Brake & Pedal",
  },
  {
    value: "Electric cable",
    label: "Electric cable",
    category: "Electrical Parts",
  },
  {
    value: "Electric connector",
    label: "Electric connector",
    category: "Electrical Parts",
  },
  {
    value: "Electric parking brake parts",
    label: "Electric parking brake parts",
    category: "Brake & Pedal",
  },
  {
    value: "Electric parking brake parts",
    label: "Electric parking brake parts",
    category: "Brake & Pedal",
  },
  {
    value: "Electric power steering (EPS)",
    label: "Electric power steering (EPS)",
    category: "Steering",
  },
  {
    value: "Electric powertrain system",
    label: "Electric powertrain system",
    category: "Driver motor",
  },
  {
    value: "Electric suspension",
    label: "Electric suspension",
    category: "Suspendion",
  },
  {
    value: "Electric suspension parts",
    label: "Electric suspension parts",
    category: "Suspendion",
  },
  {
    value: "Electrical & Power",
    label: "Electrical & Power",
    category: "Caravan & Motorhomes",
  },
  {
    value: "Electrical steel sheet for drive motor",
    label: "Electrical steel sheet for drive motor",
    category: "Driver motor",
  },
  {
    value: "Electronic Stability Control (ESC) system",
    label: "Electronic Stability Control (ESC) system",
    category: "ABS / TCS /ESC ",
  },
  {
    value: "Electronic Stability Control (ESC) system ECU",
    label: "Electronic Stability Control (ESC) system ECU",
    category: "Electrical / Electronic Parts - ECU",
  },
  {
    value: "Electronic controlled shock absorber",
    label: "Electronic controlled shock absorber",
    category: "Suspendion",
  },
  {
    value: "Electronic throttle control system",
    label: "Electronic throttle control system",
    category: "Fuel Supply System",
  },
  {
    value: "Emblem",
    label: "Emblem",
    category: "Exterior Parts",
  },
  {
    value: "Energy absorbing steering",
    label: "Energy absorbing steering",
    category: "Steering",
  },
  {
    value: "Engine ECU",
    label: "Engine ECU",
    category: "Electrical / Electronic Parts - ECU",
  },
  {
    value: "Engine assembly",
    label: "Engine assembly",
    category: "Engine Structural Parts",
  },
  {
    value: "Engine bearing cap",
    label: "Engine bearing cap",
    category: "Engine Structural Parts",
  },
  {
    value: "Engine compartment panel parts",
    label: "Engine compartment panel parts",
    category: "Body Parts / Frame",
  },
  {
    value: "Engine control parts",
    label: "Engine control parts",
    category: "Engine Electrical Parts",
  },
  {
    value: "Engine cooling module",
    label: "Engine cooling module",
    category: "Engine Cooling System",
  },
  {
    value: "Engine cover",
    label: "Engine cover",
    category: "Engine Structural Parts",
  },
  {
    value: "Engine front cover",
    label: "Engine front cover",
    category: "Engine Structural Parts",
  },
  {
    value: "Engine mount",
    label: "Engine mount",
    category: "Engine Structural Parts",
  },
  {
    value: "Engine mount bracket",
    label: "Engine mount bracket",
    category: "Engine Structural Parts",
  },
  {
    value: "Engine pulley",
    label: "Engine pulley",
    category: "Engine Structural Parts",
  },
  {
    value: "Engine speed sensor",
    label: "Engine speed sensor",
    category: "Sensor",
  },
  {
    value: "Engine temperature sensor",
    label: "Engine temperature sensor",
    category: "Sensor",
  },
  {
    value: "Engine undercover",
    label: "Engine undercover",
    category: "Body Parts / Frame",
  },
  {
    value: "Engines & Components",
    label: "Engines & Components",
    category: "Motorcycling",
  },
  {
    value: "Exhaust gas temperature sensor",
    label: "Exhaust gas temperature sensor",
    category: "Sensor",
  },
  {
    value: "Exhaust gasket",
    label: "Exhaust gasket",
    category: "Air intake & exhaust systems",
  },
  {
    value: "Exhaust manifold",
    label: "Exhaust manifold",
    category: "Air intake & exhaust systems",
  },
  {
    value: "Exhaust module",
    label: "Exhaust module",
    category: "Air intake & exhaust systems",
  },
  {
    value: "Exhaust pipe",
    label: "Exhaust pipe",
    category: "Air intake & exhaust systems",
  },
  {
    value: "Exhaust system",
    label: "Exhaust system",
    category: "Air intake & exhaust systems",
  },
  {
    value: "Exhaust valve",
    label: "Exhaust valve",
    category: "Valvetrain",
  },
  {
    value: "Exhausts & Parts",
    label: "Exhausts & Parts",
    category: "Motorcycling",
  },
  {
    value: "Expansion valve",
    label: "Expansion valve",
    category: "Climate Control",
  },
  {
    value: "Exterior Cleaning",
    label: "Exterior Cleaning",
    category: "Vehicle Care ",
  },
  {
    value: "Exterior decorative parts",
    label: "Exterior decorative parts",
    category: "Exterior Parts",
  },
  {
    value: "Fastener",
    label: "Fastener",
    category: "General Parts",
  },
  {
    value: "Feed pump",
    label: "Feed pump",
    category: "Fuel Supply System",
  },
  {
    value: "Fender / Wheelhouse panel parts",
    label: "Fender / Wheelhouse panel parts",
    category: "Body Parts / Frame",
  },
  {
    value: "Fillers & Preparation",
    label: "Fillers & Preparation",
    category: "Paints & Body Repair",
  },
  {
    value: "Film",
    label: "Film",
    category: "General Parts",
  },
  {
    value: "Film antenna",
    label: "Film antenna",
    category: "AD / ADAS / Telematics",
  },
  {
    value: "Fine blanking",
    label: "Fine blanking",
    category: "Process type (Stamping, Plastic injection molding etc.)",
  },
  {
    value: "Fire Extinguishers",
    label: "Fire Extinguishers",
    category: "Security I Entertainment",
  },
  {
    value: "Flasher",
    label: "Flasher",
    category: "Electrical Parts",
  },
  {
    value: "Floor insulator",
    label: "Floor insulator",
    category: "Interior Trim & Parts",
  },
  {
    value: "Floor mat",
    label: "Floor mat",
    category: "Interior Trim & Parts",
  },
  {
    value: "Floor panel parts",
    label: "Floor panel parts",
    category: "Body Parts / Frame",
  },
  {
    value: "Floor trim",
    label: "Floor trim",
    category: "Interior Trim & Parts",
  },
  {
    value: "Flywheel",
    label: "Flywheel",
    category: "Engine Structural Parts",
  },
  {
    value: "Flywheel ring gear",
    label: "Flywheel ring gear",
    category: "Engine Structural Parts",
  },
  {
    value: "Foaming",
    label: "Foaming",
    category: "Process type (Stamping, Plastic injection molding etc.)",
  },
  {
    value: "Fog lamp",
    label: "Fog lamp",
    category: "Lighting ",
  },
  {
    value: "Fog lamp switch",
    label: "Fog lamp switch",
    category: "Interior Switch",
  },
  {
    value: "Forward Collision Warning (FCW)",
    label: "Forward Collision Warning (FCW)",
    category: "AD / ADAS / Telematics",
  },
  {
    value: "Frame",
    label: "Frame",
    category: "Body Parts / Frame",
  },
  {
    value: "Front axle",
    label: "Front axle",
    category: "Axle",
  },
  {
    value: "Front axle corner module",
    label: "Front axle corner module",
    category: "Axle",
  },
  {
    value: "Front axle module",
    label: "Front axle module",
    category: "Axle",
  },
  {
    value: "Front chassis module",
    label: "Front chassis module",
    category: "Chassis Module",
  },
  {
    value: "Front end module",
    label: "Front end module",
    category: "Body Parts / Frame",
  },
  {
    value: "Front grille",
    label: "Front grille",
    category: "Exterior Parts",
  },
  {
    value: "Front suspension center module",
    label: "Front suspension center module",
    category: "Chassis Module",
  },
  {
    value: "Front suspension corner module",
    label: "Front suspension corner module",
    category: "Chassis Module",
  },
  {
    value: "Front suspension module",
    label: "Front suspension module",
    category: "Chassis Module",
  },
  {
    value: "Fuel cell",
    label: "Fuel cell",
    category: "Fuel Cell System",
  },
  {
    value: "Fuel cell catalyst",
    label: "Fuel cell catalyst",
    category: "Fuel Cell System",
  },
  {
    value: "Fuel cell electrodes",
    label: "Fuel cell electrodes",
    category: "Fuel Cell System",
  },
  {
    value: "Fuel cell electrolyte membrane",
    label: "Fuel cell electrolyte membrane",
    category: "Fuel Cell System",
  },
  {
    value: "Fuel cell parts",
    label: "Fuel cell parts",
    category: "Fuel Cell System",
  },
  {
    value: "Fuel cell separator",
    label: "Fuel cell separator",
    category: "Fuel Cell System",
  },
  {
    value: "Fuel cell stack",
    label: "Fuel cell stack",
    category: "Fuel Cell System",
  },
  {
    value: "Fuel cell stack parts",
    label: "Fuel cell stack parts",
    category: "Fuel Cell System",
  },
  {
    value: "Fuel cell system",
    label: "Fuel cell system",
    category: "Fuel Cell System",
  },
  {
    value: "Fuel cell system parts",
    label: "Fuel cell system parts",
    category: "Fuel Cell System",
  },
  {
    value: "Fuel evaporator",
    label: "Fuel evaporator",
    category: "Fuel Supply System",
  },
  {
    value: "Fuel filter",
    label: "Fuel filter",
    category: "Fuel Supply System",
  },
  {
    value: "Fuel filter housing",
    label: "Fuel filter housing",
    category: "Fuel Supply System",
  },
  {
    value: "Fuel filter parts",
    label: "Fuel filter parts",
    category: "Fuel Supply System",
  },
  {
    value: "Fuel gallery",
    label: "Fuel gallery",
    category: "Fuel Supply System",
  },
  {
    value: "Fuel handling and evaporative system parts",
    label: "Fuel handling and evaporative system parts",
    category: "Fuel Supply System",
  },
  {
    value: "Fuel hose",
    label: "Fuel hose",
    category: "Fuel Supply System",
  },
  {
    value: "Fuel injection nozzle",
    label: "Fuel injection nozzle",
    category: "Fuel Supply System",
  },
  {
    value: "Fuel injection nozzle holder",
    label: "Fuel injection nozzle holder",
    category: "Fuel Supply System",
  },
  {
    value: "Fuel injector",
    label: "Fuel injector",
    category: "Fuel Supply System",
  },
  {
    value: "Fuel level sensor",
    label: "Fuel level sensor",
    category: "Sensor",
  },
  {
    value: "Fuel meter",
    label: "Fuel meter",
    category: "Instrument Panel ",
  },
  {
    value: "Fuel pump",
    label: "Fuel pump",
    category: "Fuel Supply System",
  },
  {
    value: "Fuel pump housing",
    label: "Fuel pump housing",
    category: "Fuel Supply System",
  },
  {
    value: "Fuel pump module",
    label: "Fuel pump module",
    category: "Fuel Supply System",
  },
  {
    value: "Fuel pump motor",
    label: "Fuel pump motor",
    category: "Motor",
  },
  {
    value: "Fuel pump parts",
    label: "Fuel pump parts",
    category: "Fuel Supply System",
  },
  {
    value: "Fuel rail",
    label: "Fuel rail",
    category: "Fuel Supply System",
  },
  {
    value: "Fuel temperature sensor",
    label: "Fuel temperature sensor",
    category: "Sensor",
  },
  {
    value: "Fuel tube",
    label: "Fuel tube",
    category: "Fuel Supply System",
  },
  {
    value: "Full LCD meter",
    label: "Full LCD meter",
    category: "Instrument Panel ",
  },
  {
    value: "Fuse / Circuit braker",
    label: "Fuse / Circuit braker",
    category: "Electrical Parts",
  },
  {
    value: "Fuse box",
    label: "Fuse box",
    category: "Electrical Parts",
  },
  {
    value: "G sensor",
    label: "G sensor",
    category: "Sensor",
  },
  {
    value: "Garnish",
    label: "Garnish",
    category: "Interior Trim & Parts",
  },
  {
    value: "Gas spring",
    label: "Gas spring",
    category: "Body Parts / Frame",
  },
  {
    value: "Gasebos",
    label: "Gasebos",
    category: "Camping ",
  },
  {
    value: "Gasebos",
    label: "Gasebos",
    category: "Caravan & Motorhomes",
  },
  {
    value: "Gasket",
    label: "Gasket",
    category: "General Parts",
  },
  {
    value: "Gasoline fuel injection system",
    label: "Gasoline fuel injection system",
    category: "Fuel Supply System",
  },
  {
    value: "Gear",
    label: "Gear",
    category: "General Parts",
  },
  {
    value: "Gear selector switch",
    label: "Gear selector switch",
    category: "Hiddin Switch",
  },
  {
    value: "Generator for HEV",
    label: "Generator for HEV",
    category: "Electric powertrain system parts",
  },
  {
    value: "Generator for HEV",
    label: "Generator for HEV",
    category: "Driver motor",
  },
  {
    value: "Glass run channel",
    label: "Glass run channel",
    category: "Exterior Parts",
  },
  {
    value: "Glove box",
    label: "Glove box",
    category: "Interior Trim & Parts",
  },
  {
    value: "Glow plug",
    label: "Glow plug",
    category: "Ignition System",
  },
  {
    value: "Grommet",
    label: "Grommet",
    category: "General Parts",
  },
  {
    value: "Hazard lamp",
    label: "Hazard lamp",
    category: "Lighting ",
  },
  {
    value: "Hazard switch",
    label: "Hazard switch",
    category: "Interior Switch",
  },
  {
    value: "Head Up Display (HUD)",
    label: "Head Up Display (HUD)",
    category: "Display",
  },
  {
    value: "Headlamp",
    label: "Headlamp",
    category: "Lighting ",
  },
  {
    value: "Headlamp aiming control motor",
    label: "Headlamp aiming control motor",
    category: "Motor",
  },
  {
    value: "Headlamp cleaner motor",
    label: "Headlamp cleaner motor",
    category: "Motor",
  },
  {
    value: "Headlamp light distribution control system (AHB, Automatic High Beam)",
    label: "Headlamp light distribution control system (AHB, Automatic High Beam)",
    category: "AD / ADAS / Telematics",
  },
  {
    value: "Headrest",
    label: "Headrest",
    category: "Seat & Seat Belt",
  },
  {
    value: "Heat insulator",
    label: "Heat insulator",
    category: "General Parts",
  },
  {
    value: "Heat pump system",
    label: "Heat pump system",
    category: "Climate Control",
  },
  {
    value: "Heat pump system parts",
    label: "Heat pump system parts",
    category: "Climate Control",
  },
  {
    value: "Heat treatment",
    label: "Heat treatment",
    category: "Process type (Stamping, Plastic injection molding etc.)",
  },
  {
    value: "Heater control unit",
    label: "Heater control unit",
    category: "Climate Control",
  },
  {
    value: "Heater core",
    label: "Heater core",
    category: "Climate Control",
  },
  {
    value: "Heater fan motor",
    label: "Heater fan motor",
    category: "Motor",
  },
  {
    value: "Heater hose",
    label: "Heater hose",
    category: "Climate Control",
  },
  {
    value: "Heater module",
    label: "Heater module",
    category: "Climate Control",
  },
  {
    value: "Heater parts",
    label: "Heater parts",
    category: "Climate Control",
  },
  {
    value: "Heater solenoid valve",
    label: "Heater solenoid valve",
    category: "Climate Control",
  },
  {
    value: "Height sensor",
    label: "Height sensor",
    category: "Sensor",
  },
  {
    value: "High mounted stop lamp",
    label: "High mounted stop lamp",
    category: "Lighting ",
  },
  {
    value: "High pressure pipe",
    label: "High pressure pipe",
    category: "Fuel Supply System",
  },
  {
    value: "High pressure sensor",
    label: "High pressure sensor",
    category: "Sensor",
  },
  {
    value: "High tension cable",
    label: "High tension cable",
    category: "Ignition System",
  },
  {
    value: "Hood",
    label: "Hood",
    category: "Body Parts / Frame",
  },
  {
    value: "Hood hinges",
    label: "Hood hinges",
    category: "Body Parts / Frame",
  },
  {
    value: "Hood lock",
    label: "Hood lock",
    category: "Body Parts / Frame",
  },
  {
    value: "Hood panel parts",
    label: "Hood panel parts",
    category: "Body Parts / Frame",
  },
  {
    value: "Hood switch",
    label: "Hood switch",
    category: "Hiddin Switch",
  },
  {
    value: "Horn switch",
    label: "Horn switch",
    category: "Hiddin Switch",
  },
  {
    value: "Hose",
    label: "Hose",
    category: "General Parts",
  },
  {
    value: "Hot forging",
    label: "Hot forging",
    category: "Process type (Stamping, Plastic injection molding etc.)",
  },
  {
    value: "Hydroforming",
    label: "Hydroforming",
    category: "Process type (Stamping, Plastic injection molding etc.)",
  },
  {
    value: "Hydrogen tank",
    label: "Hydrogen tank",
    category: "Fuel Cell System",
  },
  {
    value: "IC",
    label: "IC",
    category: "Semiconductor Device",
  },
  {
    value: "IC regulator",
    label: "IC regulator",
    category: "Engine Electrical Parts",
  },
  {
    value: "Ice Scrapers, Demisters & Squeegees",
    label: "Ice Scrapers, Demisters & Squeegees",
    category: "Vehicle Care ",
  },
  {
    value: "Idle speed control motor",
    label: "Idle speed control motor",
    category: "Motor",
  },
  {
    value: "Idle speed control valve",
    label: "Idle speed control valve",
    category: "Fuel Supply System",
  },
  {
    value: "Idler",
    label: "Idler",
    category: "Valvetrain",
  },
  {
    value: "Idler arm",
    label: "Idler arm",
    category: "Steering",
  },
  {
    value: "Igniter",
    label: "Igniter",
    category: "Ignition System",
  },
  {
    value: "Ignition Parts",
    label: "Ignition Parts",
    category: "Ignition System",
  },
  {
    value: "Ignition coil",
    label: "Ignition coil",
    category: "Ignition System",
  },
  {
    value: "Ignition module",
    label: "Ignition module",
    category: "Ignition System",
  },
  {
    value: "Ignition switch",
    label: "Ignition switch",
    category: "Engine Electrical Parts",
  },
  {
    value: "Ignition system",
    label: "Ignition system",
    category: "Ignition System",
  },
  {
    value: "Immobilizer",
    label: "Immobilizer",
    category: "Security I Entertainment",
  },
  {
    value: "In-wheel motor",
    label: "In-wheel motor",
    category: "Electric powertrain system parts",
  },
  {
    value: "In-wheel motor",
    label: "In-wheel motor",
    category: "Driver motor",
  },
  {
    value: "In-wheel motor parts",
    label: "In-wheel motor parts",
    category: "Electric powertrain system parts",
  },
  {
    value: "In-wheel motor parts",
    label: "In-wheel motor parts",
    category: "Driver motor",
  },
  {
    value: "Indicator lamp",
    label: "Indicator lamp",
    category: "Instrument Panel ",
  },
  {
    value: "Inflator",
    label: "Inflator",
    category: "Airbag",
  },
  {
    value: "Inhibitor switch",
    label: "Inhibitor switch",
    category: "Hiddin Switch",
  },
  {
    value: "Injector nozzle",
    label: "Injector nozzle",
    category: "Fuel Supply System",
  },
  {
    value: "Input shaft (manual)",
    label: "Input shaft (manual)",
    category: "Transmission & Parts",
  },
  {
    value: "Insert molding",
    label: "Insert molding",
    category: "Process type (Stamping, Plastic injection molding etc.)",
  },
  {
    value: "Inside handle",
    label: "Inside handle",
    category: "Body Parts / Frame",
  },
  {
    value: "Inside mirror",
    label: "Inside mirror",
    category: "Interior Trim & Parts",
  },
  {
    value: "Instrument frame",
    label: "Instrument frame",
    category: "Body Parts / Frame",
  },
  {
    value: "Instrument meter",
    label: "Instrument meter",
    category: "Instrument Panel ",
  },
  {
    value: "Instrument panel",
    label: "Instrument panel",
    category: "Instrument Panel ",
  },
  {
    value: "Instrument panel assemby",
    label: "Instrument panel assemby",
    category: "Instrument Panel ",
  },
  {
    value: "Instrument panel parts",
    label: "Instrument panel parts",
    category: "Instrument Panel ",
  },
  {
    value: "Intake air pressure sensor",
    label: "Intake air pressure sensor",
    category: "Sensor",
  },
  {
    value: "Intake air temperature sensor",
    label: "Intake air temperature sensor",
    category: "Sensor",
  },
  {
    value: "Intake manifold",
    label: "Intake manifold",
    category: "Air intake & exhaust systems",
  },
  {
    value: "Intake manifold module",
    label: "Intake manifold module",
    category: "Air intake & exhaust systems",
  },
  {
    value: "Intake valve",
    label: "Intake valve",
    category: "Valvetrain",
  },
  {
    value: "Integrated thermal management system for EV",
    label: "Integrated thermal management system for EV",
    category: "EV Cooling System",
  },
  {
    value: "Interior Cleaning",
    label: "Interior Cleaning",
    category: "Vehicle Care ",
  },
  {
    value: "Interior trim",
    label: "Interior trim",
    category: "Interior Trim & Parts",
  },
  {
    value: "Inverter",
    label: "Inverter",
    category: "Electric powertrain system parts",
  },
  {
    value: "Inverter",
    label: "Inverter",
    category: "Driver motor",
  },
  {
    value: "Inverter cooling system",
    label: "Inverter cooling system",
    category: "EV Cooling System",
  },
  {
    value: "Inverter cooling system parts",
    label: "Inverter cooling system parts",
    category: "EV Cooling System",
  },
  {
    value: "Iron casting",
    label: "Iron casting",
    category: "Process type (Stamping, Plastic injection molding etc.)",
  },
  {
    value: "Jack",
    label: "Jack",
    category: "General Parts",
  },
  {
    value: "Joint",
    label: "Joint",
    category: "General Parts",
  },
  {
    value: "Junction box",
    label: "Junction box",
    category: "Electrical Parts",
  },
  {
    value: "Key set",
    label: "Key set",
    category: "Security I Entertainment",
  },
  {
    value: "Keyless entry system",
    label: "Keyless entry system",
    category: "Security I Entertainment",
  },
  {
    value: "Kids Travel Accessories",
    label: "Kids Travel Accessories",
    category: "Child Seat",
  },
  {
    value: "Kingpin / Shackle pin",
    label: "Kingpin / Shackle pin",
    category: "Suspendion",
  },
  {
    value: "Knee airbag module",
    label: "Knee airbag module",
    category: "Airbag",
  },
  {
    value: "Knee bolster",
    label: "Knee bolster",
    category: "Interior Trim & Parts",
  },
  {
    value: "Knock sensor",
    label: "Knock sensor",
    category: "Sensor",
  },
  {
    value: "Knuckle arm",
    label: "Knuckle arm",
    category: "Suspendion",
  },
  {
    value: "LCD panel (Liquid Crystal Display panel)",
    label: "LCD panel (Liquid Crystal Display panel)",
    category: "Electronic Device",
  },
  {
    value: "LCD unit",
    label: "LCD unit",
    category: "Display",
  },
  {
    value: "LED",
    label: "LED",
    category: "Semiconductor Device",
  },
  {
    value: "LPG system",
    label: "LPG system",
    category: "Fuel Supply System",
  },
  {
    value: "LPG system parts",
    label: "LPG system parts",
    category: "Fuel Supply System",
  },
  {
    value: "LSI",
    label: "LSI",
    category: "Semiconductor Device",
  },
  {
    value: "Lacquers",
    label: "Lacquers",
    category: "Paints & Body Repair",
  },
  {
    value: "Lamp parts",
    label: "Lamp parts",
    category: "Lighting ",
  },
  {
    value: "Lamp switch",
    label: "Lamp switch",
    category: "Interior Switch",
  },
  {
    value: "Lane Departure Warning System (LDWS)",
    label: "Lane Departure Warning System (LDWS)",
    category: "AD / ADAS / Telematics",
  },
  {
    value: "Lateral rod",
    label: "Lateral rod",
    category: "Suspendion",
  },
  {
    value: "Leaf spring",
    label: "Leaf spring",
    category: "Suspendion",
  },
  {
    value: "Leather / Fabric",
    label: "Leather / Fabric",
    category: "General Parts",
  },
  {
    value: "Leisure Batteries",
    label: "Leisure Batteries",
    category: "Caravan & Motorhomes",
  },
  {
    value: "License plate lamp",
    label: "License plate lamp",
    category: "Lighting ",
  },
  {
    value: "Lighting",
    label: "Lighting",
    category: "Motorcycling",
  },
  {
    value: "Lithium-ion battery / Lithium-ion battery pack",
    label: "Lithium-ion battery / Lithium-ion battery pack",
    category: "Battery & Related Parts",
  },
  {
    value: "Lithium-ion battery anode current collector",
    label: "Lithium-ion battery anode current collector",
    category: "Battery & Related Parts",
  },
  {
    value: "Lithium-ion battery cathode current collector",
    label: "Lithium-ion battery cathode current collector",
    category: "Battery & Related Parts",
  },
  {
    value: "Lithium-ion battery cell",
    label: "Lithium-ion battery cell",
    category: "Battery & Related Parts",
  },
  {
    value: "Lithium-ion battery electrolyte",
    label: "Lithium-ion battery electrolyte",
    category: "Battery & Related Parts",
  },
  {
    value: "Lithium-ion battery module",
    label: "Lithium-ion battery module",
    category: "Battery & Related Parts",
  },
  {
    value: "Lithium-ion battery separator",
    label: "Lithium-ion battery separator",
    category: "Battery & Related Parts",
  },
  {
    value: "Load sensor",
    label: "Load sensor",
    category: "Sensor",
  },
  {
    value: "Lock Up Mechanism",
    label: "Lock Up Mechanism",
    category: "Transmission & Parts",
  },
  {
    value: "Lock Up Mechanism Parts",
    label: "Lock Up Mechanism Parts",
    category: "Transmission & Parts",
  },
  {
    value: "Lower arm",
    label: "Lower arm",
    category: "Suspendion",
  },
  {
    value: "Luggage / Trunk trim",
    label: "Luggage / Trunk trim",
    category: "Interior Trim & Parts",
  },
  {
    value: "Lumbar adjustor",
    label: "Lumbar adjustor",
    category: "Seat & Seat Belt",
  },
  {
    value: "Lumbar support adjuster motor",
    label: "Lumbar support adjuster motor",
    category: "Motor",
  },
  {
    value: "MAP (Manifold Absolute Pressure) sensor",
    label: "MAP (Manifold Absolute Pressure) sensor",
    category: "Sensor",
  },
  {
    value: "Magnesium die casting",
    label: "Magnesium die casting",
    category: "Process type (Stamping, Plastic injection molding etc.)",
  },
  {
    value: "Magnet",
    label: "Magnet",
    category: "Electronic Device",
  },
  {
    value: "Magnet switch",
    label: "Magnet switch",
    category: "Hiddin Switch",
  },
  {
    value: "Manual transmission assembly",
    label: "Manual transmission assembly",
    category: "Transmission & Parts",
  },
  {
    value: "Memory (DRAM / SRAM / etc.)",
    label: "Memory (DRAM / SRAM / etc.)",
    category: "Semiconductor Device",
  },
  {
    value: "Micro SD Cards",
    label: "Micro SD Cards",
    category: "Security I Entertainment",
  },
  {
    value: "Microfibre Cloths",
    label: "Microfibre Cloths",
    category: "Vehicle Care ",
  },
  {
    value: "Microphone",
    label: "Microphone",
    category: "Electrical Parts",
  },
  {
    value: "Mirror control switch",
    label: "Mirror control switch",
    category: "Interior Switch",
  },
  {
    value: "Mirror heater",
    label: "Mirror heater",
    category: "Outside Mirror",
  },
  {
    value: "Mirrors & Accessories",
    label: "Mirrors & Accessories",
    category: "Motorcycling",
  },
  {
    value: "Mobile Phone Accessories",
    label: "Mobile Phone Accessories",
    category: "Security I Entertainment",
  },
  {
    value: "Molding (exterior)",
    label: "Molding (exterior)",
    category: "Exterior Parts",
  },
  {
    value: "Motor core for drive motor",
    label: "Motor core for drive motor",
    category: "Driver motor",
  },
  {
    value: "Motorcycle Accessories",
    label: "Motorcycle Accessories",
    category: "Motorcycling",
  },
  {
    value: "Motorcycle Batteries",
    label: "Motorcycle Batteries",
    category: "Motorcycling",
  },
  {
    value: "Motorcycle Battery Chargers & Accessories",
    label: "Motorcycle Battery Chargers & Accessories",
    category: "Motorcycling",
  },
  {
    value: "Motorcycle Bulbs",
    label: "Motorcycle Bulbs",
    category: "Motorcycling",
  },
  {
    value: "Motorcycle Cleaning Products",
    label: "Motorcycle Cleaning Products",
    category: "Motorcycling",
  },
  {
    value: "Motorcycle Clothing",
    label: "Motorcycle Clothing",
    category: "Motorcycling",
  },
  {
    value: "Motorcycle Covers",
    label: "Motorcycle Covers",
    category: "Motorcycling",
  },
  {
    value: "Motorcycle Helmets",
    label: "Motorcycle Helmets",
    category: "Motorcycling",
  },
  {
    value: "Motorcycle Oils",
    label: "Motorcycle Oils",
    category: "Motorcycling",
  },
  {
    value: "Motorcycle Parts & Manuals",
    label: "Motorcycle Parts & Manuals",
    category: "Motorcycling",
  },
  {
    value: "Motorcycle Security",
    label: "Motorcycle Security",
    category: "Motorcycling",
  },
  {
    value: "Motorcycle Technology ",
    label: "Motorcycle Technology ",
    category: "Motorcycling",
  },
  {
    value: "Motorcycle Tools",
    label: "Motorcycle Tools",
    category: "Motorcycling",
  },
  {
    value: "Mudguard",
    label: "Mudguard",
    category: "Body Parts / Frame",
  },
  {
    value: "Muffler",
    label: "Muffler",
    category: "Air intake & exhaust systems",
  },
  {
    value: "Multiple LSD",
    label: "Multiple LSD",
    category: "Differential",
  },
  {
    value: "NOx sensor",
    label: "NOx sensor",
    category: "Sensor",
  },
  {
    value: "Nickel metal hydride battery / Nickel metal hydride battery pack",
    label: "Nickel metal hydride battery / Nickel metal hydride battery pack",
    category: "Battery & Related Parts",
  },
  {
    value: "Nickel metal hydride battery cell",
    label: "Nickel metal hydride battery cell",
    category: "Battery & Related Parts",
  },
  {
    value: "Nickel metal hydride battery current collector",
    label: "Nickel metal hydride battery current collector",
    category: "Battery & Related Parts",
  },
  {
    value: "Nickel metal hydride battery module",
    label: "Nickel metal hydride battery module",
    category: "Battery & Related Parts",
  },
  {
    value: "Nickel metal hydride battery separator",
    label: "Nickel metal hydride battery separator",
    category: "Battery & Related Parts",
  },
  {
    value: "Night vision",
    label: "Night vision",
    category: "AD / ADAS / Telematics",
  },
  {
    value: "Nonwoven cloth",
    label: "Nonwoven cloth",
    category: "General Parts",
  },
  {
    value: "Nursery & Homes",
    label: "Nursery & Homes",
    category: "Child Seat",
  },
  {
    value: "O-ring",
    label: "O-ring",
    category: "General Parts",
  },
  {
    value: "Odometer stepping motor",
    label: "Odometer stepping motor",
    category: "Motor",
  },
  {
    value: "Oil condition sensor",
    label: "Oil condition sensor",
    category: "Sensor",
  },
  {
    value: "Oil cooler",
    label: "Oil cooler",
    category: "Engine Lubrication Components",
  },
  {
    value: "Oil filter",
    label: "Oil filter",
    category: "Engine Lubrication Components",
  },
  {
    value: "Oil level gauge",
    label: "Oil level gauge",
    category: "Engine Lubrication Components",
  },
  {
    value: "Oil level sensor",
    label: "Oil level sensor",
    category: "Sensor",
  },
  {
    value: "Oil pan",
    label: "Oil pan",
    category: "Engine Structural Parts",
  },
  {
    value: "Oil pressure sensor",
    label: "Oil pressure sensor",
    category: "Sensor",
  },
  {
    value: "Oil pressure switch",
    label: "Oil pressure switch",
    category: "Hiddin Switch",
  },
  {
    value: "Oil pump",
    label: "Oil pump",
    category: "Engine Lubrication Components",
  },
  {
    value: "Oil seal",
    label: "Oil seal",
    category: "Engine Lubrication Components",
  },
  {
    value: "Oil strainer",
    label: "Oil strainer",
    category: "Engine Lubrication Components",
  },
  {
    value: "On-Board Diagnostics (OBD)",
    label: "On-Board Diagnostics (OBD)",
    category: "Engine Electrical Parts",
  },
  {
    value: "On-board battery charger",
    label: "On-board battery charger",
    category: "Battery & Related Parts",
  },
  {
    value: "On-glass antenna",
    label: "On-glass antenna",
    category: "AD / ADAS / Telematics",
  },
  {
    value: "Optical fiber",
    label: "Optical fiber",
    category: "Electrical Parts",
  },
  {
    value: "Oscillation element",
    label: "Oscillation element",
    category: "Electronic Device",
  },
  {
    value: "Other Driving Support System ECU",
    label: "Other Driving Support System ECU",
    category: "Electrical / Electronic Parts - ECU",
  },
  {
    value: "Other LSD, LSD parts",
    label: "Other LSD, LSD parts",
    category: "Differential",
  },
  {
    value: "Other air / fuel management parts",
    label: "Other air / fuel management parts",
    category: "Air intake & exhaust systems",
  },
  {
    value: "Other airbag parts",
    label: "Other airbag parts",
    category: "Airbag",
  },
  {
    value: "Other antennas, Antenna parts",
    label: "Other antennas, Antenna parts",
    category: "AD / ADAS / Telematics",
  },
  {
    value: "Other automatic transmission parts",
    label: "Other automatic transmission parts",
    category: "Transmission & Parts",
  },
  {
    value: "Other battery related parts",
    label: "Other battery related parts",
    category: "Battery & Related Parts",
  },
  {
    value: "Other body panel / frame parts",
    label: "Other body panel / frame parts",
    category: "Body Parts / Frame",
  },
  {
    value: "Other body reinforcement and protector parts",
    label: "Other body reinforcement and protector parts",
    category: "Body Parts / Frame",
  },
  {
    value: "Other brake parts",
    label: "Other brake parts",
    category: "Brake & Pedal",
  },
  {
    value: "Other brake related ECU",
    label: "Other brake related ECU",
    category: "Electrical / Electronic Parts - ECU",
  },
  {
    value: "Other climate control parts",
    label: "Other climate control parts",
    category: "Climate Control",
  },
  {
    value: "Other clutch parts",
    label: "Other clutch parts",
    category: "Clutch",
  },
  {
    value: "Other differential parts",
    label: "Other differential parts",
    category: "Differential",
  },
  {
    value: "Other door parts",
    label: "Other door parts",
    category: "Body Parts / Frame",
  },
  {
    value: "Other drive motor parts",
    label: "Other drive motor parts",
    category: "Driver motor",
  },
  {
    value: "Other electric powertrain system parts",
    label: "Other electric powertrain system parts",
    category: "Electric powertrain system parts",
  },
  {
    value: "Other electric powertrain system parts",
    label: "Other electric powertrain system parts",
    category: "Driver motor",
  },
  {
    value: "Other electrical / electronic parts",
    label: "Other electrical / electronic parts",
    category: "Electrical Parts",
  },
  {
    value: "Other electronic device",
    label: "Other electronic device",
    category: "Electronic Device",
  },
  {
    value: "Other engine cooling parts",
    label: "Other engine cooling parts",
    category: "Engine Cooling System",
  },
  {
    value: "Other engine electrical parts",
    label: "Other engine electrical parts",
    category: "Engine Electrical Parts",
  },
  {
    value: "Other engine lubrication parts",
    label: "Other engine lubrication parts",
    category: "Engine Lubrication Components",
  },
  {
    value: "Other engine structural parts",
    label: "Other engine structural parts",
    category: "Engine Structural Parts",
  },
  {
    value: "Other exhaust parts",
    label: "Other exhaust parts",
    category: "Air intake & exhaust systems",
  },
  {
    value: "Other exterior parts",
    label: "Other exterior parts",
    category: "Exterior Parts",
  },
  {
    value: "Other general parts",
    label: "Other general parts",
    category: "General Parts",
  },
  {
    value: "Other hood parts",
    label: "Other hood parts",
    category: "Body Parts / Frame",
  },
  {
    value: "Other insulators",
    label: "Other insulators",
    category: "General Parts",
  },
  {
    value: "Other interior parts",
    label: "Other interior parts",
    category: "Interior Trim & Parts",
  },
  {
    value: "Other manual transmission parts",
    label: "Other manual transmission parts",
    category: "Transmission & Parts",
  },
  {
    value: "Other meters parts",
    label: "Other meters parts",
    category: "Instrument Panel ",
  },
  {
    value: "Other power semidonductor devices",
    label: "Other power semidonductor devices",
    category: "Semiconductor Device",
  },
  {
    value: "Other seat belt parts",
    label: "Other seat belt parts",
    category: "Seat & Seat Belt",
  },
  {
    value: "Other seat parts",
    label: "Other seat parts",
    category: "Seat & Seat Belt",
  },
  {
    value: "Other steering parts",
    label: "Other steering parts",
    category: "Steering",
  },
  {
    value: "Other sunroof, sunroof parts",
    label: "Other sunroof, sunroof parts",
    category: "Body Parts / Frame",
  },
  {
    value: "Other suspension parts",
    label: "Other suspension parts",
    category: "Suspendion",
  },
  {
    value: "Other transmission parts",
    label: "Other transmission parts",
    category: "Transmission & Parts",
  },
  {
    value: "Other turbo / supercharger parts",
    label: "Other turbo / supercharger parts",
    category: "Turbochargers / Superchargers",
  },
  {
    value: "Other valvetrain parts",
    label: "Other valvetrain parts",
    category: "Valvetrain",
  },
  {
    value: "Other ventilator parts",
    label: "Other ventilator parts",
    category: "Climate Control",
  },
  {
    value: "Output shaft",
    label: "Output shaft",
    category: "Transmission & Parts",
  },
  {
    value: "Outside handle",
    label: "Outside handle",
    category: "Body Parts / Frame",
  },
  {
    value: "Outside mirror",
    label: "Outside mirror",
    category: "Outside Mirror",
  },
  {
    value: "Outside mirror parts",
    label: "Outside mirror parts",
    category: "Outside Mirror",
  },
  {
    value: "Outside rearview mirror adjusting motor",
    label: "Outside rearview mirror adjusting motor",
    category: "Motor",
  },
  {
    value: "Oxygen (O2) sensor",
    label: "Oxygen (O2) sensor",
    category: "Sensor",
  },
  {
    value: "PCU (Power Control Unit)",
    label: "PCU (Power Control Unit)",
    category: "Electric powertrain system parts",
  },
  {
    value: "PCU (Power Control Unit)",
    label: "PCU (Power Control Unit)",
    category: "Driver motor",
  },
  {
    value: "PCU cooling system",
    label: "PCU cooling system",
    category: "EV Cooling System",
  },
  {
    value: "PCU cooling system parts",
    label: "PCU cooling system parts",
    category: "EV Cooling System",
  },
  {
    value: "PCU parts",
    label: "PCU parts",
    category: "Electric powertrain system parts",
  },
  {
    value: "PCU parts",
    label: "PCU parts",
    category: "Driver motor",
  },
  {
    value: "PTC heater",
    label: "PTC heater",
    category: "Climate Control",
  },
  {
    value: "PTC heater parts",
    label: "PTC heater parts",
    category: "Climate Control",
  },
  {
    value: "Paint",
    label: "Paint",
    category: "General Parts",
  },
  {
    value: "Paint coating",
    label: "Paint coating",
    category: "Process type (Stamping, Plastic injection molding etc.)",
  },
  {
    value: "Panel switch",
    label: "Panel switch",
    category: "Interior Switch",
  },
  {
    value: "Parking Sensors",
    label: "Parking Sensors",
    category: "Security I Entertainment",
  },
  {
    value: "Parking assist",
    label: "Parking assist",
    category: "AD / ADAS / Telematics",
  },
  {
    value: "Parking brake",
    label: "Parking brake",
    category: "Brake & Pedal",
  },
  {
    value: "Parking brake",
    label: "Parking brake",
    category: "Brake & Pedal",
  },
  {
    value: "Parking brake parts",
    label: "Parking brake parts",
    category: "Brake & Pedal",
  },
  {
    value: "Parking brake parts",
    label: "Parking brake parts",
    category: "Brake & Pedal",
  },
  {
    value: "Parking lamp",
    label: "Parking lamp",
    category: "Lighting ",
  },
  {
    value: "Parts for drive motor resolver (angle sensor)",
    label: "Parts for drive motor resolver (angle sensor)",
    category: "Driver motor",
  },
  {
    value: "Passenger airbag module",
    label: "Passenger airbag module",
    category: "Airbag",
  },
  {
    value: "Pedal",
    label: "Pedal",
    category: "Brake & Pedal",
  },
  {
    value: "Pedal parts",
    label: "Pedal parts",
    category: "Brake & Pedal",
  },
  {
    value: "Peripheral engine parts",
    label: "Peripheral engine parts",
    category: "Peripheral Engine Parts",
  },
  {
    value: "Permanent magnets for drive motor",
    label: "Permanent magnets for drive motor",
    category: "Driver motor",
  },
  {
    value: "Piezoresistive device",
    label: "Piezoresistive device",
    category: "Electronic Device",
  },
  {
    value: "Pillar cover",
    label: "Pillar cover",
    category: "Interior Trim & Parts",
  },
  {
    value: "Pin",
    label: "Pin",
    category: "General Parts",
  },
  {
    value: "Pinion gear",
    label: "Pinion gear",
    category: "Transmission & Parts",
  },
  {
    value: "Pipe",
    label: "Pipe",
    category: "General Parts",
  },
  {
    value: "Piston",
    label: "Piston",
    category: "Engine Structural Parts",
  },
  {
    value: "Piston pin",
    label: "Piston pin",
    category: "Engine Structural Parts",
  },
  {
    value: "Piston ring",
    label: "Piston ring",
    category: "Engine Structural Parts",
  },
  {
    value: "Pitman arm",
    label: "Pitman arm",
    category: "Steering",
  },
  {
    value: "Planetary gear",
    label: "Planetary gear",
    category: "Transmission & Parts",
  },
  {
    value: "Plastic extrusion processing",
    label: "Plastic extrusion processing",
    category: "Process type (Stamping, Plastic injection molding etc.)",
  },
  {
    value: "Plastic injection molding",
    label: "Plastic injection molding",
    category: "Process type (Stamping, Plastic injection molding etc.)",
  },
  {
    value: "Plating",
    label: "Plating",
    category: "Process type (Stamping, Plastic injection molding etc.)",
  },
  {
    value: "Plug",
    label: "Plug",
    category: "General Parts",
  },
  {
    value: "Polishers & Accessories",
    label: "Polishers & Accessories",
    category: "Vehicle Care ",
  },
  {
    value: "Power seat motor",
    label: "Power seat motor",
    category: "Motor",
  },
  {
    value: "Power seat switch",
    label: "Power seat switch",
    category: "Interior Switch",
  },
  {
    value: "Power semidonductor <IGBT Device>",
    label: "Power semidonductor <IGBT Device>",
    category: "Semiconductor Device",
  },
  {
    value: "Power semidonductor <SiC Device>",
    label: "Power semidonductor <SiC Device>",
    category: "Semiconductor Device",
  },
  {
    value: "Power steering",
    label: "Power steering",
    category: "Steering",
  },
  {
    value: "Power steering motor",
    label: "Power steering motor",
    category: "Motor",
  },
  {
    value: "Power steering pump",
    label: "Power steering pump",
    category: "Steering",
  },
  {
    value: "Power steering reservoir tank",
    label: "Power steering reservoir tank",
    category: "Steering",
  },
  {
    value: "Power window",
    label: "Power window",
    category: "Body Parts / Frame",
  },
  {
    value: "Power window motor",
    label: "Power window motor",
    category: "Motor",
  },
  {
    value: "Power window switch",
    label: "Power window switch",
    category: "Interior Switch",
  },
  {
    value: "Preheater",
    label: "Preheater",
    category: "Climate Control",
  },
  {
    value: "Pressure Washer Accessories",
    label: "Pressure Washer Accessories",
    category: "Vehicle Care ",
  },
  {
    value: "Pressure Washers",
    label: "Pressure Washers",
    category: "Vehicle Care ",
  },
  {
    value: "Pressure regulator",
    label: "Pressure regulator",
    category: "Fuel Supply System",
  },
  {
    value: "Primer",
    label: "Primer",
    category: "Paints & Body Repair",
  },
  {
    value: "Printed circuit board",
    label: "Printed circuit board",
    category: "Electrical Parts",
  },
  {
    value: "Propeller shaft",
    label: "Propeller shaft",
    category: "Axle",
  },
  {
    value: "Propeller shaft center bearing",
    label: "Propeller shaft center bearing",
    category: "Axle",
  },
  {
    value: "Protection Parts",
    label: "Protection Parts",
    category: "Motorcycling",
  },
  {
    value: "Push lock switch",
    label: "Push lock switch",
    category: "Interior Switch",
  },
  {
    value: "Push rod",
    label: "Push rod",
    category: "Valvetrain",
  },
  {
    value: "Pushchairs & Prems",
    label: "Pushchairs & Prems",
    category: "Child Seat",
  },
  {
    value: "Rack & pinion",
    label: "Rack & pinion",
    category: "Steering",
  },
  {
    value: "Radiator",
    label: "Radiator",
    category: "Engine Cooling System",
  },
  {
    value: "Radiator cap",
    label: "Radiator cap",
    category: "Engine Cooling System",
  },
  {
    value: "Radiator coolant temperature sensor",
    label: "Radiator coolant temperature sensor",
    category: "Sensor",
  },
  {
    value: "Radiator fan motor",
    label: "Radiator fan motor",
    category: "Motor",
  },
  {
    value: "Radiator fan shroud",
    label: "Radiator fan shroud",
    category: "Engine Cooling System",
  },
  {
    value: "Radiator hose",
    label: "Radiator hose",
    category: "Engine Cooling System",
  },
  {
    value: "Radiator hose clip",
    label: "Radiator hose clip",
    category: "Engine Cooling System",
  },
  {
    value: "Radiator mounting bracket",
    label: "Radiator mounting bracket",
    category: "Engine Cooling System",
  },
  {
    value: "Radiator reservoir tank",
    label: "Radiator reservoir tank",
    category: "Engine Cooling System",
  },
  {
    value: "Raindrop / Light sensor",
    label: "Raindrop / Light sensor",
    category: "Sensor",
  },
  {
    value: "Rear View Monitor System (RVS)",
    label: "Rear View Monitor System (RVS)",
    category: "AD / ADAS / Telematics",
  },
  {
    value: "Rear axle",
    label: "Rear axle",
    category: "Axle",
  },
  {
    value: "Rear axle module",
    label: "Rear axle module",
    category: "Axle",
  },
  {
    value: "Rear chassis module",
    label: "Rear chassis module",
    category: "Chassis Module",
  },
  {
    value: "Rear combination lamp",
    label: "Rear combination lamp",
    category: "Lighting ",
  },
  {
    value: "Rear defogger switch",
    label: "Rear defogger switch",
    category: "Interior Switch",
  },
  {
    value: "Rear end module",
    label: "Rear end module",
    category: "Body Parts / Frame",
  },
  {
    value: "Rear parcel shelf",
    label: "Rear parcel shelf",
    category: "Interior Trim & Parts",
  },
  {
    value: "Rear suspension module",
    label: "Rear suspension module",
    category: "Chassis Module",
  },
  {
    value: "Rear wiper motor",
    label: "Rear wiper motor",
    category: "Motor",
  },
  {
    value: "Reflex reflector",
    label: "Reflex reflector",
    category: "Lighting ",
  },
  {
    value: "Refrigerant tank",
    label: "Refrigerant tank",
    category: "Climate Control",
  },
  {
    value: "Refrigerator",
    label: "Refrigerator",
    category: "Climate Control",
  },
  {
    value: "Relay",
    label: "Relay",
    category: "Electrical Parts",
  },
  {
    value: "Resistor",
    label: "Resistor",
    category: "Electronic Device",
  },
  {
    value: "Resonator",
    label: "Resonator",
    category: "Air intake & exhaust systems",
  },
  {
    value: "Reverse lamp switch",
    label: "Reverse lamp switch",
    category: "Hiddin Switch",
  },
  {
    value: "Reversing Cameras",
    label: "Reversing Cameras",
    category: "Security I Entertainment",
  },
  {
    value: "Road wheel parts",
    label: "Road wheel parts",
    category: "Tyre & Wheel",
  },
  {
    value: "Rocker arm",
    label: "Rocker arm",
    category: "Valvetrain",
  },
  {
    value: "Rocker shaft",
    label: "Rocker shaft",
    category: "Valvetrain",
  },
  {
    value: "Rod antenna",
    label: "Rod antenna",
    category: "AD / ADAS / Telematics",
  },
  {
    value: "Roll forming",
    label: "Roll forming",
    category: "Process type (Stamping, Plastic injection molding etc.)",
  },
  {
    value: "Roof Bars & Roof Boxes",
    label: "Roof Bars & Roof Boxes",
    category: "Camping ",
  },
  {
    value: "Roof Bars & Roof Boxes",
    label: "Roof Bars & Roof Boxes",
    category: "Caravan & Motorhomes",
  },
  {
    value: "Roof module",
    label: "Roof module",
    category: "Interior Trim & Parts",
  },
  {
    value: "Roof panel parts",
    label: "Roof panel parts",
    category: "Body Parts / Frame",
  },
  {
    value: "Roof rail",
    label: "Roof rail",
    category: "Exterior Parts",
  },
  {
    value: "Roof trim",
    label: "Roof trim",
    category: "Interior Trim & Parts",
  },
  {
    value: "Roof trim parts",
    label: "Roof trim parts",
    category: "Interior Trim & Parts",
  },
  {
    value: "Rook Bike Racks",
    label: "Rook Bike Racks",
    category: "Camping ",
  },
  {
    value: "Rook Bike Racks",
    label: "Rook Bike Racks",
    category: "Caravan & Motorhomes",
  },
  {
    value: "Room lamp",
    label: "Room lamp",
    category: "Interior Trim & Parts",
  },
  {
    value: "Room temperature sensor",
    label: "Room temperature sensor",
    category: "Sensor",
  },
  {
    value: "Rotor for drive motor",
    label: "Rotor for drive motor",
    category: "Driver motor",
  },
  {
    value: "Rotor parts for drive motor",
    label: "Rotor parts for drive motor",
    category: "Driver motor",
  },
  {
    value: "Rubber engine mount",
    label: "Rubber engine mount",
    category: "Engine Structural Parts",
  },
  {
    value: "Rubber extrusion processing",
    label: "Rubber extrusion processing",
    category: "Process type (Stamping, Plastic injection molding etc.)",
  },
  {
    value: "Rubber injection molding",
    label: "Rubber injection molding",
    category: "Process type (Stamping, Plastic injection molding etc.)",
  },
  {
    value: "Rubber radiator mount",
    label: "Rubber radiator mount",
    category: "Engine Cooling System",
  },
  {
    value: "Rubber suspension mount",
    label: "Rubber suspension mount",
    category: "Suspendion",
  },
  {
    value: "Rubber-metal processing",
    label: "Rubber-metal processing",
    category: "Process type (Stamping, Plastic injection molding etc.)",
  },
  {
    value: "Rust Removal & Treatment",
    label: "Rust Removal & Treatment",
    category: "Paints & Body Repair",
  },
  {
    value: "Scratch Repairs & Paint Restorers",
    label: "Scratch Repairs & Paint Restorers",
    category: "Paints & Body Repair",
  },
  {
    value: "Scratch Romover",
    label: "Scratch Romover",
    category: "Vehicle Care ",
  },
  {
    value: "Seal",
    label: "Seal",
    category: "General Parts",
  },
  {
    value: "Seat",
    label: "Seat",
    category: "Seat & Seat Belt",
  },
  {
    value: "Seat Belts & Parts",
    label: "Seat Belts & Parts",
    category: "Security I Entertainment",
  },
  {
    value: "Seat adjustor",
    label: "Seat adjustor",
    category: "Seat & Seat Belt",
  },
  {
    value: "Seat belt",
    label: "Seat belt",
    category: "Seat & Seat Belt",
  },
  {
    value: "Seat belt adjustor",
    label: "Seat belt adjustor",
    category: "Seat & Seat Belt",
  },
  {
    value: "Seat belt buckle",
    label: "Seat belt buckle",
    category: "Seat & Seat Belt",
  },
  {
    value: "Seat belt retractor",
    label: "Seat belt retractor",
    category: "Seat & Seat Belt",
  },
  {
    value: "Seat belt webbing",
    label: "Seat belt webbing",
    category: "Seat & Seat Belt",
  },
  {
    value: "Seat cushion / Seat back",
    label: "Seat cushion / Seat back",
    category: "Seat & Seat Belt",
  },
  {
    value: "Seat fabric",
    label: "Seat fabric",
    category: "Seat & Seat Belt",
  },
  {
    value: "Seat frame",
    label: "Seat frame",
    category: "Seat & Seat Belt",
  },
  {
    value: "Seat metal parts",
    label: "Seat metal parts",
    category: "Seat & Seat Belt",
  },
  {
    value: "Seat plastic parts",
    label: "Seat plastic parts",
    category: "Seat & Seat Belt",
  },
  {
    value: "Seat reclining device",
    label: "Seat reclining device",
    category: "Seat & Seat Belt",
  },
  {
    value: "Seat slide",
    label: "Seat slide",
    category: "Seat & Seat Belt",
  },
  {
    value: "Seats",
    label: "Seats",
    category: "Motorcycling",
  },
  {
    value: "Security system",
    label: "Security system",
    category: "Security I Entertainment",
  },
  {
    value: "Shaft",
    label: "Shaft",
    category: "General Parts",
  },
  {
    value: "Shift fork",
    label: "Shift fork",
    category: "Transmission & Parts",
  },
  {
    value: "Shift knob",
    label: "Shift knob",
    category: "Transmission & Parts",
  },
  {
    value: "Shift lever",
    label: "Shift lever",
    category: "Transmission & Parts",
  },
  {
    value: "Shift lever parts",
    label: "Shift lever parts",
    category: "Transmission & Parts",
  },
  {
    value: "Shock absorber",
    label: "Shock absorber",
    category: "Suspendion",
  },
  {
    value: "Shock absorber module",
    label: "Shock absorber module",
    category: "Suspendion",
  },
  {
    value: "Shock absorber parts",
    label: "Shock absorber parts",
    category: "Suspendion",
  },
  {
    value: "Side airbag",
    label: "Side airbag",
    category: "Airbag",
  },
  {
    value: "Side bolster",
    label: "Side bolster",
    category: "Seat & Seat Belt",
  },
  {
    value: "Side impact beam",
    label: "Side impact beam",
    category: "Body Parts / Frame",
  },
  {
    value: "Sintering",
    label: "Sintering",
    category: "Process type (Stamping, Plastic injection molding etc.)",
  },
  {
    value: "Sleeping Bags",
    label: "Sleeping Bags",
    category: "Camping ",
  },
  {
    value: "Sleeping Bags",
    label: "Sleeping Bags",
    category: "Caravan & Motorhomes",
  },
  {
    value: "Sleeves",
    label: "Sleeves",
    category: "Transmission & Parts",
  },
  {
    value: "Sliding roof",
    label: "Sliding roof",
    category: "Body Parts / Frame",
  },
  {
    value: "Snow Chains & Socks",
    label: "Snow Chains & Socks",
    category: "Vehicle Care ",
  },
  {
    value: "Snow Foam",
    label: "Snow Foam",
    category: "Vehicle Care ",
  },
  {
    value: "Snow Shovels",
    label: "Snow Shovels",
    category: "Vehicle Care ",
  },
  {
    value: "Socket",
    label: "Socket",
    category: "Electrical Parts",
  },
  {
    value: "Solar sensor",
    label: "Solar sensor",
    category: "Sensor",
  },
  {
    value: "Solenoid / Solenoid valve",
    label: "Solenoid / Solenoid valve",
    category: "Electrical Parts",
  },
  {
    value: "Space frame",
    label: "Space frame",
    category: "Body Parts / Frame",
  },
  {
    value: "Spark plug",
    label: "Spark plug",
    category: "Ignition System",
  },
  {
    value: "Speaker",
    label: "Speaker",
    category: "AD / ADAS / Telematics",
  },
  {
    value: "Specialist & Decorative Paints",
    label: "Specialist & Decorative Paints",
    category: "Paints & Body Repair",
  },
  {
    value: "Speed Camera Detectors",
    label: "Speed Camera Detectors",
    category: "Security I Entertainment",
  },
  {
    value: "Speed sensor",
    label: "Speed sensor",
    category: "Sensor",
  },
  {
    value: "Speedometer",
    label: "Speedometer",
    category: "Instrument Panel ",
  },
  {
    value: "Speedometer cable",
    label: "Speedometer cable",
    category: "Instrument Panel ",
  },
  {
    value: "Splash guard",
    label: "Splash guard",
    category: "Body Parts / Frame",
  },
  {
    value: "Spoiler",
    label: "Spoiler",
    category: "Exterior Parts",
  },
  {
    value: "Sponges, Bruches & Buckets",
    label: "Sponges, Bruches & Buckets",
    category: "Vehicle Care ",
  },
  {
    value: "Spring",
    label: "Spring",
    category: "General Parts",
  },
  {
    value: "Spring seat",
    label: "Spring seat",
    category: "Suspendion",
  },
  {
    value: "Stabilizer",
    label: "Stabilizer",
    category: "Suspendion",
  },
  {
    value: "Starter motor",
    label: "Starter motor",
    category: "Engine Electrical Parts",
  },
  {
    value: "Stator for drive motor",
    label: "Stator for drive motor",
    category: "Driver motor",
  },
  {
    value: "Stator parts for drive motor",
    label: "Stator parts for drive motor",
    category: "Driver motor",
  },
  {
    value: "Steel wheel",
    label: "Steel wheel",
    category: "Tyre & Wheel",
  },
  {
    value: "Steering Wheel Locks",
    label: "Steering Wheel Locks",
    category: "Security I Entertainment",
  },
  {
    value: "Steering angle sensor",
    label: "Steering angle sensor",
    category: "Sensor",
  },
  {
    value: "Steering column",
    label: "Steering column",
    category: "Steering",
  },
  {
    value: "Steering column cover",
    label: "Steering column cover",
    category: "Interior Trim & Parts",
  },
  {
    value: "Steering column module",
    label: "Steering column module",
    category: "Steering",
  },
  {
    value: "Steering gear",
    label: "Steering gear",
    category: "Steering",
  },
  {
    value: "Steering hose",
    label: "Steering hose",
    category: "Steering",
  },
  {
    value: "Steering joint",
    label: "Steering joint",
    category: "Steering",
  },
  {
    value: "Steering knuckle",
    label: "Steering knuckle",
    category: "Steering",
  },
  {
    value: "Steering linkage",
    label: "Steering linkage",
    category: "Steering",
  },
  {
    value: "Steering lock",
    label: "Steering lock",
    category: "Steering",
  },
  {
    value: "Steering mount",
    label: "Steering mount",
    category: "Steering",
  },
  {
    value: "Steering position motor",
    label: "Steering position motor",
    category: "Motor",
  },
  {
    value: "Steering shaft",
    label: "Steering shaft",
    category: "Steering",
  },
  {
    value: "Steering switch",
    label: "Steering switch",
    category: "Interior Switch",
  },
  {
    value: "Steering system",
    label: "Steering system",
    category: "Steering",
  },
  {
    value: "Steering wheel",
    label: "Steering wheel",
    category: "Steering",
  },
  {
    value: "Steering wheel parts",
    label: "Steering wheel parts",
    category: "Steering",
  },
  {
    value: "Stud",
    label: "Stud",
    category: "General Parts",
  },
  {
    value: "Sun gear",
    label: "Sun gear",
    category: "Transmission & Parts",
  },
  {
    value: "Sun visor",
    label: "Sun visor",
    category: "Interior Trim & Parts",
  },
  {
    value: "Sunroof motor",
    label: "Sunroof motor",
    category: "Motor",
  },
  {
    value: "Sunroof switch",
    label: "Sunroof switch",
    category: "Interior Switch",
  },
  {
    value: "Super capacitor",
    label: "Super capacitor",
    category: "Battery & Related Parts",
  },
  {
    value: "Supercharger",
    label: "Supercharger",
    category: "Turbochargers / Superchargers",
  },
  {
    value: "Supercharger intercooler",
    label: "Supercharger intercooler",
    category: "Turbochargers / Superchargers",
  },
  {
    value: "Surface treatment agent",
    label: "Surface treatment agent",
    category: "General Parts",
  },
  {
    value: "Surround view monitor",
    label: "Surround view monitor",
    category: "AD / ADAS / Telematics",
  },
  {
    value: "Suspension",
    label: "Suspension",
    category: "Motorcycling",
  },
  {
    value: "Suspension ball joint",
    label: "Suspension ball joint",
    category: "Suspendion",
  },
  {
    value: "Suspension control",
    label: "Suspension control",
    category: "Suspendion",
  },
  {
    value: "Suspension control ECU",
    label: "Suspension control ECU",
    category: "Electrical / Electronic Parts - ECU",
  },
  {
    value: "Suspension cross member",
    label: "Suspension cross member",
    category: "Suspendion",
  },
  {
    value: "Suspension leveling motor",
    label: "Suspension leveling motor",
    category: "Motor",
  },
  {
    value: "Suspension module",
    label: "Suspension module",
    category: "Chassis Module",
  },
  {
    value: "Suspension mount bracket",
    label: "Suspension mount bracket",
    category: "Suspendion",
  },
  {
    value: "Suspension rod",
    label: "Suspension rod",
    category: "Suspendion",
  },
  {
    value: "Suspension strut",
    label: "Suspension strut",
    category: "Suspendion",
  },
  {
    value: "Suspension system",
    label: "Suspension system",
    category: "Suspendion",
  },
  {
    value: "Swirl control valve",
    label: "Swirl control valve",
    category: "Fuel Supply System",
  },
  {
    value: "Synchronizer hub",
    label: "Synchronizer hub",
    category: "Transmission & Parts",
  },
  {
    value: "Synchronizer ring",
    label: "Synchronizer ring",
    category: "Transmission & Parts",
  },
  {
    value: "T-bar roof",
    label: "T-bar roof",
    category: "Body Parts / Frame",
  },
  {
    value: "TCS ECU",
    label: "TCS ECU",
    category: "Electrical / Electronic Parts - ECU",
  },
  {
    value: "TCS parts",
    label: "TCS parts",
    category: "ABS / TCS /ESC ",
  },
  {
    value: "Tachometer",
    label: "Tachometer",
    category: "Instrument Panel ",
  },
  {
    value: "Tailgate module",
    label: "Tailgate module",
    category: "Body Parts / Frame",
  },
  {
    value: "Tailored blank",
    label: "Tailored blank",
    category: "Process type (Stamping, Plastic injection molding etc.)",
  },
  {
    value: "Tape",
    label: "Tape",
    category: "General Parts",
  },
  {
    value: "Television",
    label: "Television",
    category: "AD / ADAS / Telematics",
  },
  {
    value: "Tension rod",
    label: "Tension rod",
    category: "Suspendion",
  },
  {
    value: "Tents",
    label: "Tents",
    category: "Camping ",
  },
  {
    value: "Tents",
    label: "Tents",
    category: "Caravan & Motorhomes",
  },
  {
    value: "Terminal",
    label: "Terminal",
    category: "Electrical Parts",
  },
  {
    value: "Thermal management system parts for EV",
    label: "Thermal management system parts for EV",
    category: "EV Cooling System",
  },
  {
    value: "Thermo switch",
    label: "Thermo switch",
    category: "Hiddin Switch",
  },
  {
    value: "Thermostat",
    label: "Thermostat",
    category: "Engine Cooling System",
  },
  {
    value: "Throttle body",
    label: "Throttle body",
    category: "Fuel Supply System",
  },
  {
    value: "Throttle body motor",
    label: "Throttle body motor",
    category: "Motor",
  },
  {
    value: "Throttle position sensor",
    label: "Throttle position sensor",
    category: "Sensor",
  },
  {
    value: "Throttle valve",
    label: "Throttle valve",
    category: "Fuel Supply System",
  },
  {
    value: "Tie rod",
    label: "Tie rod",
    category: "Steering",
  },
  {
    value: "Tie rod end",
    label: "Tie rod end",
    category: "Steering",
  },
  {
    value: "Tilt steering",
    label: "Tilt steering",
    category: "Steering",
  },
  {
    value: "Timing chain",
    label: "Timing chain",
    category: "Valvetrain",
  },
  {
    value: "Timing gear",
    label: "Timing gear",
    category: "Valvetrain",
  },
  {
    value: "Tire",
    label: "Tire",
    category: "Tyre & Wheel",
  },
  {
    value: "Tire Pressure Monitoring System (TPMS)",
    label: "Tire Pressure Monitoring System (TPMS)",
    category: "AD / ADAS / Telematics",
  },
  {
    value: "Tire carrier",
    label: "Tire carrier",
    category: "Exterior Parts",
  },
  {
    value: "Tire cord",
    label: "Tire cord",
    category: "Tyre & Wheel",
  },
  {
    value: "Tire parts",
    label: "Tire parts",
    category: "Tyre & Wheel",
  },
  {
    value: "Tire valve / Valve core",
    label: "Tire valve / Valve core",
    category: "Tyre & Wheel",
  },
  {
    value: "Toddler Car Seats",
    label: "Toddler Car Seats",
    category: "Child Seat",
  },
  {
    value: "Tonneau cover",
    label: "Tonneau cover",
    category: "Interior Trim & Parts",
  },
  {
    value: "Tool set",
    label: "Tool set",
    category: "General Parts",
  },
  {
    value: "Torque converter",
    label: "Torque converter",
    category: "Transmission & Parts",
  },
  {
    value: "Torque converter parts",
    label: "Torque converter parts",
    category: "Transmission & Parts",
  },
  {
    value: "Torque sensing LSD",
    label: "Torque sensing LSD",
    category: "Differential",
  },
  {
    value: "Torsion bar",
    label: "Torsion bar",
    category: "Suspendion",
  },
  {
    value: "Torsional damper",
    label: "Torsional damper",
    category: "Engine Structural Parts",
  },
  {
    value: "Touches",
    label: "Touches",
    category: "Camping ",
  },
  {
    value: "Touches",
    label: "Touches",
    category: "Caravan & Motorhomes",
  },
  {
    value: "Traction Control System (TCS)",
    label: "Traction Control System (TCS)",
    category: "ABS / TCS /ESC ",
  },
  {
    value: "Traffic Sign Recognition (TSR)",
    label: "Traffic Sign Recognition (TSR)",
    category: "AD / ADAS / Telematics",
  },
  {
    value: "Trailers & Towing",
    label: "Trailers & Towing",
    category: "Camping ",
  },
  {
    value: "Trailers & Towing",
    label: "Trailers & Towing",
    category: "Caravan & Motorhomes",
  },
  {
    value: "Transformer",
    label: "Transformer",
    category: "Electrical Parts",
  },
  {
    value: "Transistor",
    label: "Transistor",
    category: "Semiconductor Device",
  },
  {
    value: "Transmission case",
    label: "Transmission case",
    category: "Transmission & Parts",
  },
  {
    value: "Transmission gear",
    label: "Transmission gear",
    category: "Transmission & Parts",
  },
  {
    value: "Transmission rear cover",
    label: "Transmission rear cover",
    category: "Transmission & Parts",
  },
  {
    value: "Transmission shaft (manual)",
    label: "Transmission shaft (manual)",
    category: "Transmission & Parts",
  },
  {
    value: "Travel Systems",
    label: "Travel Systems",
    category: "Child Seat",
  },
  {
    value: "Trimmer",
    label: "Trimmer",
    category: "Electronic Device",
  },
  {
    value: "Truck / Bus body panel",
    label: "Truck / Bus body panel",
    category: "Body Parts / Frame",
  },
  {
    value: "Trunk lid",
    label: "Trunk lid",
    category: "Body Parts / Frame",
  },
  {
    value: "Trunk lid (rear gate) closure",
    label: "Trunk lid (rear gate) closure",
    category: "Body Parts / Frame",
  },
  {
    value: "Trunk lid / Tailgate panel parts",
    label: "Trunk lid / Tailgate panel parts",
    category: "Body Parts / Frame",
  },
  {
    value: "Trunk lid hinge",
    label: "Trunk lid hinge",
    category: "Body Parts / Frame",
  },
  {
    value: "Trunk lid lock",
    label: "Trunk lid lock",
    category: "Body Parts / Frame",
  },
  {
    value: "Trunk lid parts",
    label: "Trunk lid parts",
    category: "Body Parts / Frame",
  },
  {
    value: "Trunk lid switch",
    label: "Trunk lid switch",
    category: "Hiddin Switch",
  },
  {
    value: "Trunk opener",
    label: "Trunk opener",
    category: "Body Parts / Frame",
  },
  {
    value: "Turbine wheel",
    label: "Turbine wheel",
    category: "Turbochargers / Superchargers",
  },
  {
    value: "Turbo bearing",
    label: "Turbo bearing",
    category: "Turbochargers / Superchargers",
  },
  {
    value: "Turbo housing",
    label: "Turbo housing",
    category: "Turbochargers / Superchargers",
  },
  {
    value: "Turbocharger",
    label: "Turbocharger",
    category: "Turbochargers / Superchargers",
  },
  {
    value: "Turbocharger parts",
    label: "Turbocharger parts",
    category: "Turbochargers / Superchargers",
  },
  {
    value: "Turn signal lamp",
    label: "Turn signal lamp",
    category: "Lighting ",
  },
  {
    value: "Turn signal switch",
    label: "Turn signal switch",
    category: "Interior Switch",
  },
  {
    value: "Two-color molding",
    label: "Two-color molding",
    category: "Process type (Stamping, Plastic injection molding etc.)",
  },
  {
    value: "Tyre Inflators & Pressure Gauges",
    label: "Tyre Inflators & Pressure Gauges",
    category: "Tyre & Wheel",
  },
  {
    value: "Universal joint",
    label: "Universal joint",
    category: "Axle",
  },
  {
    value: "Unspecified",
    label: "Unspecified",
    category: "Child Seat",
  },
  {
    value: "Unspecified",
    label: "Unspecified",
    category: "Camping ",
  },
  {
    value: "Unspecified",
    label: "Unspecified",
    category: "Paints & Body Repair",
  },
  {
    value: "Unspecified",
    label: "Unspecified",
    category: "Caravan & Motorhomes",
  },
  {
    value: "Unspecified",
    label: "Unspecified",
    category: "Motorcycling",
  },
  {
    value: "Upper arm",
    label: "Upper arm",
    category: "Suspendion",
  },
  {
    value: "V belt",
    label: "V belt",
    category: "Engine Structural Parts",
  },
  {
    value: "VFD (Vacuum Fluorescent Display)",
    label: "VFD (Vacuum Fluorescent Display)",
    category: "Electronic Device",
  },
  {
    value: "Vacuum pump",
    label: "Vacuum pump",
    category: "Brake & Pedal",
  },
  {
    value: "Vacuum sensor",
    label: "Vacuum sensor",
    category: "Sensor",
  },
  {
    value: "Vacuums & Accessories",
    label: "Vacuums & Accessories",
    category: "Vehicle Care ",
  },
  {
    value: "Valve",
    label: "Valve",
    category: "General Parts",
  },
  {
    value: "Valve guide",
    label: "Valve guide",
    category: "Valvetrain",
  },
  {
    value: "Valve lifter",
    label: "Valve lifter",
    category: "Valvetrain",
  },
  {
    value: "Valve retainer",
    label: "Valve retainer",
    category: "Valvetrain",
  },
  {
    value: "Valve seat",
    label: "Valve seat",
    category: "Valvetrain",
  },
  {
    value: "Valve spring",
    label: "Valve spring",
    category: "Valvetrain",
  },
  {
    value: "Valvetrain system",
    label: "Valvetrain system",
    category: "Valvetrain",
  },
  {
    value: "Various actuators",
    label: "Various actuators",
    category: "Electrical Parts",
  },
  {
    value: "Various batteries",
    label: "Various batteries",
    category: "Battery & Related Parts",
  },
  {
    value: "Various batteries",
    label: "Various batteries",
    category: "Battery & Related Parts",
  },
  {
    value: "Various batteries",
    label: "Various batteries",
    category: "Fuel Cell System",
  },
  {
    value: "Various casting",
    label: "Various casting",
    category: "Process type (Stamping, Plastic injection molding etc.)",
  },
  {
    value: "Various die casting",
    label: "Various die casting",
    category: "Process type (Stamping, Plastic injection molding etc.)",
  },
  {
    value: "Various forging",
    label: "Various forging",
    category: "Process type (Stamping, Plastic injection molding etc.)",
  },
  {
    value: "Various hidden switches",
    label: "Various hidden switches",
    category: "Hiddin Switch",
  },
  {
    value: "Various instrument switches",
    label: "Various instrument switches",
    category: "Interior Switch",
  },
  {
    value: "Various lamps",
    label: "Various lamps",
    category: "Lighting ",
  },
  {
    value: "Various machining",
    label: "Various machining",
    category: "Process type (Stamping, Plastic injection molding etc.)",
  },
  {
    value: "Various metal forming",
    label: "Various metal forming",
    category: "Process type (Stamping, Plastic injection molding etc.)",
  },
  {
    value: "Various motors",
    label: "Various motors",
    category: "Motor",
  },
  {
    value: "Various plastic molding",
    label: "Various plastic molding",
    category: "Process type (Stamping, Plastic injection molding etc.)",
  },
  {
    value: "Various processes",
    label: "Various processes",
    category: "Process type (Stamping, Plastic injection molding etc.)",
  },
  {
    value: "Various rubber processing",
    label: "Various rubber processing",
    category: "Process type (Stamping, Plastic injection molding etc.)",
  },
  {
    value: "Various semiconductors",
    label: "Various semiconductors",
    category: "Semiconductor Device",
  },
  {
    value: "Various sensors",
    label: "Various sensors",
    category: "Sensor",
  },
  {
    value: "Various stampings",
    label: "Various stampings",
    category: "Process type (Stamping, Plastic injection molding etc.)",
  },
  {
    value: "Various surface treatments",
    label: "Various surface treatments",
    category: "Process type (Stamping, Plastic injection molding etc.)",
  },
  {
    value: "Vehicle Cover & Tarpaulins",
    label: "Vehicle Cover & Tarpaulins",
    category: "Vehicle Care ",
  },
  {
    value: "Ventilator",
    label: "Ventilator",
    category: "Climate Control",
  },
  {
    value: "Vibration / Acoustic insulator",
    label: "Vibration / Acoustic insulator",
    category: "General Parts",
  },
  {
    value: "Viscous LSD",
    label: "Viscous LSD",
    category: "Differential",
  },
  {
    value: "Viscous coupling",
    label: "Viscous coupling",
    category: "Differential",
  },
  {
    value: "Voltage meter",
    label: "Voltage meter",
    category: "Instrument Panel ",
  },
  {
    value: "Warm forging",
    label: "Warm forging",
    category: "Process type (Stamping, Plastic injection molding etc.)",
  },
  {
    value: "Warning lamp",
    label: "Warning lamp",
    category: "Instrument Panel ",
  },
  {
    value: "Washer pump motor",
    label: "Washer pump motor",
    category: "Motor",
  },
  {
    value: "Washer switch",
    label: "Washer switch",
    category: "Interior Switch",
  },
  {
    value: "Wastegate valve",
    label: "Wastegate valve",
    category: "Turbochargers / Superchargers",
  },
  {
    value: "Water & Waste",
    label: "Water & Waste",
    category: "Caravan & Motorhomes",
  },
  {
    value: "Water / Oil temperature indicator",
    label: "Water / Oil temperature indicator",
    category: "Instrument Panel ",
  },
  {
    value: "Water pump",
    label: "Water pump",
    category: "Engine Cooling System",
  },
  {
    value: "Water pump motor",
    label: "Water pump motor",
    category: "Motor",
  },
  {
    value: "Weatherstrip",
    label: "Weatherstrip",
    category: "Exterior Parts",
  },
  {
    value: "Welding",
    label: "Welding",
    category: "Process type (Stamping, Plastic injection molding etc.)",
  },
  {
    value: "Wheel & Tyre Cleaning",
    label: "Wheel & Tyre Cleaning",
    category: "Vehicle Care ",
  },
  {
    value: "Wheel Clamps",
    label: "Wheel Clamps",
    category: "Security I Entertainment",
  },
  {
    value: "Wheel cap / cover",
    label: "Wheel cap / cover",
    category: "Tyre & Wheel",
  },
  {
    value: "Wheel hub",
    label: "Wheel hub",
    category: "Axle",
  },
  {
    value: "Wheel nut / Hub bolt",
    label: "Wheel nut / Hub bolt",
    category: "Suspendion",
  },
  {
    value: "Wheel speed sensor (ABS sensor)",
    label: "Wheel speed sensor (ABS sensor)",
    category: "Sensor",
  },
  {
    value: "Window Glass",
    label: "Window Glass",
    category: "Window Glass",
  },
  {
    value: "Window frame",
    label: "Window frame",
    category: "Body Parts / Frame",
  },
  {
    value: "Window regulator",
    label: "Window regulator",
    category: "Body Parts / Frame",
  },
  {
    value: "Windscreens",
    label: "Windscreens",
    category: "Motorcycling",
  },
  {
    value: "Wiper motor",
    label: "Wiper motor",
    category: "Motor",
  },
  {
    value: "Wiper switch",
    label: "Wiper switch",
    category: "Interior Switch",
  },
  {
    value: "Wire harness",
    label: "Wire harness",
    category: "Electrical Parts",
  },
  {
    value: "Wire mesh",
    label: "Wire mesh",
    category: "General Parts",
  },
  {
    value: "Yaw rate sensor",
    label: "Yaw rate sensor",
    category: "Sensor",
  },
  {
    value: "Zinc die casting",
    label: "Zinc die casting",
    category: "Process type (Stamping, Plastic injection molding etc.)",
  },
  {
    value: "e-Axle",
    label: "e-Axle",
    category: "Driver motor",
  },
];
