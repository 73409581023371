import React from "react";
import { Col } from "react-bootstrap";
import star1 from "../../../Assets/Images/star-filled.jpeg";
import star2 from "../../../Assets/Images/star-half.jpeg";
import star3 from "../../../Assets/Images/star-empty.jpeg";
import { parseDate } from "../../../utils/parseKey";

export default function ReviewCard({ type, review = {} }) {
  return (
    <Col lg={6} className="my-2 ">
      <div className="border rounded p-3">
        <div className="d-flex justify-content-between align-items-center">
          <p className="m-0">
            <b> {type === "given" ? "To" : "From"}</b>{" "}
            {type === "given" ? review.seller.name : review.user.name}
          </p>
          <p>{parseDate(review.createdAt)}</p>
        </div>
        <p>
          <b>Email:</b> {type === "given" ? review.seller.email : review.user.email}
        </p>
        <p className="d-flex align-items-center mt-3 mb-1">
          <b>Rated:</b>
          <span className="ms-2">
            {Array.from({ length: 5 }).map((_, i) => {
              return review.rating > i && review.rating < i + 1 ? (
                <img key={i} src={star2} className="ratingStar" />
              ) : review.rating <= i ? (
                <img key={i} src={star3} className="ratingStar" />
              ) : (
                <img key={i} src={star1} className="ratingStar" />
              );
            })}
          </span>
        </p>
        <p>
          <b>Review: </b>

          <span>{review.review}</span>
        </p>
      </div>
    </Col>
  );
}
