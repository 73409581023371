import React, { useEffect, useRef, useState } from "react";
import Slider from "react-slick";
import { useDispatch, useSelector } from "react-redux";
import { isArray } from "../../../utils/dataTypes";
import SharePop from "../../../components/Modals/sharePop";
import Gallery from "../../../components/gallery";
import { manageGallery } from "../../../redux/common/slice";

const OverlayCarousal = ({ media }) => {
  const sliderRef = useRef(null);
  const childrenSliderRef = useRef(null);
  const dispatch = useDispatch();
  const { galleryMedia } = useSelector((state) => state.common);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [action, setAction] = useState(null);
  const [thumbnailCount, setThumbnailCount] = useState(0);

  const settingsMain = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    afterChange: (slideIndex) => {
      childrenSliderRef.current.slickGoTo(slideIndex);
      setCurrentSlide(slideIndex);
    },
  };

  const settingsThumbnails = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: Math.min(4, thumbnailCount),
    slidesToScroll: 1,
    focusOnSelect: true,
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: Math.min(3, thumbnailCount),
        },
      },
    ],
  };

  const handleSlideChange = (index) => {
    sliderRef.current.slickGoTo(index);
  };

  const handleGallery = (media) => {
    dispatch(manageGallery(media));
  };

  useEffect(() => {
    if (media) {
      setThumbnailCount(media?.length || 0);
    }
  }, [media]);

  return (
    <div>
      <Slider ref={sliderRef} {...settingsMain}>
        {isArray(media)
          .filter((item) => item.type?.includes("image"))
          ?.map((item, index) => (
            <div
              key={index}
              className="detailsPageMainImage position-relative d-flex justify-content-center mb-2"
              onClick={() => handleGallery(media)}
            >
              <img
                src={item.url}
                alt={`Slide ${index}`}
                className="detailCrouselImage pointer"
                style={{ maxHeight: "560px" }}
              />
              <p className="watermark">AutoTitanic.com</p>
            </div>
          ))}
      </Slider>

      <Slider ref={childrenSliderRef} {...settingsThumbnails} className="thumbs">
        {isArray(media)
          .filter((item) => item.type?.includes("image"))
          ?.map((item, index) => (
            <div
              key={index}
              className={`${index === currentSlide ? "selectedThum" : ""}`}
              onClick={() => handleSlideChange(index)}
            >
              <img
                src={item.url}
                alt={`Thumbnail ${index}`}
                className="detailsCrouselThumbnailImage"
              />
            </div>
          ))}
      </Slider>

      {action?.type === "sharePost" && <SharePop action={action} setAction={setAction} />}
      {isArray(galleryMedia).length > 0 && <Gallery media={media} />}
    </div>
  );
};

export default OverlayCarousal;
