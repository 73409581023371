import React, { useEffect } from "react";
import emailVerify from "../../../Assets/Images/emailVerify.png";
import { useNavigate } from "react-router-dom";
import { Button } from "react-bootstrap";
import { handleApiRequest } from "../../../services/handleApiRequest";
import { resendVerificationEmail } from "../../../redux/auth/thunk";
import { errorMsg, successMsg } from "../../../utils/toastMsg";

export default function VerifyEmailMsg() {
  const navigate = useNavigate();

  const handleResendEmail = async () => {
    const email = localStorage.getItem("newEmail");
    if (!email) return errorMsg("Unable to send email. Please try later");

    const response = await handleApiRequest(resendVerificationEmail, { email });
    if (response?.status) {
      successMsg("Email sent successfully!!");
    }
  };

  useEffect(() => {
    return () => localStorage.removeItem("email");
  }, []);

  return (
    <div className="text-center my-5">
      <h2 className="fw-bold">EMAIL VERIFICATION </h2>
      <p className="fw-bold mb-0">
        Thank you for your registration. We have sent a verification email to your registered email
        address.
      </p>
      <p className="fw-bold">Please go to your email/inbox to verify your account.</p>
      <div className="d-flex justify-content-center gap-10">
        <Button className="mainDarkColor rounded-pill" onClick={() => navigate("/")}>
          GO to Home
        </Button>
        <Button variant="danger" className="rounded-pill px-5" onClick={() => navigate("/login")}>
          Login
        </Button>
      </div>
      <div className="d-flex justify-content-center align-items-center">
        <p className="m-0">Didn't receive email?</p>
        <Button
          variant=""
          className="rounded-pill text-primary border-0"
          onClick={handleResendEmail}
        >
          Resend it
        </Button>
      </div>
      <img src={emailVerify} className="emailVerificationImage" />
    </div>
  );
}
