import React, { useEffect } from "react";

export default function HorizontalAds({ dataAdSlot = "2825783663" }) {
  useEffect(() => {
    try {
      (window.adsbygoogle = window.adsbygoogle || []).push({});
    } catch (error) {
      console.log("ad error", error);
    }
  }, []);

  return (
    <ins
      className="adsbygoogle"
      style={{ display: "flex", justifyContent: "center" }}
      data-ad-client="ca-pub-2768118606498294"
      data-ad-slot={dataAdSlot}
      data-ad-format="auto"
      //   data-adtest="on"
      //   google_adtest="on"
      data-full-width-responsive="true"
    ></ins>
  );
}
