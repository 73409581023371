import React, { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { IoCheckmark } from "react-icons/io5";
import coupe from "../../../Assets/Images/Coupe.png";
import convertible from "../../../Assets/Images/Convertible.png";
import estate from "../../../Assets/Images/Estate.png";
import hatchback from "../../../Assets/Images/Hatchback.png";
import mpv from "../../../Assets/Images/Mpv.png";
import pickup from "../../../Assets/Images/Pickup.png";
import saloon from "../../../Assets/Images/Saloon.png";
import suv from "../../../Assets/Images/suv.png";
import { selectFilters } from "../../../redux/filters/slice";
import { handleApiRequest } from "../../../services/handleApiRequest";
import { getVehicleCount, getVehicleCountByBody } from "../../../redux/vehicles/thunk";
import HeroAdd from "../../../components/heroSection/heroAdd";
import { carsSeatOptions } from "../../../utils/filters/cars/options";
import {
  colorsList,
  doorOptions,
  driverPositionsOptions,
  engineSizeOptions,
  fuelTypeOptions,
  gearBoxOptions,
} from "../../../utils/filters/common/options";
import LeftSideFilters from "./leftSideFilters";
import ActionBtns from "./actionBtns";

const bodyTypeOptions = [
  { img: convertible, label: "Convertible", value: "convertible" },
  { img: coupe, label: "Coupe", value: "coupe" },
  { img: estate, label: "Estate", value: "estate" },
  { img: hatchback, label: "Hatchback", value: "hatchback" },
  { img: mpv, label: "MPV", value: "MPV" },
  { img: pickup, label: "Pick-up", value: "Pick-up" },
  { img: saloon, label: "Saloon", value: "saloon" },
  { img: suv, label: "SUV", value: "SUV" },
];

export default function AllCarsFilters() {
  const dispatch = useDispatch();
  const { vehiclesCount, vehiclesCountByFilter } = useSelector((state) => state.vehicles);
  const { filters } = useSelector((state) => state.filters);

  const handleUpdateFilter = (name, value) => {
    dispatch(selectFilters({ [name]: value }));
  };

  const handleResultCount = async () => {
    const newFilters = {};
    Object.entries(filters).forEach((filter) => {
      newFilters[filter[0]] =
        typeof filter[1] === "object" ? filter[1].value || filter[1]._id : filter[1];
    });

    handleApiRequest(getVehicleCount, {
      filters: { ...newFilters, status: "approved", type: "cars", sellOrRent: "sell" },
    });
  };

  const getVehicleCountByFilter = async () => {
    const bodyStyles = bodyTypeOptions.map((type) => {
      return type.value;
    });
    const request = {
      filters: { bodyStyle: bodyStyles, type: "cars" },
    };
    const response = await handleApiRequest(getVehicleCountByBody, request);
  };

  useEffect(() => {
    getVehicleCountByFilter();
  }, []);

  useEffect(() => {
    handleResultCount();
  }, [filters]);

  return (
    <>
      <HeroAdd />

      <section>
        <Row>
          <h5 className="fw-bold text-center">ADVANCED SEARCH</h5>
          <h6 className="text-center mb-5">Search the largest choice of cars</h6>
          <Col lg={4} className="d-flex flex-column" style={{ gap: 10 }}>
            <LeftSideFilters category="cars" />
          </Col>
          <Col lg={8} className="d-flex flex-column position-raltive">
            <fieldset className="border my-3">
              <legend>Body Type</legend>
              <Row className="py-3">
                {bodyTypeOptions.map((bodyType) => (
                  <Col
                    key={bodyType.value}
                    lg={2}
                    sm={3}
                    xs={4}
                    className={`bodyStyleContainer pointer position-relative ${
                      filters.bodyStyle?.value === bodyType.value ? "activeBody" : ""
                    }`}
                    onClick={() =>
                      handleUpdateFilter("bodyStyle", {
                        value: bodyType.value,
                        label: bodyType.value,
                      })
                    }
                  >
                    {filters.bodyStyle?.value === bodyType.value && (
                      <IoCheckmark className="checkIcon" />
                    )}
                    <img src={bodyType.img} />
                    <p className="m-0">{bodyType.label}</p>
                    <p>
                      (
                      {vehiclesCountByFilter.data?.bodyStyle
                        .find((style) => style.value === bodyType.value)
                        ?.count?.toLocaleString()}
                      )
                    </p>
                  </Col>
                ))}
              </Row>
            </fieldset>
            <fieldset className="border my-3">
              <legend>Fuel Type</legend>
              <Row className="py-3">
                {fuelTypeOptions.map((fuelType) => (
                  <Col
                    key={fuelType.value}
                    lg={4}
                    xs={6}
                    className="d-flex align-items-center my-2"
                  >
                    <input
                      type="radio"
                      name="fuelType"
                      id={fuelType.value}
                      onClick={() => handleUpdateFilter("fuelType", fuelType.value)}
                    />
                    <label className="m-0 ms-2" htmlFor={fuelType.value}>
                      {fuelType.label}
                    </label>
                  </Col>
                ))}
              </Row>
            </fieldset>
            <fieldset className="border my-3 py-3">
              <legend>Specification</legend>
              <fieldset className="border">
                <legend>Color</legend>
                <Row className="py-3">
                  {colorsList.map((color) => (
                    <Col key={color.value} lg={4} xs={6} className="d-flex align-items-center my-2">
                      <input
                        type="radio"
                        name="exteriorColor"
                        id={color.value}
                        onChange={() => handleUpdateFilter("exteriorColor", color)}
                      />
                      <label className="m-0 ms-2" htmlFor={color.value}>
                        {color.label}
                      </label>
                    </Col>
                  ))}
                </Row>
              </fieldset>
              <div className="d-flex justify-content-between my-2 gap-10">
                <div className="w-100">
                  <label>Door</label>
                  <Select
                    options={doorOptions}
                    components={{
                      IndicatorSeparator: () => null,
                    }}
                    value={filters.door}
                    onChange={(selected) => {
                      handleUpdateFilter("door", selected);
                    }}
                  />
                </div>
                <div className="w-100">
                  <label>Seat</label>
                  <Select
                    options={carsSeatOptions}
                    components={{
                      IndicatorSeparator: () => null,
                    }}
                    value={filters.seat}
                    onChange={(selected) => {
                      handleUpdateFilter("seat", selected);
                    }}
                  />
                </div>
              </div>
              <div className="d-flex justify-content-between my-2 gap-10">
                <div className="col-6 pe-0">
                  <label>Driver Position</label>
                  <Select
                    options={driverPositionsOptions}
                    components={{
                      IndicatorSeparator: () => null,
                    }}
                    value={filters.driverPosition}
                    onChange={(selected) => {
                      handleUpdateFilter("driverPosition", selected);
                    }}
                  />
                </div>
                <div className="w-100">
                  <label>Transmission</label>
                  <Select
                    options={gearBoxOptions}
                    components={{
                      IndicatorSeparator: () => null,
                    }}
                    value={filters.gearBox}
                    onChange={(selected) => {
                      handleUpdateFilter("gearBox", selected);
                    }}
                  />
                </div>
              </div>
              <div className="d-flex justify-content-between row my-2 gap-10">
                {/* <div className="col-6 pe-0">
                  <label>Boot Space</label>
                  <Select
                    options={carsBootSpaceOptions}
                    components={{
                      IndicatorSeparator: () => null,
                    }}
                    value={filters.bootSpace}
                    onChange={(selected) => {
                      handleUpdateFilter("bootSpace", selected);
                    }}
                  />
                </div> */}
              </div>
            </fieldset>
            <fieldset className="border my-3  py-3">
              <legend>Performence</legend>
              <div className="d-flex justify-content-between my-2 gap-10">
                <div className="w-100">
                  <label>Engine Size</label>
                  <Select
                    options={engineSizeOptions}
                    components={{
                      IndicatorSeparator: () => null,
                    }}
                    value={filters.engineSize}
                    onChange={(selected) => {
                      handleUpdateFilter("engineSize", selected);
                    }}
                  />
                </div>
                {/* <div className="w-100">
                  <label>Acceleration</label>
                  <Select
                    options={accelerationOptions}
                    components={{
                      IndicatorSeparator: () => null,
                    }}
                    value={filters.acceleration}
                    onChange={(selected) => {
                      handleUpdateFilter("acceleration", selected);
                    }}
                  />
                </div> */}
              </div>
            </fieldset>
            {/* <fieldset className="border my-3  py-3">
              <legend>Running Cost</legend>
              <div className="d-flex justify-content-between my-2 gap-10">
                <div className="w-100">
                  <label>Fuel Consumption</label>
                  <Select
                    options={fuelConsumtionOptions}
                    components={{
                      IndicatorSeparator: () => null,
                    }}
                    value={filters.fuelConsumtion}
                    onChange={(selected) => {
                      handleUpdateFilter("fuelConsumtion", selected);
                    }}
                  />
                </div>
                <div className="w-100">
                  <label>CO2 Emission</label>
                  <Select
                    options={co2EmmisionOptions}
                    components={{
                      IndicatorSeparator: () => null,
                    }}
                    value={filters.co2Emmision}
                    onChange={(selected) => {
                      handleUpdateFilter("co2Emmision", selected);
                    }}
                  />
                </div>
              </div>
            </fieldset> */}
            <fieldset className="border my-3  py-3">
              <legend>Preference</legend>
              <div className="d-flex justify-content-between my-2 gap-10">
                <div className="w-100">
                  <label>Private & Dealer</label>
                  <Select
                    options={[
                      { value: "private", label: "Private" },
                      { value: "dealer", label: "Dealer" },
                    ]}
                    components={{
                      IndicatorSeparator: () => null,
                    }}
                    value={filters.userType}
                    onChange={(selected) => {
                      handleUpdateFilter("userType", selected);
                    }}
                  />
                </div>
                <div className="w-100">
                  <label>Condition</label>
                  <Select
                    options={[
                      { value: "used", label: "Used" },
                      { value: "new", label: "New" },
                    ]}
                    components={{
                      IndicatorSeparator: () => null,
                    }}
                    value={filters.condition}
                    onChange={(selected) => {
                      handleUpdateFilter("condition", selected);
                    }}
                  />
                </div>
              </div>
            </fieldset>

            <ActionBtns count={vehiclesCount.data?.totalCount} category="cars" />
          </Col>
        </Row>
      </section>
    </>
  );
}
