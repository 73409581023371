export const farmsFuelTypeOptions = [
  { value: "biFuel", label: "Bi fuel" },
  { value: "diesel", label: "Diesel" },
  { value: "dieselHybrid", label: "Diesel Hybrid" },
  { value: "electric", label: "Electric" },
  { value: "lpg", label: "LPG" },
  { value: "petrol", label: "Petrol" },
  { value: "petrolHybrid", label: "Petrol Hybrid" },
  { value: "hydrogen", label: "Hydrogen" },
  { value: "other", label: "Other" },
];

export const farmsUsedHoursOptions = [
  { value: 500, label: "Up to 500" },
  { value: 1000, label: "Up to 1000" },
  { value: 1500, label: "Up to 1500" },
  { value: 2000, label: "Up to 2000" },
  { value: 2500, label: "Up to 2500" },
  { value: 3000, label: "Up to 3000" },
  { value: 4500, label: "Up to 4500" },
  { value: 5000, label: "Up to 5000" },
  { value: 5500, label: "Up to 5500" },
  { value: 6000, label: "Up to 6000" },
  { value: 6500, label: "Up to 6500" },
  { value: 7000, label: "Up to 7000" },
  { value: 7500, label: "Up to 7500" },
  { value: 8000, label: "Up to 8000" },
  { value: 8500, label: "Up to 8500" },
  { value: 9000, label: "Up to 9000" },
  { value: 9500, label: "Up to 9500" },
  { value: 10000, label: "Up to 10000" },
  { value: 11000, label: "Up to 11000" },
  { value: 12000, label: "Up to 12000" },
  { value: 13000, label: "Up to 13000" },
  { value: 14000, label: "Up to 14000" },
  { value: 15000, label: "Up to 15000" },
  { value: 16000, label: "Up to 16000" },
  { value: 17000, label: "Up to 17000" },
  { value: 18000, label: "Up to 18000" },
  { value: 19000, label: "Up to 19000" },
  { value: 20000, label: "Up to 20000" },
  { value: 999999, label: "Over 20000" },
];

export const farmsCategoryOptions = [
  { value: "4wdVehicles", label: "4wd vehicles" },
  { value: "attachments", label: "Attachments" },
  { value: "atvs", label: "Atvs" },
  { value: "forageHay", label: "Forage & hay" },
  { value: "forestryHedging", label: "Forestry & hedging" },
  { value: "groundCareEquipment", label: "Ground care equipment" },
  { value: "harvesters", label: "Harvesters" },
  { value: "livestockEquipment", label: "Livestock equipment" },
  { value: "loadersExcavators", label: "Loaders & excavators" },
  { value: "sowingPlanting", label: "Sowing & planting" },
  { value: "spreadersSprayers", label: "Spreaders & sprayers" },
  { value: "storageBuildings", label: "Storage & buildings" },
  { value: "tillage", label: "Tillage" },
  { value: "tractors", label: "Tractors" },
  { value: "trailers", label: "Trailers" },
  { value: "others", label: "Others" },
];

export const farmsSubCategoryOptions = [
  { category: "4wdVehicles", value: "4wdVehicles", label: "4wd vehicles" },
  { category: "attachments", value: "others", label: "Others" },
  { category: "atvs", value: "atvsTrailers", label: "'Atvs trailers'" },
  { category: "forageHay", value: "baleWrappers", label: "Bale wrappers" },
  { category: "forageHay", value: "others", label: "Others" },
  { category: "forageHay", value: "forageHesvesters", label: "Forage hesvesters" },
  { category: "forageHay", value: "hayBobs", label: "Hay bobs" },
  { category: "forageHay", value: "mowerConditioners", label: "Mower conditioners" },
  { category: "forageHay", value: "mowers", label: "Mowers" },
  { category: "forageHay", value: "rakes", label: "Rakes" },
  { category: "forageHay", value: "roundBalers", label: "Round balers" },
  { category: "forageHay", value: "squareBalers", label: "Square balers" },
  {
    category: "forageHay",
    value: "stackingLoadingEquipment",
    label: "Stacking & loading equipment",
  },
  { category: "forageHay", value: "toppers", label: "Toppers" },
  { category: "forageHay", value: "w  indrowers", label: "Windrowers" },

  {
    category: "forestryHedging",
    value: "Forestry & hedging attachments",
    label: "Forestry & hedging attachments",
  },
  { category: "forestryHedging", value: "others", label: "Others" },
  { category: "forestryHedging", value: "forestryMowers", label: "Forestry mowers" },
  { category: "forestryHedging", value: "hedgeMowers", label: "Hedge mowers" },
  { category: "forestryHedging", value: "logSplitters", label: "Log splitters" },
  { category: "forestryHedging", value: "stumpGrinders", label: "Stump grinders" },
  { category: "forestryHedging", value: "vergeMowers", label: "Verge mowers" },
  { category: "forestryHedging", value: "woodChippers", label: "Wood chippers" },

  { category: "groundCareEquipment", value: "others", label: "Others" },
  { category: "groundCareEquipment", value: "rideOnMowers", label: "Ride on mowers" },

  { category: "harvesters", value: "combineHarvesters", label: "Combine harvesters" },
  { category: "harvesters", value: "forageHarvesters", label: "Forage harvesters" },
  { category: "harvesters", value: "others", label: "Others" },

  {
    category: "livestockEquipment",
    value: "baleShreddersSpreaders",
    label: "Bale shredders & spreaders",
  },
  {
    category: "livestockEquipment",
    value: "livestockEquipmentAttachments",
    label: "Livestock equipment attachments",
  },
  { category: "livestockEquipment", value: "others", label: "Others" },

  { category: "loadersExcavators", value: "attachments", label: "Attachments" },
  { category: "loadersExcavators", value: "plantExcavators", label: "Plant excavators" },
  { category: "loadersExcavators", value: "plantLoaders", label: "Plant loaders" },
  { category: "loadersExcavators", value: "telehandlers", label: "Telehandlers" },
  { category: "loadersExcavators", value: "tractorBasedLoaders", label: "Tractor based loaders" },

  {
    category: "sowingPlanting",
    value: "combinationDrills",
    label: "Combination drills",
  },
  { category: "sowingPlanting", value: "planters", label: "Planters" },
  {
    category: "sowingPlanting",
    value: "precisionSeeders",
    label: "Precision seeders",
  },
  {
    category: "sowingPlanting",
    value: "seedDrills",
    label: "Seed drills",
  },
  { category: "sowingPlanting", value: "others", label: "Others" },
  {
    category: "spreadersSprayers",
    value: "fertiliserSpreaders",
    label: "Fertiliser spreaders",
  },
  {
    category: "spreadersSprayers",
    value: "manureSpreaders",
    label: "Manure spreaders",
  },
  { category: "spreadersSprayers", value: "others", label: "Others" },
  {
    category: "spreadersSprayers",
    value: "selfPropelledSprayers",
    label: "Self propelled sprayers",
  },
  {
    category: "spreadersSprayers",
    value: "tractorMountedSprayers",
    label: "Tractor mounted sprayers",
  },
  {
    category: "spreadersSprayers",
    value: "trailerSprayers",
    label: "Trailer sprayers",
  },
  {
    category: "spreadersSprayers",
    value: "slurryTankers",
    label: "Slurry tankers",
  },
  {
    category: "storageBuildings",
    value: "conveyors",
    label: "Conveyors",
  },
  { category: "storageBuildings", value: "others", label: "Others" },
  { category: "storageBuildings", value: "tanks", label: "Tanks" },
  { category: "tillage", value: "aerators", label: "Aerators" },
  { category: "tillage", value: "cultivators", label: "Cultivators" },
  { category: "tillage", value: "harrows", label: "Harrows" },
  { category: "tillage", value: "ploughs", label: "Ploughs" },
  {
    category: "tillage",
    value: "powerHarrows",
    label: "Power harrows",
  },
  { category: "tillage", value: "rollers", label: "Rollers" },
  {
    category: "tillage",
    value: "stoneRemovalCrushers",
    label: "Stone removal & crushers",
  },
  { category: "tillage", value: "subsoilers", label: "Subsoilers" },
  {
    category: "tillage",
    value: "tillageAttachment",
    label: "Tillage attachment",
  },
  { category: "tillage", value: "others", label: "Others" },
  {
    category: "tractors",
    value: "compactTractor",
    label: "Compact tractor",
  },
  {
    category: "tractors",
    value: "largeTractor",
    label: "Large tractor",
  },
  {
    category: "tractors",
    value: "mediumTractor",
    label: "Medium tractor",
  },
  { category: "tractors", value: "others", label: "Others" },
  {
    category: "tractors",
    value: "smallTractors",
    label: "Small tractors",
  },
  {
    category: "tractors",
    value: "tractorAttachment",
    label: "Tractor attachment",
  },
  { category: "trailers", value: "atvTrailers", label: "Atv trailers" },
  {
    category: "trailers",
    value: "baleTrailers",
    label: "Bale trailers",
  },
  {
    category: "trailers",
    value: "dropsideTrailers",
    label: "Dropside trailers",
  },
  {
    category: "trailers",
    value: "dumperTrailers",
    label: "Dumper trailers",
  },
  {
    category: "trailers",
    value: "farmTrailerAttachments",
    label: "Farm trailer attachments",
  },
  {
    category: "trailers",
    value: "flatbedTrailers",
    label: "Flatbed trailers",
  },
  {
    category: "trailers",
    value: "grainSilageTrailers",
    label: "Grain & silage trailers",
  },
  {
    category: "trailers",
    value: "livestockTrailers",
    label: "Livestock trailers",
  },
  { category: "trailers", value: "lowLoader", label: "Low loader" },
  { category: "trailers", value: "others", label: "Others" },
];
