import isEmail from "validator/lib/isEmail";
import { passwordRegex } from "../config";

export function validateFields(data) {
  if (data.email && !isEmail(data.email)) {
    return "Invalid email";
  } else if (data.password && !passwordRegex.test(data.password)) {
    return "Password must include minimum eight characters, at least one uppercase, one lowercase, one number and one special character";
  } else if (data.confirmPassword && data.password !== data.confirmPassword) {
    return "Password and confirm password must match";
  } else {
    return false;
  }
}
