import React, { useEffect } from "react";
import africaFlag from "../../../Assets/Images/africa-flag.png";
import { CountryFilterOptions } from "../../../components/filters";
import SelectBox from "../../../components/selectBox";
import { isArray } from "../../../utils/dataTypes";
import { useDispatch, useSelector } from "react-redux";
import { selectFilters } from "../../../redux/filters/slice";
import { preventMinus } from "../../../utils";
import { carsMileageList } from "../../../utils/filters/cars/options";
import { getYearList } from "../../../utils/filters/common/options";
import VerticalAds from "../../../googleAds/verticalAds";
import { getAllMake, getAllModel } from "../../../redux/makeAndModel/thunk";
import { handleApiRequest } from "../../../services/handleApiRequest";
import { getAllCity, getAllCountry } from "../../../redux/countryAndCity/thunk";

export default function LeftSideFilters({ category = "cars" }) {
  const dispatch = useDispatch();

  const { filters } = useSelector((state) => state.filters);
  const { allMakes, allModels } = useSelector((state) => state.makeAndModel);
  const { allCountries, allCities } = useSelector((state) => state.countryAndCity || {});

  const handleUpdateFilters = (name, value) => {
    if (name === "country" && !value.value) {
      dispatch(selectFilters({ [name]: value, city: "" }));
    } else if (name === "make" && !value.value) {
      dispatch(selectFilters({ [name]: value, model: "" }));
    } else {
      dispatch(selectFilters({ [name]: value }));
    }
  };

  const handleCityList = async () => {
    await handleApiRequest(getAllCity, filters.country.value);
  };

  const handleMakeList = async () => {
    handleApiRequest(getAllMake, { type: category });
  };

  const handleModelList = async () => {
    handleApiRequest(getAllModel, { makeId: filters.make.value, type: category });
  };

  useEffect(() => {
    handleMakeList();
  }, []);

  useEffect(() => {
    if (filters.make?.value) {
      handleModelList();
    }
  }, [filters.make]);

  useEffect(() => {
    if (filters.country?.value) {
      handleCityList();
    }
  }, [filters.country]);

  return (
    <>
      <div className="d-flex justify-content-between my-2 gap-10">
        <div className="w-100">
          <label>Country</label>
          <SelectBox
            isSearchable={false}
            placeholder="Select Country"
            components={{ Option: CountryFilterOptions, IndicatorSeparator: null }}
            options={[
              { _id: "", name: "Africa", flag: africaFlag },
              ...isArray(allCountries.data?.items),
            ]}
            getOptionLabel={(option) => {
              return (
                <div className="pointer px-2">
                  <img className="countryDropFlag" src={option.flag} />
                  <span className="ms-1">{option.label}</span>
                </div>
              );
            }}
            value={filters.country || ""}
            onChange={(selected) => {
              handleUpdateFilters("country", {
                value: selected._id,
                label: selected.name,
                flag: selected.flag,
              });
            }}
          />
        </div>
        {filters.country?.label !== "Africa" && (
          <div className="w-100">
            <label>City</label>
            <SelectBox
              placeholder="Select City"
              options={allCities.data?.items || []}
              value={filters.city || ""}
              getOptionLabel={(option) => option.name || option.label}
              onChange={(selected) => {
                handleUpdateFilters("city", {
                  value: selected._id,
                  label: selected.name,
                });
              }}
            />
          </div>
        )}
      </div>
      <div className="d-flex justify-content-between my-2 gap-10">
        <div className="w-100">
          <label>Make</label>
          <SelectBox
            placeholder="Make"
            options={allMakes.data?.items || []}
            value={filters.make || ""}
            onChange={(selected) => {
              handleUpdateFilters("make", { value: selected._id, label: selected.label });
            }}
          />
        </div>
        <div className="w-100">
          <label>Model</label>
          <SelectBox
            placeholder="Model"
            options={allModels.data?.items || []}
            value={filters.model || ""}
            onChange={(selected) => {
              handleUpdateFilters("model", { value: selected._id, label: selected.label });
            }}
          />
        </div>
      </div>
      <div className="d-flex justify-content-between my-2 gap-10">
        <div>
          <label>Min Price</label>
          <input
            type="number"
            className="form-control"
            style={{ height: 40 }}
            placeholder="Enter Min Price"
            name="minPrice"
            value={filters.minPrice?.value || ""}
            min={0}
            onKeyDown={preventMinus}
            onChange={(e) => {
              const value = e.target.value;
              if (value.length > 10) return;
              handleUpdateFilters("minPrice", { value: value, label: value });
            }}
          />
        </div>

        <div>
          <label>Min Price</label>
          <input
            type="number"
            className="form-control"
            style={{ height: 40 }}
            placeholder="Enter Max Price"
            name="maxPrice"
            value={filters.maxPrice?.value || ""}
            min={0}
            onKeyDown={preventMinus}
            onChange={(e) => {
              const value = e.target.value;
              if (value.length > 10) return;
              handleUpdateFilters("maxPrice", { value: value, label: value });
            }}
          />
        </div>
      </div>

      <div className="d-flex justify-content-between my-2 gap-10">
        <div className="w-100">
          <label>Min Mileage</label>
          <SelectBox
            placeholder="Max Mileage"
            options={carsMileageList.slice(0, -2)}
            value={filters.minMileage || ""}
            onChange={(value) => {
              handleUpdateFilters("minMileage", value);
            }}
          />
        </div>
        <div className="w-100">
          <label>Max Mileage</label>
          <SelectBox
            placeholder="Max Mileage"
            options={carsMileageList.slice(2)}
            value={filters.maxMileage || ""}
            onChange={(value) => {
              handleUpdateFilters("maxMileage", value);
            }}
          />
        </div>
      </div>
      <div className="d-flex justify-content-between my-2 gap-10">
        <div className="w-100">
          <label>From (Year)</label>
          <SelectBox
            options={getYearList()}
            value={filters.minYear || ""}
            onChange={(value) => {
              handleUpdateFilters("minYear", value);
            }}
          />
        </div>
        <div className="w-100">
          <label>To (Year)</label>
          <SelectBox
            options={getYearList()}
            value={filters.maxYear || ""}
            onChange={(value) => {
              handleUpdateFilters("maxYear", value);
            }}
          />
        </div>
      </div>

      <div className="">
        <VerticalAds dataAdSlot="1382508356" className="allFiltersAdd" />
      </div>
    </>
  );
}
