import React from "react";
import AllCarsFilters from "./components/carsFilters";
import { useSearchParams } from "react-router-dom";
import AllVansFilters from "./components/vansFilters";
import AllBikesFilters from "./components/bikesFilters";
import AllMotorhomesFilters from "./components/motorhomesFilters";
import AllCaravansFilters from "./components/caravansFilter";
import MoreFilters from "./components/filterIndex";
import AllRentalsFilters from "./components/rentalsFilters";
import AllPartsFilters from "./components/partAndAccessoriesFilters";
import HorizontalAds from "../../googleAds/horizontalAds";

export default function AllFilters() {
  const [params] = useSearchParams();
  const type = params.get("type") || "sell";
  let userCategory = params.get("category");
  userCategory = userCategory ? userCategory : type === "rent" && !userCategory ? "" : "cars";

  return (
    <>
      <div className="my-3">
        <HorizontalAds />
      </div>
      {type === "rent" ? (
        <AllRentalsFilters />
      ) : userCategory === "cars" ? (
        <AllCarsFilters />
      ) : userCategory === "caravans" ? (
        <AllCaravansFilters />
      ) : userCategory === "vans" ? (
        <AllVansFilters />
      ) : userCategory === "bikes" ? (
        <AllBikesFilters />
      ) : userCategory === "motorhomes" ? (
        <AllMotorhomesFilters />
      ) : ["trucks", "farms", "plants"].includes(userCategory) ? (
        <MoreFilters />
      ) : userCategory === "partAndAccessories" ? (
        <AllPartsFilters />
      ) : (
        <AllCarsFilters />
      )}
    </>
  );
}
